import { Component, OnInit } from '@angular/core';
import { AdvisorService } from '../../../shared/services/advisor.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { AREAS } from '../../../shared/helpers/areas.catalog';
import { Router } from '@angular/router';
import { AddPropertyDialogComponent } from './add-property-dialog/add-property-dialog.component';
import { ChangePropertyDialogComponent } from './change-property-name-dialog/change-property-name-dialog.component';
import { initPendo } from 'app/shared/helpers/pendo.helper';

import { MatDialog } from '@angular/material/dialog';
import { DialogConfirm } from '../../../shared/components/dialog-confirm/dialog-confirm.component';
import { AuthenticationService } from '../../../shared/services/auth/authentication.service';
import { DialogMultipleAssets } from '../dialog-multiple-assets/dialog-multipleAssets.component';
import { ClientDataService } from 'app/shared/services/client-data-service';
import { readFromStoragedObject } from 'app/shared/helpers/utils';

@Component({
  selector: 'app-hub-multi-area',
  templateUrl: './hub-multi-area.component.html',
})
export class HubMultiAreaComponent implements OnInit {

  public clientID: string;
  public areasInHub;
  public clientHasAnsweredGeneral;
  public objectKeys = Object.keys;
  public clientName: string = '';
  public clientJustName: string = '';
  public isLoading: boolean = true;
  public areaSelecterFromUrl: string = '';
  public areaName: string = '';
  public newQuestionnaireName: string = '';
  public foundArea: any;
  public props: any[] = [];
  public isAdvisor: boolean = false;
  public toggleSearchBar: boolean = false;
  public hasAccessToRecs: boolean = false;

  public spouseName: string = '';

  constructor(
    private advisorService: AdvisorService,
    public snackBarError: MatSnackBar,
    private router: Router,
    public dialog: MatDialog,
    public authService: AuthenticationService,
    public clientDataService: ClientDataService
  ) { }

  ngOnInit() {

    this.hasAccessToRecs = this.authService.currentRoleHasAccessTo('planningopps');
    //
    this.isAdvisor = this.authService.isAdvisor();
    // get the area from the browser url
    this.areaSelecterFromUrl = window.location.href.split('/').slice(-1)[0];
    this.areaSelecterFromUrl = (this.areaSelecterFromUrl === 'estate-documents' || this.areaSelecterFromUrl === 'estate-documents?s=3') ? 'estate' : this.areaSelecterFromUrl;
    // Load area data from Areas catalog
    this.foundArea = AREAS.filter(area => { return (area.id == this.areaSelecterFromUrl); })[0];

    this.areaName = (this.foundArea != undefined) ? this.foundArea.name : 'Undefined';
    console.log('areaName', this.areaName, this.foundArea);

    // this.clientName = JSON.parse(sessionStorage.currentClient).name;
    let storagedClient = JSON.parse(sessionStorage.getItem('currentClient'));
    this.clientID = storagedClient.clientId;
    this.clientName = `${storagedClient.name} ${storagedClient.lastName}`;
    this.clientJustName = storagedClient.name;

    if(storagedClient.isMarried== 'Married'){
      this.spouseName = storagedClient.spouse;
    }


    let hasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));
    this.clientHasAnsweredGeneral = hasAnswered.ansGeneral;

    let promiseGetHubSubquestionnaires = this.getHubSubquestionnaires();

    let promiseGetHubAreas = this.advisorService.getHubAreas(this.clientID)
    .subscribe(
      data => {
        this.areasInHub = data;

      },
      error => {
        console.log(error);
        this.snackBarError.open("Can't get Hub data. [" + error.error.message + ']', "", {
          duration: 6000,
          panelClass: 'error-snackbar'
        });

      }
    );

    Promise.all([promiseGetHubAreas, promiseGetHubSubquestionnaires]).then(response => {
      this.isLoading = false;
    })

    this.pendoInit();

  }

  async validateMaritalStatus() {

    let isMarried  =  readFromStoragedObject('currentClient', 'isMarried', 'Session');

      if (this.props.length === 1 && isMarried === "Married") {
        this.openDialog();
      } else if (this.props.length === 2 && isMarried !== "Married") {
        this.deleteMultiquest(2, true);
      } else if (this.props.length === 0 && isMarried === "Married") {
        setTimeout(() => {
          this.openDialog();
        }, 500);
      }
  }


  async getHubSubquestionnaires(){
    await this.advisorService.getHubSubQuestionnares(this.clientID, this.areaSelecterFromUrl).then(
      async subQuestionnaires => {


        this.props = subQuestionnaires;


        // If no questionnaires in these areas are present create the first one automagically
        if (this.props.length === 0) {
          this.openDialog();
        }
        if (this.areaSelecterFromUrl === 'estate') {
          await this.validateMaritalStatus();
        }

      }
    );
  }



  goToQuest(index: number, name: string = '', answered:boolean = false) {
    this.setStoragedQuestionnareData(this.areaSelecterFromUrl, index, name, answered);

    this.router.navigate(['/questionnaire-' + this.areaSelecterFromUrl]);
  }

  async openDialog() {
    if(this.isAssetLimited()){
      this.dialog.open(DialogMultipleAssets, {
        panelClass: 'modal-dialog-questionnaire',
        height: '200px',
        width: '500px',
    });
      return;
      //Open modal no more space
    }

    if(this.areaSelecterFromUrl == 'disability' || this.areaSelecterFromUrl == 'ltc' || this.areaSelecterFromUrl == 'estate'){
      await this.addArea();
      return;
    }

    const dialogRef = this.dialog.open(AddPropertyDialogComponent, {
      width: '250px',
      data: {
        mainObjectDescription: this.foundArea.mainObjectDescription,
        title: '',
        area: this.areaSelecterFromUrl,
        items: this.props.length
       }
    });

    dialogRef.afterClosed().subscribe(result => {

      this.advisorService.addHubSubQuestionnares(this.clientID, this.areaSelecterFromUrl, result).then(
        result => {
          this.advisorService.getHubSubQuestionnares(this.clientID, this.areaSelecterFromUrl).then(response =>{
            this.props = response;
          })
          //this.props = result;

        }
      )
    });
  }


  async addArea(){
    try {
      let result;

      if(this.props.length == 0 ) {
        result = this.clientJustName;
      }else if(this.props.length==1){
        result = this.spouseName;
      }

      this.props = await this.advisorService.addHubSubQuestionnares(this.clientID, this.areaSelecterFromUrl, result);

    } catch(e) {

    }
  }

  editMultiquest(areaId: number, oldName: string) {

    const dialogRef = this.dialog.open(ChangePropertyDialogComponent, {
      width: '300px',
      data: {
        oldName: oldName,
        name: this.newQuestionnaireName
       }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.newQuestionnaireName = result;
        this.advisorService.editHubSubQuestionnares(this.areaSelecterFromUrl, this.clientID, areaId, this.newQuestionnaireName ).then(result => {
          this.getHubSubquestionnaires();
        });
      }
    });
  }

  deleteMultiquest(areaId: number, autoCheck: Boolean = false) {
    if (!autoCheck) {
      const dialogRef = this.dialog.open(DialogConfirm, {
        data: 'The questionnaire and all the related data (insights) will be deleted. Are you sure you want to remove this asset? This is an irreversible action.'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.advisorService.deleteHubSubQuestionnares(this.areaSelecterFromUrl, this.clientID, areaId).then(result => {
            this.props = result;

            this.getHubSubquestionnaires();
          });

        }
      });
    } else {

      // Try to apply erase styles to deleted row
      // document.getElementById(this.props[1]._id).style.opacity = '0.35';
      // document.getElementById(this.props[1]._id).style.cursor = 'not-allowed';

      this.advisorService.deleteHubSubQuestionnares(this.areaSelecterFromUrl, this.clientID, areaId).then(result => {
        this.props = result;

        this.getHubSubquestionnaires();
      });
    }

  }

  setStoragedQuestionnareData(area: string, index: number, name: string, answered: boolean) {
    sessionStorage.setItem(area + 'QuestIndex', String(index));
    sessionStorage.setItem(area + 'Name', name);
    sessionStorage.setItem(area + 'HasBeenAnswered', String(answered));
  }

  isAssetLimited(): boolean {
    let isMarried =JSON.parse(sessionStorage.getItem('currentClient')).isMarried;
    if(this.areaSelecterFromUrl == 'disability' || this.areaSelecterFromUrl == 'ltc' || this.areaSelecterFromUrl == 'estate'){
      if(isMarried === 'Married') {
        if(this.props.length >= 2 ) return true;
      }else if(this.props.length >= 1 ){
        return true;
      }
    }
    return false;
  }

  toggleSearch() {
    this.toggleSearchBar = !this.toggleSearchBar;
  }

  /**
   * @name pendoInit
   * @description Initializes the Pendo script if a token was provided. This script reads the props set on advisorInfo inside Local storage.
   * @param none
   */
  pendoInit(){
    console.log('Initializing Pendo on HUb multi...💿');
    initPendo();
  }

}
