import { Injectable } from "@angular/core";
@Injectable({
    providedIn: "root",
})
export class RothCalculator {
    private TABLES: {
        OrdinaryIncomeTaxBracketTables: any;
        CapitalGainsRatesTables: any;
        IRSUniformLifetimeTable: any;
        StandardDeductionTables: any;
        AdditionalMedicareTables: any;
        SocialSecurityBenefitTaxTables: any;
        IRMAABracketsTables: any;
        IRMAADBracketsTables: any;
    };

    /**
     * Set the tax tables from remote data
     * @param tables
     */
    public setTaxTables(tables: any) {
        this.TABLES = tables;
    }

    /**
     * Execute Roth Calculator v2.4.2
     * @param numberOfYearsToConvert
     * @param graphType
     */
    public exec(
        dataset: any,
        numberOfYearsToConvert: number,
        graphType: "one_year" | "multi_year",
        useTargetAge: boolean = false
    ) {
        const periods = [];
        const {
            OrdinaryIncomeTaxBracketTables,
            CapitalGainsRatesTables,
            IRSUniformLifetimeTable,
            StandardDeductionTables,
            AdditionalMedicareTables,
            SocialSecurityBenefitTaxTables,
        } = this.TABLES;

        const numericDataset = (_dataset: any) => {
            let auxDataset: any = {};
            for (const key in _dataset) {
                const value = _dataset[key];
                if (!isNaN(value)) {
                    auxDataset[key] = parseFloat(value);
                } else {
                    auxDataset[key] = value;
                }
            }
            return auxDataset;
        };

        const datasetInNumericFormat = numericDataset(dataset);
        let incomeAtKickOfAge = 0
        const {
            annualConversionAmount,
            currentAge: _currentAge,
            retirementAge: _retirementAge,
            lifeExpectancy,
            targetAge: _targetAge,
            beginningIRAAssetValue,
            investmentRateOfReturn: _investmentRateOfReturn, //#C6
            currentTaxableIncome,
            currentIncomeGrowthRate: _currentIncomeGrowthRate,
            retirementTaxableIncome,
            retirementIncomeGrowthRate: _retirementIncomeGrowthRate,
            // heirsMarginalLTCGTaxRateAtDeath: _heirsMarginalLTCGTaxRateAtDeath,
            heirsOrdinaryIncomeAtTransfer: _heirsMarginalOrdinaryIncomeTaxRateAtDeath, //#E13
            // heirsYearsToWithdraw,
            // heirsOrdinaryIncomeAtTransfer,
            rothCalcFilingStatus: filingStatus,
            withdrawalAmountGross,
            socialSecurityBenefits: _ssBenefits,
            taxableIncomePerYear,
            beginningRothIRAAssetValue, //#E5
            kickoffYear: _kickoffYear, //C18,
        } = datasetInNumericFormat;
        let { conversionAmountPerYear } = datasetInNumericFormat;
        if (!conversionAmountPerYear) {
            conversionAmountPerYear = [];
        }
        // const kickoffYear = 8;
        const {
            is2026Sunset,
            payConvesionTaxesAlternateAccount, //E18
        } = dataset;

        const socialSecurityBenefits = _ssBenefits || 0;
        const targetAge = _targetAge || lifeExpectancy;
        const currentAge = +_currentAge;
        const kickoffYear = +_kickoffYear - currentAge + 1;
        console.log(kickoffYear,_kickoffYear)
        const _taxableIncomePerYear = taxableIncomePerYear;
        // If the retirement age is less than current age, conduct the calculation as if retirementAge == current age.
        const retirementAge = Math.max(_retirementAge, currentAge);
        // retirementAge = useTargetAge && targetAge && targetAge < retirementAge ? targetAge : retirementAge;

        // Convert inputs percentages to decimal values
        const investmentRateOfReturn = _investmentRateOfReturn / 100;
        const currentIncomeGrowthRate = _currentIncomeGrowthRate / 100;
        const retirementIncomeGrowthRate = _retirementIncomeGrowthRate / 100;
        // const heirsMarginalLTCGTaxRateAtDeath = _heirsMarginalLTCGTaxRateAtDeath / 100;
        const heirsMarginalOrdinaryIncomeTaxRateAtDeath =
            _heirsMarginalOrdinaryIncomeTaxRateAtDeath / 100;

        // ////////////////////////////////////////////////////
        // Calculate General Values
        // ////////////////////////////////////////////////////

        const currentYear = new Date().getFullYear();
        // const currentYear = 2021;
        const numOfYearsToConvert = numberOfYearsToConvert;
        const ORDINARY_INCOME_YEAR_SWITCH = 2026;
        const ORDINARY_INCOME_YEAR_SWITCH_TARGET = "2017";

        const withdrawalStartYear = retirementAge - currentAge + currentYear;
        // const withdrawalStartYear = 2021;
        const breakEvenTargetAge = useTargetAge ? targetAge : lifeExpectancy;
        // conversionAmountPerYear[2027] = 200000;
        // conversionAmountPerYear[2028] = 200000;
        // conversionAmountPerYear[2038] = 2000;
        let previousPeriod = null;
        let secondToLastPeriod = null;
        let sumOfConversionTaxPayment = 0;
        for (
            let year = currentYear, age = currentAge, periodNum = 1;
            age <= lifeExpectancy;
            periodNum++, age++, year++
        ) {
            const period = {
                num: periodNum,
                year,
                age,
                conversionAmount_graph: 0,
                taxableIncomeBeforeRMD_table: 0,
                taxableIncomeBeforeRMD_graph: 0,
                taxableIncomeBeforeRMD_inputs: 0,
                taxableIncomeBeforeRMD: 0,
                taxableIncomeBeforeRMD_calculated: 0,
                nontaxableInterest: 0,
                combinedIncomeForSSTaxation: 0,
                portionOfSS: 0,
                portionOfSSstring: "0%",
                ssBenefits: 0,
                hasAdditionalMedicareFlag: false,
                additionalMedicareTax: 0,
                hasSSIFlag: false,
                ssiTax: 0,
                noConversion: {
                    rothIRAEOY: 0,
                    totalAfterTaxPortfolioBalanceWOConversion: 0,
                    taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal: 0,
                    marginalIncomeTaxRateWithMedicareTaxRate: 0,
                    marginalIncomeTaxRateWithMedicareTaxString: "0%",
                    marginalLTCGTaxRate: 0,
                    RMDFactor: 0,
                    traditionalIRABOY: 0,
                    traditionalIRABOYAfterWithdrawal: 0,
                    traditionalIRAValueEOY: 0,
                    RMDAfterWithdrawal: 0,
                    RMDTax: 0,
                    taxPercentage: 0,
                    annualConversionAfterTaxes: 0,
                    netOfTaxRMD: 0,
                    taxableAcctBOY: 0,
                    taxableAcctBOYAfterWithdrawal: 0,
                    taxableAcctGrowth: 0,
                    basis: 0,
                    taxableAcctEOY: 0,
                    rothIRABOY: 0,
                    rothIRABOYAfterWithdrawal: 0,
                    distributionRequested: 0,
                    totalWithdrawal: 0,
                    withdrawalFromTaxableAcct: 0,
                    basisWithdrewFromTaxableAccount: 0,
                    taxesPaidDueToTaxableAcctWithdrawal: 0,
                    grossDistributionFromTradIRA: 0,
                    taxOnTradIRADistribution: 0,
                    withdrawalFromTraditionalIRA: 0,
                    withdrawalFromTraditionalIRATax: 0,
                    withdrawalFromRothIRA: 0,
                    netWithdrawal: 0,
                    withdrawalAccountBOY: 0,
                    withdrawalAccountBasis: 0,
                    withdrawalAccountEOY: 0,
                    savingsImpact: 0,
                    valueToHeir: 0,
                    medicarePartBPremium: 0,
                    rothIRABalance: 0,
                    taxesPaid: 0,
                    traditionalIRABalance: 0,
                    totalAfterTaxPortfolioBalance: 0,
                    remainingRMD: 0,
                },
                conversion: {
                    remainingRMD: 0,
                    rothIRABOY: 0,
                    conversionAmount: 0,
                    taxPayment: 0,
                    rothIRAEOY: 0,
                    taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal: 0,
                    marginalIncomeTaxRate: 0,
                    marginalIncomeTaxRateString: "0%",
                    marginalLTCGTaxRate: 0,
                    RMDFactor: 0,
                    traditionalIRABOY: 0,
                    traditionalIRABOYAfterWithdrawal: 0,
                    traditionalIRAValueEOY: 0,
                    RMDAfterWithdrawal: 0,
                    RMDTax: 0,
                    netOfTaxRMD: 0,
                    taxableAcctBOY: 0,
                    taxableAcctBOYAfterWithdrawal: 0,
                    taxableAcctGrowth: 0,
                    basis: 0,
                    taxableAcctEOY: 0,
                    cashAccountForTaxConversionPaymentEOY: 0,
                    cashAccountEOY: 0,
                    cashAccountTaxBasis: 0,
                    totalWithdrawal: 0,
                    withdrawalFromTaxableAcct: 0,
                    basisWithdrewFromTaxableAccount: 0,
                    taxesPaidDueToTaxableAcctWithdrawal: 0,
                    withdrawalFromTraditionalIRA: 0,
                    withdrawalFromTraditionalIRATax: 0,
                    withdrawalFromRothIRA: 0,
                    netWithdrawal: 0,
                    withdrawalAccountBOY: 0,
                    withdrawalAccountBasis: 0,
                    withdrawalAccountEOY: 0,
                    savingsImpact: 0,
                    grossDistributionFromTradIRA: 0,
                    distributionRequested: 0,
                    taxForDistributionFromTradIRA: 0,
                    valueToHeir: 0,
                    medicarePartBPremium: 0,
                    totalAfterTaxPortfolioBalanceWConversion: 0,
                    taxesPaid: 0,
                    traditionalIRABalance: 0,
                    rothIRABalance: 0,
                    totalAfterTaxPortfolioBalance: 0,
                },
                tradIra_rothIra: {
                    tradIra: 0,
                    rothIra: 0,
                },
                additionalTbl1: {
                    col1: 0,
                    col2: 0,
                },
                additionalTbl2: {
                    col1: 0,
                    col2: 0,
                },
            };

            // ////////////////////////////////////////////////////////////////////////////////////////////////////////
            // Current Period's Tax Tables
            // ////////////////////////////////////////////////////////////////////////////////////////////////////////

            const marginalIncomeTaxRateYear =
                !is2026Sunset || period.year < ORDINARY_INCOME_YEAR_SWITCH
                    ? currentYear
                    : ORDINARY_INCOME_YEAR_SWITCH_TARGET;
            // const marginalIncomeTaxRateYear = currentYear;
            const capitalGainsTaxRateYear = marginalIncomeTaxRateYear;
            const ordinaryIncomeTable =
                OrdinaryIncomeTaxBracketTables[marginalIncomeTaxRateYear][filingStatus];
            const marginalIncomeTableRates = Object.keys(ordinaryIncomeTable);
            const capitalGainsTable =
                CapitalGainsRatesTables[capitalGainsTaxRateYear][filingStatus];
            const capitalGainsTableRates = Object.keys(capitalGainsTable);
            const standardDeduction =
                StandardDeductionTables[marginalIncomeTaxRateYear][filingStatus];
            const additionalMedicareTable =
                AdditionalMedicareTables[currentYear][filingStatus];
            const ssBenefitsTable =
                SocialSecurityBenefitTaxTables[currentYear][filingStatus];

            // ////////////////////////////////////////////////////////////////////////////////////////////////////////
            // Calculate Non-specific Scenario
            // ////////////////////////////////////////////////////////////////////////////////////////////////////////

            // Taxable Income Before RMD #K
            if (previousPeriod === null) {
                // First period
                if (period.age < retirementAge) {
                    period.taxableIncomeBeforeRMD =
                        currentTaxableIncome * (1 + currentIncomeGrowthRate);
                } else {
                    period.taxableIncomeBeforeRMD =
                        retirementTaxableIncome *
                        (1 + retirementIncomeGrowthRate) ** (period.age - retirementAge);
                }
            } else {
                // Period > 1
                if (period.age < retirementAge) {
                    period.taxableIncomeBeforeRMD =
                        previousPeriod.taxableIncomeBeforeRMD_calculated *
                        (1 + currentIncomeGrowthRate);
                } else {
                    period.taxableIncomeBeforeRMD =
                        retirementTaxableIncome *
                        (1 + retirementIncomeGrowthRate) ** (period.age - retirementAge);
                }
            }

            period.taxableIncomeBeforeRMD_calculated = period.taxableIncomeBeforeRMD;
            period.taxableIncomeBeforeRMD_graph = period.taxableIncomeBeforeRMD;

            if (
                taxableIncomePerYear && ![undefined,null].includes(taxableIncomePerYear[year])
            ) {
                period.taxableIncomeBeforeRMD_graph = +taxableIncomePerYear[year];
            }

            // SS Benefits #O
            period.ssBenefits = socialSecurityBenefits;

            // ////////////////////////////////////////////////////////////////////////////////////////////////////////
            // Calculate No Conversion Scenario
            // ////////////////////////////////////////////////////////////////////////////////////////////////////////

            // RMD Factor #V
            if (period.year - period.age >= 1960) {
                if (period.age < 75) {
                    period.noConversion.RMDFactor = 0;
                } else {
                    period.noConversion.RMDFactor =
                        IRSUniformLifetimeTable[period.age] || 0;
                }
            } else if (period.year - period.age <= 1950) {
                period.noConversion.RMDFactor =
                    IRSUniformLifetimeTable[period.age] || 0;
            } else {
                if (period.age < 73) {
                    period.noConversion.RMDFactor = 0;
                } else {
                    period.noConversion.RMDFactor =
                        IRSUniformLifetimeTable[period.age] || 0;
                }
            }

            // Traditional IRA BOY #W
            if (previousPeriod === null) {
                period.noConversion.traditionalIRABOY = beginningIRAAssetValue;
            } else {
                period.noConversion.traditionalIRABOY =
                    previousPeriod.noConversion.traditionalIRAValueEOY;
            }

            // Taxable Acct BOY #AD
            if (previousPeriod === null) {
                period.noConversion.taxableAcctBOY = 0;
            } else {
                period.noConversion.taxableAcctBOY =
                    previousPeriod.noConversion.taxableAcctEOY;
            }

            // Total Withdrawal (?)
            if (withdrawalStartYear > period.year) {
                period.noConversion.totalWithdrawal = 0;
            } else {
                period.noConversion.totalWithdrawal = withdrawalAmountGross;
            }

            // Roth IRA BOY #AI
            if (previousPeriod === null) {
                period.noConversion.rothIRABOY = beginningRothIRAAssetValue;
            } else {
                period.noConversion.rothIRABOY =
                    previousPeriod.noConversion.rothIRABOYAfterWithdrawal *
                    (1 + investmentRateOfReturn);
            }

            // Distribution Requested #AL
            period.noConversion.distributionRequested =
                withdrawalStartYear > period.year
                    ? 0
                    : Math.min(
                        withdrawalAmountGross,
                        period.noConversion.traditionalIRABOY +
                        period.noConversion.taxableAcctBOY +
                        period.noConversion.rothIRABOY
                    );

            // Withdrawal from Taxable Acct #AM
            if (period.noConversion.taxableAcctBOY === 0) {
                period.noConversion.withdrawalFromTaxableAcct = 0;
            } else {
                period.noConversion.withdrawalFromTaxableAcct = Math.min(
                    period.noConversion.taxableAcctBOY,
                    period.noConversion.distributionRequested
                );
            }

            // Basis withdrew from Taxable Account #AN
            if (previousPeriod === null) {
                period.noConversion.basisWithdrewFromTaxableAccount = 0;
            } else {
                if (period.noConversion.taxableAcctBOY === 0) {
                    period.noConversion.basisWithdrewFromTaxableAccount = 0;
                } else {
                    period.noConversion.basisWithdrewFromTaxableAccount =
                        period.noConversion.withdrawalFromTaxableAcct *
                        (previousPeriod.noConversion.basis /
                            period.noConversion.taxableAcctBOY);
                }
            }

            // Withdrawal from Traditional IRA - NOTFOUND
            if (period.noConversion.traditionalIRABOY === 0) {
                period.noConversion.withdrawalFromTraditionalIRA = 0;
            } else {
                period.noConversion.withdrawalFromTraditionalIRA = Math.min(
                    period.noConversion.traditionalIRABOY,
                    period.noConversion.totalWithdrawal -
                    period.noConversion.withdrawalFromTaxableAcct
                );
            }

            // Gross Distribution From Trad IRA #AP
            period.noConversion.grossDistributionFromTradIRA =
                period.noConversion.traditionalIRABOY === 0
                    ? 0
                    : Math.min(
                        period.noConversion.traditionalIRABOY,
                        period.noConversion.distributionRequested -
                        period.noConversion.withdrawalFromTaxableAcct
                    );

            // Taxable Acct BOY after withdrawal #AE
            period.noConversion.taxableAcctBOYAfterWithdrawal =
                period.noConversion.taxableAcctBOY -
                period.noConversion.withdrawalFromTaxableAcct;

            // Taxable Acct Growth #AF
            period.noConversion.taxableAcctGrowth =
                period.noConversion.taxableAcctBOYAfterWithdrawal *
                investmentRateOfReturn;

            // Withdrawal from Roth IRA #AR
            if (period.noConversion.rothIRABOY === 0) {
                period.noConversion.withdrawalFromRothIRA = 0;
            } else {
                period.noConversion.withdrawalFromRothIRA = Math.min(
                    period.noConversion.rothIRABOY,
                    period.noConversion.distributionRequested -
                    period.noConversion.withdrawalFromTaxableAcct -
                    period.noConversion.grossDistributionFromTradIRA
                );
            }

            // Roth IRA BOY after withdrawal #AJ
            period.noConversion.rothIRABOYAfterWithdrawal =
                period.noConversion.rothIRABOY -
                period.noConversion.withdrawalFromRothIRA;

            // Roth IRA EOY #AK
            period.noConversion.rothIRAEOY =
                period.noConversion.rothIRABOYAfterWithdrawal *
                (1 + investmentRateOfReturn);

            // Traditional IRA BOY after withdrawal #X
            period.noConversion.traditionalIRABOYAfterWithdrawal =
                period.noConversion.traditionalIRABOY -
                    period.noConversion.grossDistributionFromTradIRA <
                    0
                    ? 0
                    : period.noConversion.traditionalIRABOY -
                    period.noConversion.grossDistributionFromTradIRA;

            // RMD After Withdrawal - RMD #Z
            if (previousPeriod === null) {
                period.noConversion.RMDAfterWithdrawal =
                    period.noConversion.RMDFactor === 0
                        ? 0
                        : Math.min(
                            period.noConversion.traditionalIRABOYAfterWithdrawal,
                            period.noConversion.traditionalIRABOY /
                            period.noConversion.RMDFactor
                        );
            } else {
                period.noConversion.RMDAfterWithdrawal =
                    period.noConversion.RMDFactor === 0
                        ? 0
                        : previousPeriod.noConversion.traditionalIRAValueEOY /
                        period.noConversion.RMDFactor;
            }

            //Remaining RMD (After Traditional IRA Withdrawal) #AA
            period.noConversion.remainingRMD = Math.max(
                0,
                period.noConversion.RMDAfterWithdrawal -
                period.noConversion.grossDistributionFromTradIRA
            );

            // Combined Income #M
            period.combinedIncomeForSSTaxation =
                period.taxableIncomeBeforeRMD +
                period.noConversion.RMDAfterWithdrawal +
                period.noConversion.grossDistributionFromTradIRA +
                (period.noConversion.withdrawalFromTaxableAcct -
                    period.noConversion.basisWithdrewFromTaxableAccount) +
                period.nontaxableInterest +
                0.5 * period.ssBenefits;

            // Portion of SS #N
            const ssThresholdsRates = Object.keys(ssBenefitsTable);
            for (let i = 0; i < ssThresholdsRates.length; i++) {
                const rate = ssThresholdsRates[i];
                const topThreshold = ssBenefitsTable[rate] || Infinity;
                // console.log(period.combinedIncomeForSSTaxation, topThreshold)
                if (period.combinedIncomeForSSTaxation < topThreshold) {
                    period.portionOfSS = +rate.replace("%", "") / 100;
                    period.portionOfSSstring = rate;
                    break;
                }
            }

            const RMDAfterWithdrawalFx = () => {
                // Tax on Traditional IRA Distribution
                let taxOnTradIRADistribution =
                    period.noConversion.grossDistributionFromTradIRA *
                    period.noConversion.marginalIncomeTaxRateWithMedicareTaxRate;

                // Traditional IRA BOY after withdrawal
                let traditionalIRABOYAfterWithdrawal = Math.max(
                    0,
                    period.noConversion.traditionalIRABOY -
                    period.noConversion.grossDistributionFromTradIRA -
                    taxOnTradIRADistribution
                );

                // RMD After Withdrawal
                let RMDAfterWithdrawal = 0;
                if (previousPeriod === null) {
                    RMDAfterWithdrawal =
                        period.noConversion.RMDFactor === 0
                            ? 0
                            : Math.min(
                                traditionalIRABOYAfterWithdrawal,
                                period.noConversion.traditionalIRABOY /
                                period.noConversion.RMDFactor
                            );
                } else {
                    RMDAfterWithdrawal =
                        period.noConversion.RMDFactor === 0
                            ? 0
                            : previousPeriod.noConversion.traditionalIRAValueEOY /
                            period.noConversion.RMDFactor;
                }

                return RMDAfterWithdrawal;
            };

            // Taxable Income w/RMD & Withdrawal from IRA & Taxable portion from Taxable account withdrawal #P
            period.noConversion.taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal =
                period.taxableIncomeBeforeRMD +
                period.noConversion.remainingRMD +
                period.noConversion.grossDistributionFromTradIRA +
                (period.noConversion.withdrawalFromTaxableAcct -
                    period.noConversion.basisWithdrewFromTaxableAccount) +
                period.ssBenefits * period.portionOfSS;

            if (
                taxableIncomePerYear && ![undefined,null].includes(taxableIncomePerYear[year])
            ) {
                period.noConversion.taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal =
                    +taxableIncomePerYear[year];
            }
            if (+period.age === +_kickoffYear+1) {
                incomeAtKickOfAge = period.noConversion.taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal
            }
            // Marginal Income Tax Rate with Medicare Tax Rate #Q
            if (
                period.noConversion
                    .taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal <
                standardDeduction
            ) {
                period.noConversion.marginalIncomeTaxRateWithMedicareTaxRate = 0;
                period.noConversion.marginalIncomeTaxRateWithMedicareTaxString = "0%";
            } else {
                for (let i = 0; i < marginalIncomeTableRates.length; i++) {
                    const rate = marginalIncomeTableRates[i];
                    const topThreshold = ordinaryIncomeTable[rate] || Infinity;
                    if (
                        period.noConversion
                            .taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal <=
                        topThreshold
                    ) {
                        const rateAsDecimal = +rate.replace("%", "") / 100;
                        let finalRate = rateAsDecimal;
                        const medicareRate = Object.keys(additionalMedicareTable)[0];
                        const medicareThreshold = additionalMedicareTable[medicareRate];
                        if (period.taxableIncomeBeforeRMD > medicareThreshold) {
                            finalRate += +medicareRate.replace("%", "") / 100;
                        }

                        period.noConversion.marginalIncomeTaxRateWithMedicareTaxRate =
                            finalRate;
                        period.noConversion.marginalIncomeTaxRateWithMedicareTaxString =
                            Math.ceil(this.getPercentage(finalRate)).toFixed(1) + "%";
                        break;
                    }
                }
            }

            // Marginal LTCG Tax Rate #U
            for (let i = 0; i < capitalGainsTableRates.length; i++) {
                const rate = capitalGainsTableRates[i];
                const topThreshold = capitalGainsTable[rate] || Infinity;
                if (
                    period.noConversion
                        .taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal <
                    topThreshold
                ) {
                    period.noConversion.marginalLTCGTaxRate =
                        +rate.replace("%", "") / 100;
                    break;
                }
            }

            // Tax on Traditional IRA Distributionn #AQ
            period.noConversion.taxOnTradIRADistribution =
                period.noConversion.grossDistributionFromTradIRA *
                period.noConversion.marginalIncomeTaxRateWithMedicareTaxRate;

            // Traditional IRA Value EOY #G / #Y
            period.noConversion.traditionalIRAValueEOY = Math.max(
                0,
                period.noConversion.traditionalIRABOYAfterWithdrawal *
                (1 + investmentRateOfReturn) -
                // - period.noConversion.RMDAfterWithdrawal
                period.noConversion.remainingRMD
            );

            // Withdrawal from Traditional IRA Tax -NOTFOUND
            period.noConversion.withdrawalFromTraditionalIRATax =
                period.noConversion.withdrawalFromTraditionalIRA *
                period.noConversion.marginalIncomeTaxRateWithMedicareTaxRate;

            // RMD Tax #AB
            period.noConversion.RMDTax =
                period.noConversion.remainingRMD *
                period.noConversion.marginalIncomeTaxRateWithMedicareTaxRate;

            // Tax % -NOTFOUND
            period.noConversion.taxPercentage = Math.max(
                period.noConversion.RMDAfterWithdrawal > 0
                    ? period.noConversion.RMDTax / period.noConversion.RMDAfterWithdrawal
                    : 0,
                period.noConversion.withdrawalFromTraditionalIRA > 0
                    ? period.noConversion.withdrawalFromTraditionalIRATax /
                    period.noConversion.withdrawalFromTraditionalIRA
                    : 0
            );

            // Net of Tax RMD #AC
            period.noConversion.netOfTaxRMD =
                period.noConversion.remainingRMD *
                (1 - period.noConversion.marginalIncomeTaxRateWithMedicareTaxRate);

            // Basis - Taxable Acct Basis #AG
            if (previousPeriod === null) {
                period.noConversion.basis =
                    period.noConversion.netOfTaxRMD -
                    period.noConversion.basisWithdrewFromTaxableAccount;
            } else {
                period.noConversion.basis =
                    previousPeriod.noConversion.basis +
                    period.noConversion.netOfTaxRMD -
                    period.noConversion.basisWithdrewFromTaxableAccount;
            }

            // Taxes Paid due to Taxable Acct Withdrawal #AO
            if (previousPeriod === null) {
                period.noConversion.taxesPaidDueToTaxableAcctWithdrawal = 0;
            } else {
                if (period.noConversion.taxableAcctBOY === 0) {
                    period.noConversion.taxesPaidDueToTaxableAcctWithdrawal = 0;
                } else {
                    period.noConversion.taxesPaidDueToTaxableAcctWithdrawal =
                        period.noConversion.withdrawalFromTaxableAcct *
                        (1 -
                            previousPeriod.noConversion.basis /
                            period.noConversion.taxableAcctBOY) *
                        period.noConversion.marginalLTCGTaxRate;
                }
            }

            // Taxable Acct EOY #AH
            period.noConversion.taxableAcctEOY =
                period.noConversion.netOfTaxRMD +
                period.noConversion.taxableAcctBOYAfterWithdrawal +
                period.noConversion.taxableAcctGrowth;

            // Net Withdrawal #AS
            if (period.noConversion.distributionRequested === 0) {
                period.noConversion.netWithdrawal = 0;
            } else {
                period.noConversion.netWithdrawal =
                    period.noConversion.withdrawalFromTaxableAcct -
                    period.noConversion.taxesPaidDueToTaxableAcctWithdrawal +
                    period.noConversion.grossDistributionFromTradIRA -
                    period.noConversion.taxOnTradIRADistribution +
                    period.noConversion.withdrawalFromRothIRA;
            }

            // Withdrawal Account BOY #AT
            if (previousPeriod === null) {
                period.noConversion.withdrawalAccountBOY = 0;
            } else {
                period.noConversion.withdrawalAccountBOY =
                    previousPeriod.noConversion.withdrawalAccountEOY;
            }

            // Withdrawal Account Basis #AU
            if (previousPeriod === null) {
                period.noConversion.withdrawalAccountBasis =
                    period.noConversion.netWithdrawal;
            } else {
                period.noConversion.withdrawalAccountBasis =
                    previousPeriod.noConversion.withdrawalAccountBasis +
                    period.noConversion.netWithdrawal;
            }

            // Withdrawal Account EOY #AV
            period.noConversion.withdrawalAccountEOY =
                (period.noConversion.netWithdrawal +
                    period.noConversion.withdrawalAccountBOY) *
                (1 + investmentRateOfReturn);

            // Medicare Part B Premium #AW
            if (period.age < 65 || [1, 2].includes(period.num)) {
                period.noConversion.medicarePartBPremium = 0;
            } else {
                period.noConversion.medicarePartBPremium =
                    this.getIrmaaMonthlyPremium(
                        filingStatus,
                        currentYear,
                        secondToLastPeriod.noConversion
                            .taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal +
                        secondToLastPeriod.nontaxableInterest
                    ) * 12;
            }

            // Savings Impact
            period.noConversion.savingsImpact =
                period.noConversion.traditionalIRAValueEOY +
                period.noConversion.taxableAcctEOY;

            // const heirWithdrawalPerYear = period.noConversion.traditionalIRAValueEOY / (heirsYearsToWithdraw || 1);
            // const heirTaxRateString = this.calculateTaxBracket(heirWithdrawalPerYear, is2026Sunset ? +ORDINARY_INCOME_YEAR_SWITCH_TARGET : currentYear, filingStatus);
            // const heirTaxRate = +heirTaxRateString.replace('%', '') / 100;
            // const heirWithdrawalPerYearAfterTax = heirWithdrawalPerYear * (1 - heirTaxRate);

            // Value to heir #T
            period.noConversion.valueToHeir =
                period.noConversion.traditionalIRAValueEOY *
                (1 - heirsMarginalOrdinaryIncomeTaxRateAtDeath) +
                period.noConversion.taxableAcctEOY +
                period.noConversion.rothIRAEOY;

            // ////////////////////////////////////////////////////////////////////////////////////////////////////////
            // Calculate Conversion Scenario
            // ////////////////////////////////////////////////////////////////////////////////////////////////////////

            // Roth IRA BOY #AX
            if (previousPeriod === null) {
                period.conversion.rothIRABOY = beginningRothIRAAssetValue;
            } else {
                period.conversion.rothIRABOY = previousPeriod.conversion.rothIRAEOY;
            }

            // RMD Factor #BK
            if (period.year - period.age >= 1960) {
                if (period.age < 75) {
                    period.conversion.RMDFactor = 0;
                } else {
                    period.conversion.RMDFactor =
                        IRSUniformLifetimeTable[period.age] || 0;
                }
            } else if (period.year - period.age <= 1950) {
                period.conversion.RMDFactor = IRSUniformLifetimeTable[period.age] || 0;
            } else {
                if (period.age < 73) {
                    period.conversion.RMDFactor = 0;
                } else {
                    period.conversion.RMDFactor =
                        IRSUniformLifetimeTable[period.age] || 0;
                }
            }

            // Traditional IRA BOY #BL
            if (previousPeriod === null) {
                period.conversion.traditionalIRABOY = beginningIRAAssetValue;
            } else {
                period.conversion.traditionalIRABOY =
                    previousPeriod.conversion.traditionalIRAValueEOY;
            }

            // Conversion Amount #AZ

            if (
                period.num <= +dataset.numOfYearsToConvert + kickoffYear - 1 &&
                period.num >= kickoffYear
            ) {
                period.conversion.conversionAmount = annualConversionAmount;
            }
            if (
                conversionAmountPerYear && ![undefined,null].includes(conversionAmountPerYear[year])
            ) {
                period.conversion.conversionAmount = conversionAmountPerYear[year];
            }
            period.conversion.conversionAmount = Math.min(
                period.conversion.conversionAmount,
                period.conversion.traditionalIRABOY
            );

            conversionAmountPerYear[year] = period.conversion.conversionAmount;
            period.conversionAmount_graph = period.conversion.conversionAmount;

            // Taxable Acct BOY #BS
            if (previousPeriod === null) {
                period.conversion.taxableAcctBOY = 0;
            } else {
                period.conversion.taxableAcctBOY =
                    previousPeriod.conversion.taxableAcctEOY;
            }

            // Total Withdrawal (?) notfound
            if (withdrawalStartYear > period.year) {
                period.conversion.totalWithdrawal = 0;
            } else {
                period.conversion.totalWithdrawal = withdrawalAmountGross;
            }

            // Distribution Requested #BX
            period.conversion.distributionRequested =
                withdrawalStartYear > period.year
                    ? 0
                    : Math.min(
                        withdrawalAmountGross,
                        period.conversion.rothIRABOY +
                        period.conversion.traditionalIRABOY +
                        period.conversion.taxableAcctBOY
                    );

            // Withdrawal from Taxable Acct #BY
            if (period.conversion.taxableAcctBOY === 0) {
                period.conversion.withdrawalFromTaxableAcct = 0;
            } else {
                period.conversion.withdrawalFromTaxableAcct = Math.min(
                    period.conversion.taxableAcctBOY,
                    period.conversion.distributionRequested
                );
            }

            // Basis withdrew from Taxable Account #BZ
            if (previousPeriod === null) {
                period.conversion.basisWithdrewFromTaxableAccount = 0;
            } else {
                if (period.conversion.taxableAcctBOY === 0) {
                    period.conversion.basisWithdrewFromTaxableAccount = 0;
                } else {
                    period.conversion.basisWithdrewFromTaxableAccount =
                        period.conversion.withdrawalFromTaxableAcct *
                        (previousPeriod.conversion.basis /
                            period.conversion.taxableAcctBOY);
                }
            }

            // Withdrawal from Traditional IRA NOTFOUND
            if (period.conversion.traditionalIRABOY === 0) {
                period.conversion.withdrawalFromTraditionalIRA = 0;
            } else {
                period.conversion.withdrawalFromTraditionalIRA = Math.min(
                    period.conversion.traditionalIRABOY,
                    period.conversion.totalWithdrawal -
                    period.conversion.withdrawalFromTaxableAcct
                );
            }

            // Gross Distribution from Trad IRA #CB
            period.conversion.grossDistributionFromTradIRA =
                period.conversion.traditionalIRABOY === 0
                    ? 0
                    : Math.min(
                        period.conversion.traditionalIRABOY,
                        period.conversion.distributionRequested -
                        period.conversion.withdrawalFromTaxableAcct
                    );

            // Taxable Acct BOY after withdrawal #BT
            period.conversion.taxableAcctBOYAfterWithdrawal =
                period.conversion.taxableAcctBOY -
                period.conversion.withdrawalFromTaxableAcct;

            // Taxable Acct Growth #BU
            period.conversion.taxableAcctGrowth =
                period.conversion.taxableAcctBOYAfterWithdrawal *
                investmentRateOfReturn;

            // Withdrawal from Roth IRA #CD
            if (period.conversion.rothIRABOY === 0) {
                period.conversion.withdrawalFromRothIRA = 0;
            } else {
                period.conversion.withdrawalFromRothIRA = Math.min(
                    period.conversion.rothIRABOY,
                    period.conversion.distributionRequested -
                    period.conversion.withdrawalFromTaxableAcct -
                    period.conversion.grossDistributionFromTradIRA
                );
            }

            const RMDAfterWithdrawalFx_Conversion = () => {
                return period.conversion.RMDFactor === 0
                    ? 0
                    : previousPeriod !== null
                        ? previousPeriod.conversion.traditionalIRAValueEOY /
                        period.conversion.RMDFactor
                        : beginningIRAAssetValue / period.conversion.RMDFactor;
            };

            // RMD #BO
            period.conversion.RMDAfterWithdrawal =
                period.conversion.RMDFactor === 0
                    ? 0
                    : previousPeriod !== null
                        ? previousPeriod.conversion.traditionalIRAValueEOY /
                        period.conversion.RMDFactor
                        : beginningIRAAssetValue / period.conversion.RMDFactor;

            // Remaining RMD (After Traditional IRA Withdrawal) #BP
            period.conversion.remainingRMD = Math.max(
                0,
                period.conversion.RMDAfterWithdrawal -
                period.conversion.grossDistributionFromTradIRA
            );

            // Taxable Income w/RMD & Withdrawal from IRA & Taxable portion from Taxable account withdrawal #BG
            // K50
            // +BN50
            // +BZ50
            // +(BW50-BX50)
            // +O50*N50+AY50
            period.conversion.taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal =
                period.taxableIncomeBeforeRMD +
                period.conversion.remainingRMD +
                period.conversion.grossDistributionFromTradIRA +
                (period.conversion.withdrawalFromTaxableAcct -
                    period.conversion.basisWithdrewFromTaxableAccount) +
                period.ssBenefits * period.portionOfSS +
                period.conversion.conversionAmount;

            if (
                taxableIncomePerYear && ![undefined,null].includes(taxableIncomePerYear[year])
            ) {
                period.conversion.taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal =
                    +taxableIncomePerYear[year] + period.conversion.conversionAmount;
            }

            // Marginal Income Tax Rate #BI
            if (
                period.conversion
                    .taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal <
                standardDeduction
            ) {
                period.conversion.marginalIncomeTaxRate = 0;
                period.conversion.marginalIncomeTaxRateString = "0%";
            } else {
                for (let i = 0; i < marginalIncomeTableRates.length; i++) {
                    const rate = marginalIncomeTableRates[i];
                    const topThreshold = ordinaryIncomeTable[rate] || Infinity;
                    if (
                        period.conversion
                            .taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal <=
                        topThreshold
                    ) {
                        const rateAsDecimal = +rate.replace("%", "") / 100;
                        let finalRate = rateAsDecimal;
                        const medicareRate = Object.keys(additionalMedicareTable)[0];
                        const medicareThreshold = additionalMedicareTable[medicareRate];
                        if (period.taxableIncomeBeforeRMD > medicareThreshold) {
                            finalRate += +medicareRate.replace("%", "") / 100;
                        }
                        period.conversion.marginalIncomeTaxRate = finalRate;
                        period.conversion.marginalIncomeTaxRateString =
                            Math.ceil(this.getPercentage(finalRate)).toFixed(1) + "%";
                        break;
                    }
                }
            }

            // Marginal LTCG Tax Rate #BJ
            for (let i = 0; i < capitalGainsTableRates.length; i++) {
                const rate = capitalGainsTableRates[i];
                const topThreshold = capitalGainsTable[rate] || Infinity;
                if (
                    period.conversion
                        .taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal <
                    topThreshold
                ) {
                    period.conversion.marginalLTCGTaxRate = +rate.replace("%", "") / 100;
                    break;
                }
            }

            // Tax for Distribution from Trad IRA #CC
            period.conversion.taxForDistributionFromTradIRA =
                period.conversion.grossDistributionFromTradIRA *
                period.conversion.marginalIncomeTaxRate;

            // Traditional IRA BOY after withdrawal #BM
            period.conversion.traditionalIRABOYAfterWithdrawal = Math.max(
                0,
                period.conversion.traditionalIRABOY -
                period.conversion.grossDistributionFromTradIRA -
                period.conversion.conversionAmount
            );

            // RMD Tax #BQ
            period.conversion.RMDTax =
                period.conversion.remainingRMD *
                period.conversion.marginalIncomeTaxRate;

            // Net of Tax RMD #BR
            period.conversion.netOfTaxRMD =
                period.conversion.remainingRMD - period.conversion.RMDTax;

            // // RMD After Withdrawal
            // period.conversion.RMDAfterWithdrawal = period.conversion.RMDFactor === 0
            // ? 0
            // : previousPeriod !== null
            //     ? previousPeriod.conversion.traditionalIRAValueEOY / period.conversion.RMDFactor
            //     : beginningIRAAssetValue/period.conversion.RMDFactor

            // Traditional IRA Value EOY #BN
            if (period.conversion.traditionalIRABOYAfterWithdrawal === 0) {
                period.conversion.traditionalIRAValueEOY = 0;
            } else {
                period.conversion.traditionalIRAValueEOY =
                    period.conversion.traditionalIRABOYAfterWithdrawal *
                    (1 + investmentRateOfReturn) -
                    // - period.conversion.RMDAfterWithdrawal;
                    period.conversion.remainingRMD;
            }

            // Withdrawal from Traditional IRA Tax  -NOTFOUND
            period.conversion.withdrawalFromTraditionalIRATax =
                period.conversion.withdrawalFromTraditionalIRA *
                period.conversion.marginalIncomeTaxRate;

            // Basis - Taxable Acct Basis #BV
            if (previousPeriod === null) {
                period.conversion.basis = 
                period.conversion.netOfTaxRMD - 
                period.conversion.basisWithdrewFromTaxableAccount;
            } else {
                period.conversion.basis =
                    previousPeriod.conversion.basis +
                    period.conversion.netOfTaxRMD -
                    period.conversion.basisWithdrewFromTaxableAccount;
            }

            // Taxes Paid due to Taxable Acct Withdrawal #CA
            if (previousPeriod === null) {
                period.conversion.taxesPaidDueToTaxableAcctWithdrawal = 0;
            } else {
                if (period.conversion.taxableAcctBOY === 0) {
                    period.conversion.taxesPaidDueToTaxableAcctWithdrawal = 0;
                } else {
                    period.conversion.taxesPaidDueToTaxableAcctWithdrawal =
                        period.conversion.withdrawalFromTaxableAcct *
                        (1 -
                            previousPeriod.conversion.basis /
                            period.conversion.taxableAcctBOY) *
                        period.conversion.marginalLTCGTaxRate;
                }
            }

            // Taxable Acct EOY #BW
            period.conversion.taxableAcctEOY =
                period.conversion.netOfTaxRMD +
                period.conversion.taxableAcctBOYAfterWithdrawal +
                period.conversion.taxableAcctGrowth;

            // Net Withdrawal #CE
            if (period.conversion.distributionRequested === 0) {
                period.conversion.netWithdrawal = 0;
            } else {
                period.conversion.netWithdrawal =
                    period.conversion.withdrawalFromTaxableAcct -
                    period.conversion.taxesPaidDueToTaxableAcctWithdrawal +
                    period.conversion.grossDistributionFromTradIRA -
                    period.conversion.taxForDistributionFromTradIRA +
                    period.conversion.withdrawalFromRothIRA;
            }

            // Withdrawal Account BOY #CF
            if (previousPeriod === null) {
                period.conversion.withdrawalAccountBOY = 0;
            } else {
                period.conversion.withdrawalAccountBOY =
                    previousPeriod.conversion.withdrawalAccountEOY;
            }

            // Withdrawal Account Basis #CG
            if (previousPeriod === null) {
                period.conversion.withdrawalAccountBasis =
                    period.conversion.netWithdrawal;
            } else {
                period.conversion.withdrawalAccountBasis =
                    previousPeriod.conversion.withdrawalAccountBasis +
                    period.conversion.netWithdrawal;
            }

            // Withdrawal Account EOY #CH
            period.conversion.withdrawalAccountEOY =
                (period.conversion.netWithdrawal +
                    period.conversion.withdrawalAccountBOY) *
                (1 + investmentRateOfReturn);

            // Tax Payment - Tax Payment from Roth IRA #BA
            period.conversion.taxPayment =
                period.conversion.conversionAmount *
                period.conversion.marginalIncomeTaxRate;

            // Cash account (for tax conversion payment) EOY #BC
            if (previousPeriod === null) {
                period.conversion.cashAccountForTaxConversionPaymentEOY =
                    payConvesionTaxesAlternateAccount ? -period.conversion.taxPayment : 0;
            } else {
                period.conversion.cashAccountForTaxConversionPaymentEOY =
                    payConvesionTaxesAlternateAccount
                        ? previousPeriod.conversion.cashAccountEOY -
                        period.conversion.taxPayment
                        : 0;
            }

            // Cash account EOY #BD
            period.conversion.cashAccountEOY =
                period.conversion.cashAccountForTaxConversionPaymentEOY *
                (1 + investmentRateOfReturn);

            // Cash Account Tax Basis #BE
            period.conversion.cashAccountTaxBasis = payConvesionTaxesAlternateAccount
                ? -(period.conversion.taxPayment + sumOfConversionTaxPayment)
                : 0;

            // Roth IRA EOY #H/#BB
            period.conversion.rothIRAEOY = !payConvesionTaxesAlternateAccount
                ? (period.conversion.rothIRABOY +
                    period.conversion.conversionAmount -
                    period.conversion.taxPayment -
                    period.conversion.withdrawalFromRothIRA) *
                (1 + investmentRateOfReturn)
                : (period.conversion.rothIRABOY +
                    period.conversion.conversionAmount -
                    period.conversion.withdrawalFromRothIRA) *
                (1 + investmentRateOfReturn);

            // Medicare Part B Premium #CI
            if (period.age < 65 || [1, 2].includes(period.num)) {
                period.conversion.medicarePartBPremium = 0;
            } else {
                period.conversion.medicarePartBPremium =
                    this.getIrmaaMonthlyPremium(
                        filingStatus,
                        currentYear,
                        secondToLastPeriod.conversion
                            .taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal +
                        secondToLastPeriod.nontaxableInterest
                    ) * 12;
            }

            // Savings Impact ***Traditional IRA balance conversion**
            period.conversion.savingsImpact =
                period.conversion.rothIRAEOY +
                period.conversion.taxableAcctEOY +
                period.conversion.traditionalIRAValueEOY;

            // Additional Medicare Tax #BH
            const additionalMedicareIncome =
                period.conversion
                    .taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal;
            const additionalMedicareRateString = Object.keys(
                additionalMedicareTable
            )[0];
            const additionalMedicareThreshold =
                additionalMedicareTable[additionalMedicareRateString];

            if (additionalMedicareThreshold <= additionalMedicareIncome) {
                const additionalMedicareRate =
                    +additionalMedicareRateString.replace("%", "") / 100;

                period.hasAdditionalMedicareFlag = true;
                period.additionalMedicareTax =
                    (additionalMedicareIncome - additionalMedicareThreshold) *
                    additionalMedicareRate;
            } else {
                period.hasAdditionalMedicareFlag = false;
                period.additionalMedicareTax = 0;
            }

            // SSI Tax
            period.hasSSIFlag = period.portionOfSS === 0.85;
            period.ssiTax = period.hasSSIFlag
                ? period.combinedIncomeForSSTaxation * period.portionOfSS
                : 0;

            // const heirWithdrawalPerYearC = period.conversion.traditionalIRAValueEOY / (heirsYearsToWithdraw || 1);
            // const heirTaxRateStringC = this.calculateTaxBracket(heirWithdrawalPerYearC, is2026Sunset ? +ORDINARY_INCOME_YEAR_SWITCH_TARGET : currentYear, filingStatus);
            // const heirTaxRateC = +heirTaxRateStringC.replace('%', '') / 100;
            // const heirWithdrawalPerYearAfterTaxC = heirWithdrawalPerYearC * (1 - heirTaxRateC);

            //Value to Heir #BF
            period.conversion.valueToHeir =
                period.conversion.traditionalIRAValueEOY *
                (1 - heirsMarginalOrdinaryIncomeTaxRateAtDeath) +
                period.conversion.taxableAcctEOY +
                period.conversion.rothIRAEOY +
                period.conversion.cashAccountEOY;

            /////////////////////////////////////////////////////////
            //                        Graphic                       //
            /////////////////////////////////////////////////////////
            // Final_Version!Y50*(1-Final_Version!Q50)
            // +Final_Version!AH50-(Final_Version!AH50-Final_Version!AG50)*Final_Version!U50
            // +Final_Version!AK50

            period.noConversion.totalAfterTaxPortfolioBalanceWOConversion =
                period.noConversion.traditionalIRAValueEOY *
                (1 - period.noConversion.marginalIncomeTaxRateWithMedicareTaxRate) +
                period.noConversion.taxableAcctEOY -
                (period.noConversion.taxableAcctEOY - period.noConversion.basis) *
                period.noConversion.marginalLTCGTaxRate +
                period.noConversion.rothIRAEOY;

            period.conversion.totalAfterTaxPortfolioBalanceWConversion =
                period.conversion.rothIRAEOY +
                period.conversion.traditionalIRAValueEOY *
                (1 - period.conversion.marginalIncomeTaxRate) +
                period.conversion.taxableAcctEOY -
                (period.conversion.taxableAcctEOY - period.conversion.basis) *
                period.conversion.marginalLTCGTaxRate +
                period.conversion.cashAccountEOY -
                (period.conversion.cashAccountEOY -
                    period.conversion.cashAccountTaxBasis) *
                period.conversion.marginalLTCGTaxRate;

            /////////////////////////////////////////////////////////
            //                         TABLE                       //
            /////////////////////////////////////////////////////////

            period.noConversion.totalAfterTaxPortfolioBalance =
                period.noConversion.totalAfterTaxPortfolioBalanceWOConversion;

            period.conversion.totalAfterTaxPortfolioBalance =
                period.conversion.totalAfterTaxPortfolioBalanceWConversion;

            //Total After Tax Portfolio Balance W/O Conversion // Traditional IRA balance
            period.noConversion.traditionalIRABalance =
                period.noConversion.traditionalIRAValueEOY;
            period.conversion.traditionalIRABalance =
                period.conversion.traditionalIRAValueEOY;

            // Roth IRA Balance
            period.noConversion.rothIRABalance = period.noConversion.rothIRAEOY;
            period.conversion.rothIRABalance = period.conversion.rothIRAEOY;

            //Taxable Account
            period.noConversion.taxableAcctEOY;
            period.conversion.taxableAcctEOY;

            //Taxes Paid
            period.noConversion.taxesPaid =
                period.noConversion.taxesPaidDueToTaxableAcctWithdrawal +
                period.noConversion.RMDTax +
                period.noConversion.taxOnTradIRADistribution;
            period.conversion.taxesPaid =
                period.conversion.taxesPaidDueToTaxableAcctWithdrawal +
                period.conversion.taxForDistributionFromTradIRA +
                period.conversion.RMDTax +
                period.conversion.taxPayment;

            //Conversion amount
            period.conversion.conversionAmount;

            //Income ammount
            period.noConversion
                .taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal;
            period.conversion
                .taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal;

            //Value to heir
            period.noConversion.valueToHeir;
            period.conversion.valueToHeir;

            // Annual Medicare Part B Premium
            period.noConversion.medicarePartBPremium;
            period.conversion.medicarePartBPremium;

            //Withdrawal account (taxable)
            period.noConversion.withdrawalAccountEOY;
            period.conversion.withdrawalAccountEOY;

            //Marginal tax bracket
            period.noConversion.marginalIncomeTaxRateWithMedicareTaxString;
            period.conversion.marginalIncomeTaxRateString;

            //RMD
            period.noConversion.RMDAfterWithdrawal
            period.conversion.RMDAfterWithdrawal
            // ////////////////////////////////////////////////////////////////////////////////////////////////////////
            // Calculate Trad Ira vs Roth Ira
            // ////////////////////////////////////////////////////////////////////////////////////////////////////////

            if (previousPeriod === null) {
                period.tradIra_rothIra.tradIra = period.conversion.conversionAmount;
                period.tradIra_rothIra.rothIra = period.conversion.rothIRAEOY;
            } else {
                period.tradIra_rothIra.tradIra = Math.max(
                    0,
                    previousPeriod.tradIra_rothIra.tradIra *
                    (1 + investmentRateOfReturn) -
                    (previousPeriod.noConversion.distributionRequested +
                        previousPeriod.noConversion.distributionRequested *
                        previousPeriod.noConversion
                            .marginalIncomeTaxRateWithMedicareTaxRate)
                );

                period.tradIra_rothIra.rothIra = Math.max(
                    0,
                    previousPeriod.tradIra_rothIra.rothIra *
                    (1 + investmentRateOfReturn) -
                    previousPeriod.noConversion.netWithdrawal
                );
            }

            period.additionalTbl1.col1 =
                period.noConversion.traditionalIRAValueEOY +
                period.noConversion.taxableAcctEOY +
                period.conversion.withdrawalAccountEOY;
            period.additionalTbl1.col2 =
                period.conversion.rothIRAEOY +
                period.conversion.traditionalIRAValueEOY +
                period.conversion.taxableAcctEOY +
                period.conversion.withdrawalAccountEOY;

            period.additionalTbl2.col1 =
                period.noConversion.traditionalIRAValueEOY +
                period.noConversion.taxableAcctEOY;
            period.additionalTbl2.col2 =
                period.conversion.rothIRAEOY +
                period.conversion.traditionalIRAValueEOY +
                period.conversion.taxableAcctEOY;
            sumOfConversionTaxPayment =
                sumOfConversionTaxPayment + period.conversion.taxPayment;
            periods.push(period);
            secondToLastPeriod = previousPeriod;
            previousPeriod = period;
        }

        // ////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Calculate Final Results
        // ////////////////////////////////////////////////////////////////////////////////////////////////////////

        const results = {
            noConversion: {
                traditionalIRAEndingBalance: 0,
                taxableAccountEndingBalance: 0,
                rothIRAEndingValue: 0,
                withdrawalAccountTaxable: 0,
                totalTaxPayments: 0,
                valueToHeir: 0,
                totalMedicareBPremium: 0,
            },
            rothConversion: {
                traditionalIRAEndingBalance: 0,
                taxableAccountEndingBalance: 0,
                rothIRAEndingValue: 0,
                withdrawalAccountTaxable: 0,
                totalTaxPayments: 0, // Taxes for conversion
                valueToHeir: 0,
                totalConversionAmount: 0,
                conversionTaxes: 0,
                rmdTaxes: 0,
                totalMedicareBPremium: 0,
            },
            netBenefitOfRothConversionDiff: {
                conversion: 0,
                noConversion: 0,
            },
            taxesPaidWithConversion: 0,
            taxesPaidWithoutConversion: 0,
            netBenefitOfRothConversion: 0,
            netValueToHeir: 0,
        };

        if (useTargetAge) {
            previousPeriod = periods.find((p) => p.age === targetAge);
        }

        results.noConversion.traditionalIRAEndingBalance =
            previousPeriod.noConversion.traditionalIRAValueEOY;

        results.noConversion.taxableAccountEndingBalance =
            previousPeriod.noConversion.taxableAcctEOY;

        results.noConversion.rothIRAEndingValue =
            beginningRothIRAAssetValue *
            (1 + investmentRateOfReturn) ** (breakEvenTargetAge - currentAge);

        // results.noConversion.withdrawalAccountTaxable = previousPeriod.noConversion.withdrawalAccountEOY
        //     - (previousPeriod.noConversion.withdrawalAccountEOY - previousPeriod.noConversion.withdrawalAccountBasis) * heirsMarginalLTCGTaxRateAtDeath;

        results.noConversion.totalTaxPayments = periods.reduce(
            (total, period) =>
                total +
                period.noConversion.RMDTax +
                period.noConversion.taxesPaidDueToTaxableAcctWithdrawal +
                period.noConversion.withdrawalFromTraditionalIRATax,
            0
        );

        // Heir's pre-calculations (no conversion)
        // const heirWithdrawalPerYear = results.noConversion.traditionalIRAEndingBalance / (heirsYearsToWithdraw || 1);
        // const heirTaxRateString = this.calculateTaxBracket(heirWithdrawalPerYear, is2026Sunset ? +ORDINARY_INCOME_YEAR_SWITCH_TARGET : currentYear, filingStatus);
        // const heirTaxRate = +heirTaxRateString.replace('%', '') / 100;
        // const heirWithdrawalPerYearAfterTax = heirWithdrawalPerYear * (1 - heirTaxRate);

        // results.noConversion.valueToHeir = heirsOrdinaryIncomeAtTransfer
        //     + (heirsYearsToWithdraw * heirWithdrawalPerYearAfterTax)
        //     + (results.noConversion.taxableAccountEndingBalance * (1 - heirTaxRate));

        results.rothConversion.rothIRAEndingValue =
            previousPeriod.conversion.rothIRAEOY;

        results.rothConversion.taxableAccountEndingBalance =
            previousPeriod.conversion.taxableAcctEOY;

        results.rothConversion.traditionalIRAEndingBalance =
            previousPeriod.conversion.traditionalIRAValueEOY;

        // results.rothConversion.withdrawalAccountTaxable = previousPeriod.conversion.withdrawalAccountEOY
        //     - (previousPeriod.conversion.withdrawalAccountEOY - previousPeriod.conversion.withdrawalAccountBasis) * heirsMarginalLTCGTaxRateAtDeath;

        //PAGE 2 RESULTS//

        //Taxes for conversion
        results.rothConversion.conversionTaxes = periods.reduce(
            (total, period) => total + period.conversion.taxPayment,
            0
        );

        //Taxes for distributions/RMDs
        results.rothConversion.rmdTaxes = periods.reduce(
            (total, period) =>
                total +
                period.conversion.RMDTax +
                period.conversion.taxesPaidDueToTaxableAcctWithdrawal +
                period.conversion.taxForDistributionFromTradIRA,
            0
        );

        //Total Taxes
        results.rothConversion.totalTaxPayments =
            results.rothConversion.conversionTaxes + results.rothConversion.rmdTaxes;
        results.taxesPaidWithConversion = results.rothConversion.totalTaxPayments;
        results.taxesPaidWithoutConversion = periods.reduce(
            (total, period) =>
                total +
                period.noConversion.taxesPaidDueToTaxableAcctWithdrawal +
                period.noConversion.RMDTax +
                period.noConversion.taxOnTradIRADistribution,
            0
        );
        //Total conversion amount
        results.rothConversion.totalConversionAmount = periods.reduce(
            (total, period) => total + period.conversion.conversionAmount,
            0
        );

        // Savings to heir)
        results.rothConversion.valueToHeir =
            previousPeriod.conversion.valueToHeir -
            previousPeriod.noConversion.valueToHeir;

        results.netBenefitOfRothConversion =
            previousPeriod.conversion.totalAfterTaxPortfolioBalanceWConversion -
            previousPeriod.noConversion.totalAfterTaxPortfolioBalanceWOConversion;

        results.netBenefitOfRothConversionDiff.conversion =
            previousPeriod.conversion.savingsImpact;
        results.netBenefitOfRothConversionDiff.noConversion =
            previousPeriod.noConversion.savingsImpact;

        results.noConversion.totalMedicareBPremium = periods.reduce(
            (total: number, p: any) => total + p.noConversion.medicarePartBPremium,
            0
        );
        results.rothConversion.totalMedicareBPremium = periods.reduce(
            (total: number, p: any) => total + p.conversion.medicarePartBPremium,
            0
        );

        const transformed2DollarResults: any =
            this.transformValues2DollarNotation(results);

        // ////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Build Graph and Table Data
        // ////////////////////////////////////////////////////////////////////////////////////////////////////////

        const conversion = [];
        const noConversion = [];
        const labels = [];

        periods.forEach((elem, index) => {
            labels.push(`${elem.age}`);
            conversion.push(
                Math.ceil(elem.conversion.totalAfterTaxPortfolioBalanceWConversion)
            );
            noConversion.push(
                Math.ceil(elem.noConversion.totalAfterTaxPortfolioBalanceWOConversion)
            );
        });

        transformed2DollarResults.additionalMedicareTax = periods.map((p) => ({
            year: p.year,
            value: p.hasAdditionalMedicareFlag,
        }));
        transformed2DollarResults.ssiTax = periods.map((p) => ({
            year: p.year,
            value: p.hasSSIFlag,
        }));

        // Final Table
        transformed2DollarResults.periods = periods.map((p) => ({
            year: p.year.toString(),
            age: p.age.toString(),
            taxBracket: p.conversion.marginalIncomeTaxRateString,
            savingsImpact: this.transformValues2DollarNotation(
                p.conversion.totalAfterTaxPortfolioBalanceWConversion -
                p.noConversion.totalAfterTaxPortfolioBalanceWOConversion
            ),
            noConversion: this.transformValues2DollarNotation(
                p.noConversion.totalAfterTaxPortfolioBalanceWOConversion
            ),
            withConversion: this.transformValues2DollarNotation(
                p.conversion.totalAfterTaxPortfolioBalanceWConversion
            ),
        }));

        transformed2DollarResults.graph = {
            noConversion,
            conversion,
            labels,
        };
        transformed2DollarResults.incomeAtKickOfAge = incomeAtKickOfAge;
        transformed2DollarResults._periods = periods.map((p) => ({
            ...p,
            taxableIncomeBeforeRMD: +p.taxableIncomeBeforeRMD.toFixed(0),
        }));
        console.log(transformed2DollarResults);
        return transformed2DollarResults;
    }

    private getPercentage(finalRate) {
        if (+finalRate.toString().slice(-1) === 8) {
            return +(finalRate * 100).toFixed(2);
        }
        return finalRate * 100;
    }

    private getIrmaaMonthlyPremium(
        filingStatus: number,
        year: number,
        income: number
    ) {
        const { IRMAABracketsTables, IRMAADBracketsTables } = this.TABLES;

        const irmaaTable = IRMAABracketsTables[year][filingStatus];
        const irmaaDTable = IRMAADBracketsTables[year][filingStatus];
        const irmaaMonthlyPremiums = Object.keys(irmaaTable);
        const irmaaMonthlyDPremiums = Object.keys(irmaaDTable);
        for (let i = 0; i < irmaaMonthlyPremiums.length; i++) {
            const monthlyPremium = irmaaMonthlyPremiums[i];
            const monthlyDPremium = irmaaMonthlyDPremiums[i];
            irmaaTable[monthlyPremium] =
                irmaaTable[monthlyPremium] === 0 ? 1 : irmaaTable[monthlyPremium];
            const topBracket = irmaaTable[monthlyPremium] || Infinity;

            if (income <= topBracket) {
                return +monthlyPremium + +monthlyDPremium;
            }
        }

        return 0;
    }

    /**
     * Transform a value into dollar notation separated by commas
     * @param data Value to transform
     */
    private transformValues2DollarNotation(data: any) {
        if (typeof data === "object") {
            const transformed = {};
            for (const key in data) {
                transformed[key] = this.transformValues2DollarNotation(data[key]);
            }
            return transformed;
        } else if (typeof data === "number") {
            return "$" + data.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        return "$" + (+data).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    /**
     * Calculate the max conversion amount for Roth conversion
     * @param taxableIncome
     * @param year
     * @param filingStatus
     */
    public calculateMaxConversionAmount(
        taxableIncome: number,
        beginningIRAAssetValue: number,
        year: string,
        filingStatus: string
    ) {
        const MAX_CONVERSION_AMOUNT = 250000;
        const { OrdinaryIncomeTaxBracketTables, StandardDeductionTables } =
            this.TABLES;
        const ordinaryIncomeTable =
            OrdinaryIncomeTaxBracketTables[year][filingStatus];
        const standardDeduction = StandardDeductionTables[year][filingStatus];
        const marginalIncomeTableRates = Object.keys(ordinaryIncomeTable);

        if (+taxableIncome < standardDeduction) {
            return standardDeduction - +taxableIncome;
        } else {
            for (let i = 0; i < marginalIncomeTableRates.length - 1; i++) {
                const rate = marginalIncomeTableRates[i];
                const topThreshold = ordinaryIncomeTable[rate] || Infinity;
                if (+taxableIncome <= topThreshold) {
                    return Math.min(
                        topThreshold - +taxableIncome,
                        beginningIRAAssetValue
                    );
                }
            }
        }

        return Math.min(beginningIRAAssetValue, MAX_CONVERSION_AMOUNT);
    }

    /**
     * Calculate the max conversion amount for a certain threshold Roth conversion
     * @param taxableIncome
     * @param year
     * @param filingStatus
     */
    public fillUpBracket(
        taxableIncome: number,
        year: string,
        filingStatus: string,
        targetThreshold: string
    ) {
        const { OrdinaryIncomeTaxBracketTables, StandardDeductionTables } =
            this.TABLES;
        const ordinaryIncomeTable =
            OrdinaryIncomeTaxBracketTables[year][filingStatus];
        const marginalIncomeTableRates = Object.keys(ordinaryIncomeTable);
        const secondToLastThreshold =
            ordinaryIncomeTable[
            marginalIncomeTableRates[marginalIncomeTableRates.length - 2]
            ];
        const MAX_CONVERSION_AMOUNT =
            secondToLastThreshold + 1 > taxableIncome
                ? secondToLastThreshold + 1
                : taxableIncome + 1;
        if (ordinaryIncomeTable[targetThreshold]) {
            if (
                marginalIncomeTableRates[marginalIncomeTableRates.length - 1] ===
                targetThreshold
            ) {
                return MAX_CONVERSION_AMOUNT;
            } else {
                return ordinaryIncomeTable[targetThreshold] - taxableIncome;
            }
        } else {
            if (
                marginalIncomeTableRates[marginalIncomeTableRates.length - 1] ===
                targetThreshold
            ) {
                return MAX_CONVERSION_AMOUNT;
            }
            return taxableIncome;
        }
    }

    /**
     * Gets all the thresholds above the client's current thresholds
     * @param taxableIncome
     * @param year
     * @param filingStatus
     */
    public getAvailableThresholdsToFillUp(
        taxableIncome: number,
        year: string,
        filingStatus: string
    ) {
        const { OrdinaryIncomeTaxBracketTables } = this.TABLES;
        const ordinaryIncomeTable =
            OrdinaryIncomeTaxBracketTables[year][filingStatus];
        const marginalIncomeTableRates = Object.keys(ordinaryIncomeTable);
        const availableThresholds = [];
        for (let i = 0; i < marginalIncomeTableRates.length; i++) {
            const rate = marginalIncomeTableRates[i];
            const topThreshold = ordinaryIncomeTable[rate] || Number.MAX_SAFE_INTEGER;
            if (+taxableIncome <= topThreshold) {
                availableThresholds.push(rate);
            }
        }
        return availableThresholds;
    }

    /**
     * Calculate the max conversion amount for a given taxable amount
     * @param taxableIncome
     * @param year
     * @param filingStatus
     */
    public maxConversionAmount(
        taxableIncome: number,
        beginningIRAAssetValue: number,
        year: string,
        filingStatus: string,
    ) {
        const { OrdinaryIncomeTaxBracketTables } = this.TABLES;
        const ordinaryIncomeTable =
            OrdinaryIncomeTaxBracketTables[year][filingStatus];
        const marginalIncomeTableRates = Object.keys(ordinaryIncomeTable);
        const secondToLastThreshold =
            ordinaryIncomeTable[
            marginalIncomeTableRates[marginalIncomeTableRates.length - 2]
            ];
        const projectedIRA = beginningIRAAssetValue * 3;
        // return secondToLastThreshold + 1 > taxableIncome
        //     ? secondToLastThreshold + 1
        //     : taxableIncome + 1;
        return projectedIRA;
    }

    /**
     * Calculate tax bracket of a given taxable income
     * @param income
     * @param year
     * @param filingStatus
     */
    public calculateTaxBracket(
        income: any,
        year: number,
        filingStatus: string
    ): string {
        const { OrdinaryIncomeTaxBracketTables, StandardDeductionTables } =
            this.TABLES;
        const ordinaryIncomeTable =
            OrdinaryIncomeTaxBracketTables[year][filingStatus];
        const standardDeduction = StandardDeductionTables[year][filingStatus];
        const marginalIncomeTableRates = Object.keys(ordinaryIncomeTable);

        if (+income >= standardDeduction) {
            for (let i = 0; i < marginalIncomeTableRates.length; i++) {
                const rate = marginalIncomeTableRates[i];
                const topThreshold = ordinaryIncomeTable[rate] || Infinity;
                if (+income <= topThreshold) {
                    return rate;
                }
            }
        }

        return "0%";
    }

    /**
     * Calculate the optimized conversion amount focused on reducing taxes
     * @param dataset
     * @param maxConversionAmount
     */
    public optimizeTaxes(
        dataset: any,
        maxConversionAmount: number,
        lockedVal:
            | "none"
            | "conversion_amount"
            | "period"
            | "conversion_years" = "none"
    ) {
        let minTaxPayment = Infinity,
            optConversionAmount = null,
            optConversionYears = null,
            kickoffage = null;

        // Execute calculator and compare both scenarios' savings impact
        const execAndCompare = (
            conversionAmount: number,
            numOfYearsToConvert: number,
            period: number
        ) => {
            const auxDataset = {
                ...dataset,
                annualConversionAmount: conversionAmount.toString(),
                numOfYearsToConvert,
                kickoffYear: period,
            };
            const calculation = this.exec(
                auxDataset,
                numOfYearsToConvert,
                "multi_year",
                true
            );
            const taxPayment = +calculation.rothConversion.totalTaxPayments
                .replace("$", "")
                .split(",")
                .join("");

            if (optConversionAmount === null || taxPayment < minTaxPayment) {
                minTaxPayment = taxPayment;
                optConversionAmount = conversionAmount;
                optConversionYears = numOfYearsToConvert;
                kickoffage = period;
            }

            return taxPayment;
        };

        const { beginningIRAAssetValue, lifeExpectancy, currentAge } = dataset;

        const limitedMaxConversionAmount =
            lockedVal === "conversion_amount"
                ? +dataset.annualConversionAmount
                : Math.min(+maxConversionAmount, 250000);

        const initialConversionAmount =
            lockedVal === "conversion_amount"
                ? +dataset.annualConversionAmount
                : 1000;
        const initialNumOfYears =
            lockedVal === "conversion_years" ? +dataset.numOfYearsToConvert : 1;
        const initialPeriod =
            lockedVal === "period" ? +dataset.kickoffYear : +currentAge;
        const maxNumOfPeriods = +lifeExpectancy;
        // Eval all the stages in the range [100, maxConversionAmount)
        for (
            let conversionAmount = initialConversionAmount;
            conversionAmount <= limitedMaxConversionAmount;
            conversionAmount += 1000
        ) {
            const maxNumOfYears =
                lockedVal === "conversion_years"
                    ? +dataset.numOfYearsToConvert
                    : Math.min(
                        +lifeExpectancy - +currentAge,
                        Math.floor(beginningIRAAssetValue / conversionAmount)
                    );

            for (
                let numOfYears = initialNumOfYears;
                numOfYears <= maxNumOfYears;
                numOfYears++
            ) {
                for (
                    let periodNum = initialPeriod;
                    periodNum <= maxNumOfPeriods;
                    periodNum++
                ) {
                    execAndCompare(conversionAmount, numOfYears, periodNum);
                }
            }
        }

        return {
            conversionAmount: optConversionAmount,
            numOfYears: optConversionYears,
            kickoffage: kickoffage,
            optimalValue: minTaxPayment,
        };
    }

    /**
     * Calculate the optimized conversion amount focused on the heir
     * @param dataset
     * @param maxConversionAmount
     */
    public optimizeHeirValue(
        dataset: any,
        maxConversionAmount: number,
        lockedVal:
            | "none"
            | "conversion_amount"
            | "period"
            | "conversion_years" = "none"
    ) {
        let maxHeirValue = Infinity,
            optConversionAmount = null,
            optConversionYears = null,
            kickoffage = null;

        // Execute calculator and compare both scenarios' savings impact
        const execAndCompare = (
            conversionAmount: number,
            numOfYearsToConvert: number,
            period: number
        ) => {
            const auxDataset = {
                ...dataset,
                annualConversionAmount: conversionAmount.toString(),
                numOfYearsToConvert,
                kickoffYear: period,
            };
            const calculation = this.exec(
                auxDataset,
                numOfYearsToConvert,
                "multi_year",
                true
            );
            const netValueToHeir = +calculation.rothConversion.valueToHeir
                .replace("$", "")
                .split(",")
                .join("");

            if (optConversionAmount === null || netValueToHeir > maxHeirValue) {
                maxHeirValue = netValueToHeir;
                optConversionAmount = conversionAmount;
                optConversionYears = numOfYearsToConvert;
                kickoffage = period;
            }

            return netValueToHeir;
        };

        const { beginningIRAAssetValue, lifeExpectancy, currentAge } = dataset;

        const limitedMaxConversionAmount =
            lockedVal === "conversion_amount"
                ? +dataset.annualConversionAmount
                : Math.min(+maxConversionAmount, 250000);

        const initialConversionAmount =
            lockedVal === "conversion_amount"
                ? +dataset.annualConversionAmount
                : 1000;
        const initialNumOfYears =
            lockedVal === "conversion_years" ? +dataset.numOfYearsToConvert : 1;
        const initialPeriod =
            lockedVal === "period" ? +dataset.kickoffYear : +currentAge;
        const maxNumOfPeriods = +lifeExpectancy;

        // Eval all the stages in the range [100, maxConversionAmount)
        for (
            let conversionAmount = initialConversionAmount;
            conversionAmount <= limitedMaxConversionAmount;
            conversionAmount += 1000
        ) {
            const maxNumOfYears =
                lockedVal === "conversion_years"
                    ? +dataset.numOfYearsToConvert
                    : Math.min(
                        +lifeExpectancy - +currentAge,
                        Math.floor(beginningIRAAssetValue / conversionAmount)
                    );

            for (
                let numOfYears = initialNumOfYears;
                numOfYears <= maxNumOfYears;
                numOfYears++
            ) {
                for (
                    let periodNum = initialPeriod;
                    periodNum <= maxNumOfPeriods;
                    periodNum++
                ) {
                    execAndCompare(conversionAmount, numOfYears, periodNum);
                }
            }
        }

        return {
            conversionAmount: optConversionAmount,
            kickoffage: kickoffage,
            numOfYears: optConversionYears,
            optimalValue: maxHeirValue,
        };
    }

    /**
     * Calculate the optimized conversion amount focused on maximizing net benefit
     * @param dataset
     * @param maxConversionAmount
     */
    public optimizeNetBenefit(
        dataset: any,
        maxConversionAmount: number,
        lockedVal:
            | "none"
            | "conversion_amount"
            | "period"
            | "conversion_years" = "none"
    ) {
        let maxDiff = null,
            optConversionAmount = null,
            optConversionYears = null,
            kickoffage = null;

        // Execute calculator and compare both scenarios' savings impact
        const execAndCompare = (
            conversionAmount: number,
            numOfYearsToConvert: number,
            period: number
        ) => {
            const auxDataset = {
                ...dataset,
                annualConversionAmount: conversionAmount.toString(),
                numOfYearsToConvert,
                kickoffYear: period,
            };
            // if (numOfYearsToConvert === 8 && conversionAmount === 15000 && )
            const calculation = this.exec(
                auxDataset,
                numOfYearsToConvert,
                "multi_year",
                true
            );
            const diff = +calculation.netBenefitOfRothConversion
                .replace("$", "")
                .split(",")
                .join("");

            if (maxDiff === null || diff > maxDiff) {
                maxDiff = diff;
                optConversionAmount = conversionAmount;
                optConversionYears = numOfYearsToConvert;
                kickoffage = period;
            }

            return diff;
        };

        const { beginningIRAAssetValue, lifeExpectancy, currentAge } = dataset;

        const limitedMaxConversionAmount =
            lockedVal === "conversion_amount"
                ? +dataset.annualConversionAmount
                : Math.min(+maxConversionAmount, 250000);

        const initialConversionAmount =
            lockedVal === "conversion_amount"
                ? +dataset.annualConversionAmount
                : 1000;
        const initialNumOfYears =
            lockedVal === "conversion_years" ? +dataset.numOfYearsToConvert : 1;
        const initialPeriod =
            lockedVal === "period" ? +dataset.kickoffYear : +currentAge;
        const maxNumOfPeriods = +lifeExpectancy;
        // Eval all the stages in the range [initialConversionAmount, maxConversionAmount)
        for (
            let conversionAmount = initialConversionAmount;
            conversionAmount <= limitedMaxConversionAmount;
            conversionAmount += 1000
        ) {
            const maxNumOfYears =
                lockedVal === "conversion_years"
                    ? +dataset.numOfYearsToConvert
                    : Math.min(
                        +lifeExpectancy - +currentAge,
                        Math.floor(beginningIRAAssetValue / conversionAmount)
                    );

            for (
                let numOfYears = initialNumOfYears;
                numOfYears <= maxNumOfYears;
                numOfYears++
            ) {
                for (
                    let periodNum = initialPeriod;
                    periodNum <= maxNumOfPeriods;
                    periodNum++
                ) {
                    execAndCompare(conversionAmount, numOfYears, periodNum);
                }
            }
        }

        // Eval the maxConversionAmount
        // execAndCompare(+maxConversionAmount);
        return {
            conversionAmount: optConversionAmount,
            numOfYears: optConversionYears,
            kickoffage: kickoffage,
            optimalValue: maxDiff,
        };
    }

    public getTaxableIncomePerYear(dataset: any) {
        const numericDataset = (_dataset: any) => {
            let auxDataset: any = {};
            for (const key in _dataset) {
                const value = _dataset[key];
                if (!isNaN(value)) {
                    auxDataset[key] = parseFloat(value);
                } else {
                    auxDataset[key] = value;
                }
            }
            return auxDataset;
        };

        const datasetInNumericFormat = numericDataset(dataset);

        const {
            currentAge,
            retirementAge: _retirementAge,
            lifeExpectancy,
            currentTaxableIncome,
            currentIncomeGrowthRate: _currentIncomeGrowthRate,
            retirementTaxableIncome,
            retirementIncomeGrowthRate: _retirementIncomeGrowthRate,
        }: any = datasetInNumericFormat;

        // If the retirement age is less than current age, conduct the calculation as if retirementAge == current age.
        const retirementAge = Math.max(_retirementAge, currentAge);

        // Convert inputs percentages to decimal values
        const currentIncomeGrowthRate = _currentIncomeGrowthRate / 100;
        const retirementIncomeGrowthRate = _retirementIncomeGrowthRate / 100;

        const currentYear = new Date().getFullYear();
        const ageLimit = lifeExpectancy;

        const periods = [];
        let previousPeriod = null;

        for (
            let year = currentYear, age = currentAge, periodNum = 1;
            age <= ageLimit;
            periodNum++, age++, year++
        ) {
            const period = {
                age,
                year,
                taxableIncome: 0,
            };

            if (previousPeriod === null) {
                // First period
                if (period.age < retirementAge) {
                    period.taxableIncome =
                        currentTaxableIncome * (1 + currentIncomeGrowthRate);
                } else {
                    period.taxableIncome =
                        retirementTaxableIncome *
                        (1 + retirementIncomeGrowthRate) ** (period.age - retirementAge);
                }
            } else {
                // Period > 1
                if (period.age < retirementAge) {
                    period.taxableIncome =
                        previousPeriod.taxableIncomeBeforeRMD_calculated *
                        (1 + currentIncomeGrowthRate);
                } else {
                    period.taxableIncome =
                        retirementTaxableIncome *
                        (1 + retirementIncomeGrowthRate) ** (period.age - retirementAge);
                }
            }

            periods.push(period);
        }
        console.log(periods);
        return periods;
    }
}

///

@Injectable({
    providedIn: "root",
})
export class RothCalculatorV1 {
    TABLES: {
        OrdinaryIncomeTaxBracketTables: any;
        CapitalGainsRatesTables: any;
        IRSUniformLifetimeTable: any;
        StandardDeductionTables: any;
    };

    /**export class RothCalculator {
      private static TABLES: {
          OrdinaryIncomeTaxBracketTables: any
          CapitalGainsRatesTables: any,
          IRSUniformLifetimeTable: any,
          StandardDeductionTables: any
      };

      /**
       * Set the tax tables from remote data
       * @param tables
       */
    setTaxTables(tables: any) {
        this.TABLES = tables;
    }

    /**
     * Execute Roth Calculator v2.3
     * @param numberOfYearsToConvert
     * @param graphType
     */
    exec(
        dataset: any,
        numberOfYearsToConvert: number,
        graphType: "one_year" | "multi_year"
    ) {
        const periods = [];
        const {
            OrdinaryIncomeTaxBracketTables,
            CapitalGainsRatesTables,
            IRSUniformLifetimeTable,
            StandardDeductionTables,
        } = this.TABLES;

        const numericDataset = (_dataset: any) => {
            let auxDataset: any = {};
            for (const key in _dataset) {
                const value = _dataset[key];
                if (!isNaN(value)) {
                    auxDataset[key] = parseFloat(value);
                } else {
                    auxDataset[key] = value;
                }
            }
            return auxDataset;
        };

        const datasetInNumericFormat = numericDataset(dataset);
        let incomeAtKickOfAge = 0
        const {
            annualConversionAmount,
            currentAge,
            retirementAge: _retirementAge,
            lifeExpectancy,
            beginningIRAAssetValue,
            investmentRateOfReturn: _investmentRateOfReturn,
            currentTaxableIncome,
            currentIncomeGrowthRate: _currentIncomeGrowthRate,
            retirementTaxableIncome,
            retirementIncomeGrowthRate: _retirementIncomeGrowthRate,
            heirsMarginalLTCGTaxRateAtDeath: _heirsMarginalLTCGTaxRateAtDeath,
            heirsMarginalOrdinaryIncomeTaxRateAtDeath:
            _heirsMarginalOrdinaryIncomeTaxRateAtDeath,
            rothCalcFilingStatus: filingStatus,
            withdrawalAmountGross,
        }: any = datasetInNumericFormat;
        // If the retirement age is less than current age, conduct the calculation as if retirementAge == current age.
        const retirementAge = Math.max(_retirementAge, currentAge);
        const withdrawalStartYear =
            retirementAge - currentAge + new Date().getFullYear();
        const beginningRothIRAAssetValue = 0;

        // Convert inputs percentages to decimal values
        const investmentRateOfReturn = _investmentRateOfReturn / 100;
        const currentIncomeGrowthRate = _currentIncomeGrowthRate / 100;
        const retirementIncomeGrowthRate = _retirementIncomeGrowthRate / 100;
        const heirsMarginalLTCGTaxRateAtDeath =
            _heirsMarginalLTCGTaxRateAtDeath / 100;
        const heirsMarginalOrdinaryIncomeTaxRateAtDeath =
            _heirsMarginalOrdinaryIncomeTaxRateAtDeath / 100;

        // ////////////////////////////////////////////////////
        // Calculate General Values
        // ////////////////////////////////////////////////////

        const currentYear = new Date().getFullYear();
        const numOfYearsToConvert = numberOfYearsToConvert;
        const ORDINARY_INCOME_YEAR_SWITCH = 2026;
        const ORDINARY_INCOME_YEAR_SWITCH_TARGET = "2017";

        let previousPeriod = null;

        for (
            let year = currentYear, age = currentAge, periodNum = 1;
            age <= lifeExpectancy;
            periodNum++, age++, year++
        ) {
            const period = {
                num: periodNum,
                year,
                age,
                taxableIncomeBeforeRMD: 0,
                noConversion: {
                    taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal: 0,
                    marginalIncomeTaxRate: 0,
                    marginalIncomeTaxRateString: "0%",
                    marginalLTCGTaxRate: 0,
                    RMDFactor: 0,
                    traditionalIRABOY: 0,
                    traditionalIRABOYAfterWithdrawal: 0,
                    traditionalIRAValueEOY: 0,
                    RMDAfterWithdrawal: 0,
                    RMDTax: 0,
                    taxPercentage: 0,
                    annualConversionAfterTaxes: 0,
                    netOfTaxRMD: 0,
                    taxableAcctBOY: 0,
                    taxableAcctBOYAfterWithdrawal: 0,
                    taxableAcctGrowth: 0,
                    basis: 0,
                    taxableAcctEOY: 0,
                    rothIRABOY: 0,
                    rothIRABOYAfterWithdrawal: 0,
                    totalWithdrawal: 0,
                    withdrawalFromTaxableAcct: 0,
                    basisWithdrewFromTaxableAccount: 0,
                    taxesPaidDueToTaxableAcctWithdrawal: 0,
                    withdrawalFromTraditionalIRA: 0,
                    withdrawalFromTraditionalIRATax: 0,
                    withdrawalFromRothIRA: 0,
                    netWithdrawal: 0,
                    withdrawalAccountBOY: 0,
                    withdrawalAccountBasis: 0,
                    withdrawalAccountEOY: 0,
                    savingsImpact: 0,
                },
                conversion: {
                    rothIRABOY: 0,
                    conversionAmount: 0,
                    taxPayment: 0,
                    rothIRAEOY: 0,
                    taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal: 0,
                    marginalIncomeTaxRate: 0,
                    marginalIncomeTaxRateString: "0%",
                    marginalLTCGTaxRate: 0,
                    RMDFactor: 0,
                    traditionalIRABOY: 0,
                    traditionalIRABOYAfterWithdrawal: 0,
                    traditionalIRAValueEOY: 0,
                    RMDAfterWithdrawal: 0,
                    RMDTax: 0,
                    netOfTaxRMD: 0,
                    taxableAcctBOY: 0,
                    taxableAcctBOYAfterWithdrawal: 0,
                    taxableAcctGrowth: 0,
                    basis: 0,
                    taxableAcctEOY: 0,
                    totalWithdrawal: 0,
                    withdrawalFromTaxableAcct: 0,
                    basisWithdrewFromTaxableAccount: 0,
                    taxesPaidDueToTaxableAcctWithdrawal: 0,
                    withdrawalFromTraditionalIRA: 0,
                    withdrawalFromTraditionalIRATax: 0,
                    withdrawalFromRothIRA: 0,
                    netWithdrawal: 0,
                    withdrawalAccountBOY: 0,
                    withdrawalAccountBasis: 0,
                    withdrawalAccountEOY: 0,
                    savingsImpact: 0,
                },
            };

            // ////////////////////////////////////////////////////////////////////////////////////////////////////////
            // Calculate Non-specific Scenario
            // ////////////////////////////////////////////////////////////////////////////////////////////////////////

            // Taxable Income Before RMD
            if (previousPeriod === null) {
                // First period
                if (period.age < retirementAge) {
                    period.taxableIncomeBeforeRMD =
                        currentTaxableIncome * (1 + currentIncomeGrowthRate);
                } else {
                    period.taxableIncomeBeforeRMD =
                        retirementTaxableIncome *
                        (1 + retirementIncomeGrowthRate) ** (period.age - retirementAge);
                }
            } else {
                // Period > 1
                if (period.age < retirementAge) {
                    period.taxableIncomeBeforeRMD =
                        previousPeriod.taxableIncomeBeforeRMD *
                        (1 + currentIncomeGrowthRate);
                } else {
                    period.taxableIncomeBeforeRMD =
                        retirementTaxableIncome *
                        (1 + retirementIncomeGrowthRate) ** (period.age - retirementAge);
                }
            }

            // ////////////////////////////////////////////////////////////////////////////////////////////////////////
            // Current Period's Tax Tables
            // ////////////////////////////////////////////////////////////////////////////////////////////////////////

            const marginalIncomeTaxRateYear =
                period.year < ORDINARY_INCOME_YEAR_SWITCH
                    ? currentYear
                    : ORDINARY_INCOME_YEAR_SWITCH_TARGET;
            const ordinaryIncomeTable =
                OrdinaryIncomeTaxBracketTables[marginalIncomeTaxRateYear][filingStatus];
            const marginalIncomeTableRates = Object.keys(ordinaryIncomeTable);
            const capitalGainsTable =
                CapitalGainsRatesTables[marginalIncomeTaxRateYear][filingStatus];
            const capitalGainsTableRates = Object.keys(capitalGainsTable);
            const standardDeduction =
                StandardDeductionTables[marginalIncomeTaxRateYear][filingStatus];

            // ////////////////////////////////////////////////////////////////////////////////////////////////////////
            // Calculate No Conversion Scenario
            // ////////////////////////////////////////////////////////////////////////////////////////////////////////

            // RMD Factor
            if (period.age <= 71) {
                period.noConversion.RMDFactor = 0;
            } else {
                period.noConversion.RMDFactor =
                    IRSUniformLifetimeTable[period.age] || 0;
            }

            // Traditional IRA BOY
            if (previousPeriod === null) {
                period.noConversion.traditionalIRABOY = beginningIRAAssetValue;
            } else {
                period.noConversion.traditionalIRABOY =
                    previousPeriod.noConversion.traditionalIRAValueEOY;
            }

            // Taxable Acct BOY
            if (previousPeriod === null) {
                period.noConversion.taxableAcctBOY = 0;
            } else {
                period.noConversion.taxableAcctBOY =
                    previousPeriod.noConversion.taxableAcctEOY;
            }

            // Total Withdrawal
            if (withdrawalStartYear > period.year) {
                period.noConversion.totalWithdrawal = 0;
            } else {
                period.noConversion.totalWithdrawal = withdrawalAmountGross;
            }

            // Withdrawal from Taxable Acct
            if (period.noConversion.taxableAcctBOY === 0) {
                period.noConversion.withdrawalFromTaxableAcct = 0;
            } else {
                period.noConversion.withdrawalFromTaxableAcct = Math.min(
                    period.noConversion.taxableAcctBOY,
                    period.noConversion.totalWithdrawal
                );
            }

            // Basis withdrew from Taxable Account
            if (previousPeriod === null) {
                period.noConversion.basisWithdrewFromTaxableAccount = 0;
            } else {
                if (period.noConversion.taxableAcctBOY === 0) {
                    period.noConversion.basisWithdrewFromTaxableAccount = 0;
                } else {
                    period.noConversion.basisWithdrewFromTaxableAccount =
                        period.noConversion.withdrawalFromTaxableAcct *
                        (previousPeriod.noConversion.basis /
                            period.noConversion.taxableAcctBOY);
                }
            }

            // Withdrawal from Traditional IRA
            if (period.noConversion.traditionalIRABOY === 0) {
                period.noConversion.withdrawalFromTraditionalIRA = 0;
            } else {
                period.noConversion.withdrawalFromTraditionalIRA = Math.min(
                    period.noConversion.traditionalIRABOY,
                    period.noConversion.totalWithdrawal -
                    period.noConversion.withdrawalFromTaxableAcct
                );
            }

            // Traditional IRA BOY after withdrawal
            period.noConversion.traditionalIRABOYAfterWithdrawal =
                period.noConversion.traditionalIRABOY -
                period.noConversion.withdrawalFromTraditionalIRA;

            // RMD After Withdrawal
            period.noConversion.RMDAfterWithdrawal = Math.max(
                0,
                (period.noConversion.RMDFactor === 0
                    ? 0
                    : Math.min(
                        period.noConversion.traditionalIRABOYAfterWithdrawal,
                        period.noConversion.traditionalIRABOY /
                        period.noConversion.RMDFactor
                    )) - period.noConversion.withdrawalFromTraditionalIRA
            );

            // Traditional IRA Value EOY
            period.noConversion.traditionalIRAValueEOY =
                period.noConversion.traditionalIRABOYAfterWithdrawal *
                (1 + investmentRateOfReturn) -
                period.noConversion.RMDAfterWithdrawal;

            // Taxable Acct BOY after withdrawal
            period.noConversion.taxableAcctBOYAfterWithdrawal =
                period.noConversion.taxableAcctBOY -
                period.noConversion.withdrawalFromTaxableAcct;

            // Taxable Acct Growth
            period.noConversion.taxableAcctGrowth =
                period.noConversion.taxableAcctBOYAfterWithdrawal *
                investmentRateOfReturn;

            // Roth IRA BOY
            if (previousPeriod === null) {
                period.noConversion.rothIRABOY = beginningRothIRAAssetValue;
            } else {
                period.noConversion.rothIRABOY =
                    previousPeriod.noConversion.rothIRABOYAfterWithdrawal *
                    (1 + investmentRateOfReturn);
            }

            // Withdrawal from Roth IRA
            if (period.noConversion.rothIRABOY === 0) {
                period.noConversion.withdrawalFromRothIRA = 0;
            } else {
                period.noConversion.withdrawalFromRothIRA = Math.min(
                    period.noConversion.rothIRABOY,
                    period.noConversion.totalWithdrawal -
                    period.noConversion.withdrawalFromTaxableAcct -
                    period.noConversion.withdrawalFromTraditionalIRA
                );
            }

            // Roth IRA BOY after withdrawal
            period.noConversion.rothIRABOYAfterWithdrawal =
                period.noConversion.rothIRABOY -
                period.noConversion.withdrawalFromRothIRA;

            // Taxable Income w/RMD & Withdrawal from IRA & Taxable portion from Taxable account withdrawal
            period.noConversion.taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal =
                period.taxableIncomeBeforeRMD +
                Math.max(
                    period.noConversion.RMDAfterWithdrawal,
                    period.noConversion.withdrawalFromTraditionalIRA
                ) +
                period.noConversion.withdrawalFromTraditionalIRA +
                (period.noConversion.withdrawalFromTaxableAcct -
                    period.noConversion.basisWithdrewFromTaxableAccount);

            // Marginal Income Tax Rate
            if (
                period.noConversion
                    .taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal <
                standardDeduction
            ) {
                period.noConversion.marginalIncomeTaxRate = 0;
                period.noConversion.marginalIncomeTaxRateString = "0%";
            } else {
                for (let i = 0; i < marginalIncomeTableRates.length; i++) {
                    const rate = marginalIncomeTableRates[i];
                    const topThreshold = ordinaryIncomeTable[rate] || Infinity;
                    if (
                        period.noConversion
                            .taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal <=
                        topThreshold
                    ) {
                        period.noConversion.marginalIncomeTaxRate =
                            +rate.replace("%", "") / 100;
                        period.noConversion.marginalIncomeTaxRateString = rate;
                        break;
                    }
                }
            }

            // Marginal LTCG Tax Rate
            for (let i = 0; i < capitalGainsTableRates.length; i++) {
                const rate = capitalGainsTableRates[i];
                const topThreshold = capitalGainsTable[rate] || Infinity;
                if (
                    period.noConversion
                        .taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal <
                    topThreshold
                ) {
                    period.noConversion.marginalLTCGTaxRate =
                        +rate.replace("%", "") / 100;
                    break;
                }
            }

            // Withdrawal from Traditional IRA Tax
            period.noConversion.withdrawalFromTraditionalIRATax =
                period.noConversion.withdrawalFromTraditionalIRA *
                period.noConversion.marginalIncomeTaxRate;

            // RMD Tax
            period.noConversion.RMDTax =
                period.noConversion.RMDAfterWithdrawal *
                period.noConversion.marginalIncomeTaxRate;

            // Tax %
            period.noConversion.taxPercentage = Math.max(
                period.noConversion.RMDAfterWithdrawal > 0
                    ? period.noConversion.RMDTax / period.noConversion.RMDAfterWithdrawal
                    : 0,
                period.noConversion.withdrawalFromTraditionalIRA > 0
                    ? period.noConversion.withdrawalFromTraditionalIRATax /
                    period.noConversion.withdrawalFromTraditionalIRA
                    : 0
            );

            // Annual Conversion After Taxes
            if (previousPeriod === null) {
                period.noConversion.annualConversionAfterTaxes =
                    annualConversionAmount * (1 - period.noConversion.taxPercentage);
            } else {
                period.noConversion.annualConversionAfterTaxes =
                    previousPeriod.noConversion.annualConversionAfterTaxes *
                    (1 + investmentRateOfReturn) *
                    (1 - period.noConversion.taxPercentage);
            }

            // Net of Tax RMD
            period.noConversion.netOfTaxRMD =
                period.noConversion.RMDAfterWithdrawal *
                (1 - period.noConversion.marginalIncomeTaxRate);

            // Basis
            if (previousPeriod === null) {
                period.noConversion.basis =
                    period.noConversion.netOfTaxRMD -
                    period.noConversion.basisWithdrewFromTaxableAccount;
            } else {
                period.noConversion.basis =
                    previousPeriod.noConversion.basis +
                    period.noConversion.netOfTaxRMD -
                    period.noConversion.basisWithdrewFromTaxableAccount;
            }

            // Taxes Paid due to Taxable Acct Withdrawal
            if (previousPeriod === null) {
                period.noConversion.taxesPaidDueToTaxableAcctWithdrawal = 0;
            } else {
                if (period.noConversion.taxableAcctBOY === 0) {
                    period.noConversion.taxesPaidDueToTaxableAcctWithdrawal = 0;
                } else {
                    period.noConversion.taxesPaidDueToTaxableAcctWithdrawal =
                        period.noConversion.withdrawalFromTaxableAcct *
                        (1 -
                            previousPeriod.noConversion.basis /
                            period.noConversion.taxableAcctBOY) *
                        period.noConversion.marginalLTCGTaxRate;
                }
            }

            // Taxable Acct EOY
            period.noConversion.taxableAcctEOY =
                period.noConversion.netOfTaxRMD +
                period.noConversion.taxableAcctBOYAfterWithdrawal +
                period.noConversion.taxableAcctGrowth -
                period.noConversion.taxesPaidDueToTaxableAcctWithdrawal;

            // Net Withdrawal
            if (period.noConversion.totalWithdrawal === 0) {
                period.noConversion.netWithdrawal = 0;
            } else {
                period.noConversion.netWithdrawal =
                    period.noConversion.withdrawalFromTaxableAcct -
                    period.noConversion.taxesPaidDueToTaxableAcctWithdrawal +
                    period.noConversion.withdrawalFromTraditionalIRA -
                    period.noConversion.withdrawalFromTraditionalIRATax +
                    period.noConversion.withdrawalFromRothIRA;
            }

            // Withdrawal Account BOY
            if (previousPeriod === null) {
                period.noConversion.withdrawalAccountBOY = 0;
            } else {
                period.noConversion.withdrawalAccountBOY =
                    previousPeriod.noConversion.withdrawalAccountEOY;
            }

            // Withdrawal Account Basis
            if (previousPeriod === null) {
                period.noConversion.withdrawalAccountBasis =
                    period.noConversion.netWithdrawal;
            } else {
                period.noConversion.withdrawalAccountBasis =
                    previousPeriod.noConversion.withdrawalAccountBasis +
                    period.noConversion.netWithdrawal;
            }

            // Withdrawal Account EOY
            period.noConversion.withdrawalAccountEOY =
                (period.noConversion.netWithdrawal +
                    period.noConversion.withdrawalAccountBOY) *
                (1 + investmentRateOfReturn);

            // Savings Impact
            period.noConversion.savingsImpact =
                period.noConversion.traditionalIRAValueEOY +
                period.noConversion.taxableAcctEOY;

            // ////////////////////////////////////////////////////////////////////////////////////////////////////////
            // Calculate Conversion Scenario
            // ////////////////////////////////////////////////////////////////////////////////////////////////////////

            // Roth IRA BOY
            if (previousPeriod === null) {
                period.conversion.rothIRABOY = beginningRothIRAAssetValue;
            } else {
                period.conversion.rothIRABOY = previousPeriod.conversion.rothIRAEOY;
            }

            // Conversion Amount
            if (period.num <= numOfYearsToConvert) {
                period.conversion.conversionAmount = annualConversionAmount;
            }

            // RMD Factor
            if (period.age <= 71) {
                period.conversion.RMDFactor = 0;
            } else {
                period.conversion.RMDFactor = IRSUniformLifetimeTable[period.age] || 0;
            }

            // Traditional IRA BOY
            if (previousPeriod === null) {
                period.conversion.traditionalIRABOY =
                    beginningIRAAssetValue - period.conversion.conversionAmount;
            } else {
                period.conversion.traditionalIRABOY =
                    previousPeriod.conversion.traditionalIRAValueEOY -
                    period.conversion.conversionAmount;
            }

            // Taxable Acct BOY
            if (previousPeriod === null) {
                period.conversion.taxableAcctBOY = 0;
            } else {
                period.conversion.taxableAcctBOY =
                    previousPeriod.conversion.taxableAcctEOY;
            }

            // Total Withdrawal
            if (withdrawalStartYear > period.year) {
                period.conversion.totalWithdrawal = 0;
            } else {
                period.conversion.totalWithdrawal = withdrawalAmountGross;
            }

            // Withdrawal from Taxable Acct
            if (period.conversion.taxableAcctBOY === 0) {
                period.conversion.withdrawalFromTaxableAcct = 0;
            } else {
                period.conversion.withdrawalFromTaxableAcct = Math.min(
                    period.conversion.taxableAcctBOY,
                    period.conversion.totalWithdrawal
                );
            }

            // Basis withdrew from Taxable Account
            if (previousPeriod === null) {
                period.conversion.basisWithdrewFromTaxableAccount = 0;
            } else {
                if (period.conversion.taxableAcctBOY === 0) {
                    period.conversion.basisWithdrewFromTaxableAccount = 0;
                } else {
                    period.conversion.basisWithdrewFromTaxableAccount =
                        period.conversion.withdrawalFromTaxableAcct *
                        (previousPeriod.conversion.basis /
                            period.conversion.taxableAcctBOY);
                }
            }

            // Withdrawal from Traditional IRA
            if (period.conversion.traditionalIRABOY === 0) {
                period.conversion.withdrawalFromTraditionalIRA = 0;
            } else {
                period.conversion.withdrawalFromTraditionalIRA = Math.min(
                    period.conversion.traditionalIRABOY,
                    period.conversion.totalWithdrawal -
                    period.conversion.withdrawalFromTaxableAcct
                );
            }

            // Traditional IRA BOY after withdrawal
            period.conversion.traditionalIRABOYAfterWithdrawal =
                period.conversion.traditionalIRABOY -
                period.conversion.withdrawalFromTraditionalIRA;

            // RMD After Withdrawal
            period.conversion.RMDAfterWithdrawal = Math.max(
                0,
                (period.conversion.RMDFactor === 0
                    ? 0
                    : Math.min(
                        period.conversion.traditionalIRABOYAfterWithdrawal,
                        period.conversion.traditionalIRABOY / period.conversion.RMDFactor
                    )) - period.conversion.withdrawalFromTraditionalIRA
            );

            // Traditional IRA Value EOY
            if (period.conversion.traditionalIRABOYAfterWithdrawal === 0) {
                period.conversion.traditionalIRAValueEOY = 0;
            } else {
                period.conversion.traditionalIRAValueEOY =
                    period.conversion.traditionalIRABOYAfterWithdrawal *
                    (1 + investmentRateOfReturn) -
                    period.conversion.RMDAfterWithdrawal;
            }

            // Taxable Acct BOY after withdrawal
            period.conversion.taxableAcctBOYAfterWithdrawal =
                period.conversion.taxableAcctBOY -
                period.conversion.withdrawalFromTaxableAcct;

            // Taxable Acct Growth
            period.conversion.taxableAcctGrowth =
                period.conversion.taxableAcctBOYAfterWithdrawal *
                investmentRateOfReturn;

            // Withdrawal from Roth IRA
            if (period.conversion.rothIRABOY === 0) {
                period.conversion.withdrawalFromRothIRA = 0;
            } else {
                period.conversion.withdrawalFromRothIRA = Math.min(
                    period.conversion.rothIRABOY,
                    period.conversion.totalWithdrawal -
                    period.conversion.withdrawalFromTaxableAcct -
                    period.conversion.withdrawalFromTraditionalIRA
                );
            }

            // Taxable Income w/RMD & Withdrawal from IRA & Taxable portion from Taxable account withdrawal
            period.conversion.taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal =
                period.taxableIncomeBeforeRMD +
                period.conversion.conversionAmount +
                Math.max(
                    period.conversion.RMDAfterWithdrawal,
                    period.conversion.withdrawalFromTraditionalIRA
                ) +
                period.conversion.withdrawalFromTraditionalIRA +
                (period.conversion.withdrawalFromTaxableAcct -
                    period.conversion.basisWithdrewFromTaxableAccount);

            // Marginal Income Tax Rate
            if (
                period.conversion
                    .taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal <
                standardDeduction
            ) {
                period.conversion.marginalIncomeTaxRate = 0;
                period.conversion.marginalIncomeTaxRateString = "0%";
            } else {
                for (let i = 0; i < marginalIncomeTableRates.length; i++) {
                    const rate = marginalIncomeTableRates[i];
                    const topThreshold = ordinaryIncomeTable[rate] || Infinity;
                    if (
                        period.conversion
                            .taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal <=
                        topThreshold
                    ) {
                        period.conversion.marginalIncomeTaxRate =
                            +rate.replace("%", "") / 100;
                        period.conversion.marginalIncomeTaxRateString = rate;
                        break;
                    }
                }
            }

            // Marginal LTCG Tax Rate
            for (let i = 0; i < capitalGainsTableRates.length; i++) {
                const rate = capitalGainsTableRates[i];
                const topThreshold = capitalGainsTable[rate] || Infinity;
                if (
                    period.conversion
                        .taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal <
                    topThreshold
                ) {
                    period.conversion.marginalLTCGTaxRate = +rate.replace("%", "") / 100;
                    break;
                }
            }

            // Withdrawal from Traditional IRA Tax
            period.conversion.withdrawalFromTraditionalIRATax =
                period.conversion.withdrawalFromTraditionalIRA *
                period.conversion.marginalIncomeTaxRate;

            // RMD Tax
            period.conversion.RMDTax =
                period.conversion.RMDAfterWithdrawal *
                period.conversion.marginalIncomeTaxRate;

            // Net of Tax RMD
            period.conversion.netOfTaxRMD =
                period.conversion.RMDAfterWithdrawal *
                (1 - period.conversion.marginalIncomeTaxRate);

            // Basis
            if (previousPeriod === null) {
                period.conversion.basis = 0;
            } else {
                period.conversion.basis =
                    previousPeriod.conversion.basis +
                    period.conversion.netOfTaxRMD -
                    period.conversion.basisWithdrewFromTaxableAccount;
            }

            // Taxes Paid due to Taxable Acct Withdrawal
            if (previousPeriod === null) {
                period.conversion.taxesPaidDueToTaxableAcctWithdrawal = 0;
            } else {
                if (period.conversion.taxableAcctBOY === 0) {
                    period.conversion.taxesPaidDueToTaxableAcctWithdrawal = 0;
                } else {
                    period.conversion.taxesPaidDueToTaxableAcctWithdrawal =
                        period.conversion.withdrawalFromTaxableAcct *
                        (1 -
                            previousPeriod.conversion.basis /
                            period.conversion.taxableAcctBOY) *
                        period.conversion.marginalLTCGTaxRate;
                }
            }

            // Taxable Acct EOY
            period.conversion.taxableAcctEOY =
                period.conversion.netOfTaxRMD +
                period.conversion.taxableAcctBOYAfterWithdrawal +
                period.conversion.taxableAcctGrowth -
                period.conversion.taxesPaidDueToTaxableAcctWithdrawal;

            // Net Withdrawal
            if (period.conversion.totalWithdrawal === 0) {
                period.conversion.netWithdrawal = 0;
            } else {
                period.conversion.netWithdrawal =
                    period.conversion.withdrawalFromTaxableAcct -
                    period.conversion.taxesPaidDueToTaxableAcctWithdrawal +
                    period.conversion.withdrawalFromTraditionalIRA -
                    period.conversion.withdrawalFromTraditionalIRATax +
                    period.conversion.withdrawalFromRothIRA;
            }

            // Withdrawal Account BOY
            if (previousPeriod === null) {
                period.conversion.withdrawalAccountBOY = 0;
            } else {
                period.conversion.withdrawalAccountBOY =
                    previousPeriod.conversion.withdrawalAccountEOY;
            }

            // Withdrawal Account Basis
            if (previousPeriod === null) {
                period.conversion.withdrawalAccountBasis =
                    period.conversion.netWithdrawal;
            } else {
                period.conversion.withdrawalAccountBasis =
                    previousPeriod.conversion.withdrawalAccountBasis +
                    period.conversion.netWithdrawal;
            }

            // Withdrawal Account EOY
            period.conversion.withdrawalAccountEOY =
                (period.conversion.netWithdrawal +
                    period.conversion.withdrawalAccountBOY) *
                (1 + investmentRateOfReturn);

            // Tax Payment
            period.conversion.taxPayment =
                period.conversion.conversionAmount *
                period.conversion.marginalIncomeTaxRate;

            // Roth IRA EOY
            // period.conversion.rothIRAEOY = (period.conversion.rothIRABOY + period.conversion.conversionAmount - period.conversion.taxPayment)
            //     * (1 + investmentRateOfReturn);
            period.conversion.rothIRAEOY =
                (period.conversion.rothIRABOY +
                    period.conversion.conversionAmount -
                    period.conversion.taxPayment -
                    period.conversion.withdrawalFromRothIRA) *
                (1 + investmentRateOfReturn);

            // Savings Impact
            period.conversion.savingsImpact =
                period.conversion.rothIRAEOY +
                period.conversion.taxableAcctEOY +
                period.conversion.traditionalIRAValueEOY;

            periods.push(period);
            previousPeriod = period;
        }

        // ////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Calculate Final Results
        // ////////////////////////////////////////////////////////////////////////////////////////////////////////

        const results = {
            noConversion: {
                traditionalIRAEndingBalance: 0,
                taxableAccountEndingBalance: 0,
                rothIRAEndingValue: 0,
                withdrawalAccountTaxable: 0,
                totalTaxPayments: 0,
                valueToHeir: 0,
            },
            rothConversion: {
                traditionalIRAEndingBalance: 0,
                taxableAccountEndingBalance: 0,
                rothIRAEndingValue: 0,
                withdrawalAccountTaxable: 0,
                totalTaxPayments: 0,
                valueToHeir: 0,
            },
            netBenefitOfRothConversionDiff: {
                conversion: 0,
                noConversion: 0,
            },
            netBenefitOfRothConversion: 0,
            netValueToHeir: 0,

        };

        results.noConversion.traditionalIRAEndingBalance =
            previousPeriod.noConversion.traditionalIRAValueEOY;

        results.noConversion.taxableAccountEndingBalance =
            previousPeriod.noConversion.taxableAcctEOY;

        results.noConversion.rothIRAEndingValue =
            beginningRothIRAAssetValue *
            (1 + investmentRateOfReturn) ** (lifeExpectancy - currentAge);

        results.noConversion.withdrawalAccountTaxable =
            previousPeriod.noConversion.withdrawalAccountEOY -
            (previousPeriod.noConversion.withdrawalAccountEOY -
                previousPeriod.noConversion.withdrawalAccountBasis) *
            heirsMarginalLTCGTaxRateAtDeath;

        results.noConversion.totalTaxPayments = periods.reduce(
            (total, period) =>
                total +
                period.noConversion.RMDTax +
                period.noConversion.taxesPaidDueToTaxableAcctWithdrawal +
                period.noConversion.withdrawalFromTraditionalIRATax,
            0
        );

        results.noConversion.valueToHeir =
            results.noConversion.traditionalIRAEndingBalance *
            (1 - heirsMarginalOrdinaryIncomeTaxRateAtDeath) +
            results.noConversion.taxableAccountEndingBalance *
            (1 - heirsMarginalOrdinaryIncomeTaxRateAtDeath);

        results.rothConversion.rothIRAEndingValue =
            previousPeriod.conversion.rothIRAEOY;

        results.rothConversion.taxableAccountEndingBalance =
            previousPeriod.conversion.taxableAcctEOY;

        results.rothConversion.traditionalIRAEndingBalance =
            previousPeriod.conversion.traditionalIRAValueEOY;

        results.rothConversion.withdrawalAccountTaxable =
            previousPeriod.conversion.withdrawalAccountEOY -
            (previousPeriod.conversion.withdrawalAccountEOY -
                previousPeriod.conversion.withdrawalAccountBasis) *
            heirsMarginalLTCGTaxRateAtDeath;

        results.rothConversion.totalTaxPayments = periods.reduce(
            (total, period) =>
                total +
                period.conversion.taxPayment +
                period.conversion.RMDTax +
                period.conversion.taxesPaidDueToTaxableAcctWithdrawal +
                period.conversion.withdrawalFromTraditionalIRATax,
            0
        );

        results.rothConversion.valueToHeir =
            results.rothConversion.rothIRAEndingValue +
            results.rothConversion.taxableAccountEndingBalance *
            (1 - heirsMarginalOrdinaryIncomeTaxRateAtDeath) +
            results.rothConversion.traditionalIRAEndingBalance *
            (1 - heirsMarginalOrdinaryIncomeTaxRateAtDeath);

        results.netValueToHeir =
            results.rothConversion.valueToHeir - results.noConversion.valueToHeir;

        results.netBenefitOfRothConversion =
            results.rothConversion.rothIRAEndingValue +
            results.rothConversion.taxableAccountEndingBalance +
            results.rothConversion.traditionalIRAEndingBalance -
            (results.noConversion.traditionalIRAEndingBalance +
                results.noConversion.taxableAccountEndingBalance);

        results.netBenefitOfRothConversionDiff.conversion =
            previousPeriod.conversion.savingsImpact;
        results.netBenefitOfRothConversionDiff.noConversion =
            previousPeriod.noConversion.savingsImpact;

        const transformed2DollarResults: any =
            this.transformValues2DollarNotation(results);

        // ////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Build Graph and Table Data
        // ////////////////////////////////////////////////////////////////////////////////////////////////////////

        const conversion = [];
        const noConversion = [];
        const labels = [];

        switch (graphType) {
            case "one_year":
                periods.forEach((elem, index) => {
                    labels.push(`${elem.age}`);
                    conversion.push(Math.ceil(elem.conversion.rothIRAEOY));
                    noConversion.push(
                        Math.ceil(elem.noConversion.annualConversionAfterTaxes)
                    );
                });
                break;
            case "multi_year":
                periods.forEach((elem, index) => {
                    labels.push(`${elem.age}`);
                    conversion.push(Math.ceil(elem.conversion.savingsImpact));
                    noConversion.push(Math.ceil(elem.noConversion.savingsImpact));
                });
                break;
        }

        // Tabla final

        transformed2DollarResults.periods = periods.map((p) => ({
            year: p.year.toString(),
            age: p.age.toString(),
            taxBracket: p.conversion.marginalIncomeTaxRateString,
            savingsImpact: this.transformValues2DollarNotation(
                p.conversion.savingsImpact - p.noConversion.savingsImpact
            ),
            noConversion: this.transformValues2DollarNotation(
                p.noConversion.savingsImpact
            ),
            withConversion: this.transformValues2DollarNotation(
                p.conversion.savingsImpact
            ),
        }));
        transformed2DollarResults.incomeAtKickOfAge = incomeAtKickOfAge;
        transformed2DollarResults.graph = {
            noConversion,
            conversion,
            labels,
        };
        console.log(transformed2DollarResults);
        return transformed2DollarResults;
    }

    /**
     * Transform a value into dollar notation separated by commas
     * @param data Value to transform
     */
    private static transformValues2DollarNotation(data: any) {
        if (typeof data === "object") {
            const transformed = {};
            for (const key in data) {
                transformed[key] = this.transformValues2DollarNotation(data[key]);
            }
            return transformed;
        } else if (typeof data === "number") {
            return "$" + data.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        return "$" + (+data).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    /**
     * Calculate the optimal number of years to convert using the dataset in the calculator's instance
     * @param minAllowedYears
     * @param maxAllowedYears
     */
    calculateOptimalNumOfYearsToConvert(
        dataset: any,
        minAllowedYears: number,
        maxAllowedYears: number
    ) {
        // Initial max value
        let max = [
            1,
            +this.exec(dataset, minAllowedYears, "multi_year")
                .netBenefitOfRothConversion.replace("$", "")
                .split(",")
                .join(""),
        ];

        // Search for optimal
        for (let i = minAllowedYears + 1; i <= maxAllowedYears; i++) {
            const calculation = +this.exec(dataset, i, "multi_year")
                .netBenefitOfRothConversion.replace("$", "")
                .split(",")
                .join("");
            if (max[1] < calculation) {
                max = [i, calculation];
            }
        }

        return max[0];
    }

    /**
     * Calculate the optimal conversion amount for Roth conversion
     * @param taxableIncome
     * @param year
     * @param filingStatus
     */
    calculateOptimalConversionAmount(
        taxableIncome: number,
        beginningIRAAssetValue: number,
        year: string,
        filingStatus: string
    ) {
        const MAX_CONVERSION_AMOUNT = 250000;
        const { OrdinaryIncomeTaxBracketTables, StandardDeductionTables } =
            this.TABLES;
        const ordinaryIncomeTable =
            OrdinaryIncomeTaxBracketTables[year][filingStatus];
        const standardDeduction = StandardDeductionTables[year][filingStatus];
        const marginalIncomeTableRates = Object.keys(ordinaryIncomeTable);

        if (taxableIncome < standardDeduction) {
            return standardDeduction - taxableIncome;
        } else {
            for (let i = 0; i < marginalIncomeTableRates.length - 1; i++) {
                const rate = marginalIncomeTableRates[i];
                const topThreshold = ordinaryIncomeTable[rate] || Infinity;
                const bottomThreshold =
                    i > 0 ? ordinaryIncomeTable[marginalIncomeTableRates[i - 1]] : 0;
                if (taxableIncome <= topThreshold) {
                    return Math.min(topThreshold - taxableIncome, beginningIRAAssetValue);
                }
            }
        }

        return Math.min(beginningIRAAssetValue, MAX_CONVERSION_AMOUNT);
    }

    calculateTaxBracket(income: number, year: number, filingStatus: string) {
        const { OrdinaryIncomeTaxBracketTables, StandardDeductionTables } =
            this.TABLES;
        const ordinaryIncomeTable =
            OrdinaryIncomeTaxBracketTables[year][filingStatus];
        const standardDeduction = StandardDeductionTables[year][filingStatus];
        const marginalIncomeTableRates = Object.keys(ordinaryIncomeTable);

        if (income >= standardDeduction) {
            for (let i = 0; i < marginalIncomeTableRates.length; i++) {
                const rate = marginalIncomeTableRates[i];
                const topThreshold = ordinaryIncomeTable[rate] || Infinity;
                if (income <= topThreshold) {
                    return rate;
                }
            }
        }

        return "0%";
    }

    calculateOptimizedConversionAmount(
        dataset: any,
        maxConversionAmount: number
    ) {
        let maxDiff = 0,
            optConversionAmount = 0;

        // Execute calculator and compare both scenarios' savings impact
        const execAndCompare = (conversionAmount: number) => {
            const auxDataset = {
                ...dataset,
                annualConversionAmount: conversionAmount.toString(),
            };
            const calculation = this.exec(auxDataset, 1, "multi_year");
            const diff = +calculation.netBenefitOfRothConversion
                .replace("$", "")
                .split(",")
                .join("");

            if (diff > maxDiff) {
                maxDiff = diff;
                optConversionAmount = conversionAmount;
            }

            return diff;
        };

        // Initial diff to compare with following stages
        maxDiff = execAndCompare(1000);

        // Eval all the stages in the range [2000, maxConversionAmount)
        for (
            let conversionAmount = 2000;
            conversionAmount < maxConversionAmount;
            conversionAmount += 1000
        ) {
            execAndCompare(conversionAmount);
        }

        // Eval the maxConversionAmount
        execAndCompare(maxConversionAmount);

        return optConversionAmount;
    }

    /**
     * Transform a value into dollar notation separated by commas
     * @param data Value to transform
     */
    transformValues2DollarNotation(data: any) {
        if (typeof data === "object") {
            const transformed = {};
            for (const key in data) {
                transformed[key] = this.transformValues2DollarNotation(data[key]);
            }
            return transformed;
        } else if (typeof data === "number") {
            return "$" + data.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        return "$" + (+data).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}
