import { Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AdvisorService } from 'app/shared/services/advisor.service';

import {
  ENVIRONMENT_URL_PRODUCTION,
  urlHelperIsInEnvironment,
  urlPrependRootContext,
} from '../../../shared/helpers/url.helper';
import { AuthenticationService } from '../../../shared/services/auth/authentication.service';
import { AppSocketIoService } from '../../../shared/services/socket.io.service';
import { ConcurrentSessionModalComponent } from '../concurrent-session-modal/concurrent-session-modal.component';
import { ExpiredSessionModalComponent } from '../expired-session-modal/expired-session-modal.component';
import { TwoFactorAuthModalComponent } from '../two-factor-auth-modal/two-factor-auth-modal.component';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit, OnDestroy {
  @ViewChild(MatProgressBar, { static: false }) progressBar: MatProgressBar;
  @ViewChild(MatButton, { static: false }) submitButton: MatButton;
  @ViewChild(TwoFactorAuthModalComponent, { static: false }) twoFactorInput: any;

  public ieSniffer: boolean = false;
  public isMaintenanceBreak: boolean = false;
  signinForm: FormGroup;
  returnUrl: string;
  errorDesc: string;
  mailText: string;
  public isSubmitDisabled: boolean = false;
  public counter: { min: number, sec: number }
  public isProduction: boolean = false;
  private originUrl: string;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private snackBarSuccess: MatSnackBar,
    private snackBarError: MatSnackBar,
    public dialog: MatDialog,
    public socketService: AppSocketIoService,
    public advisorService: AdvisorService,
    private route: ActivatedRoute,
    private renderer: Renderer2
  ) {


    this.route.queryParams.subscribe(params => {
      //MGP Integration
      if(params['origin'] == 'integration' && params['source'] == 'mgp'){
        let data = {
          fullName: params['fullName'],
          email: params['email'],
          clientStatus: params['clientStatus'],
          status: params['status'],
          origin: params['source']
        }

        localStorage.setItem("mgp", 'true');
        localStorage.setItem("mgpData", JSON.stringify(data));

        this.router.navigate(['/advisor/clients']);

      }

      //Orion Integration
      if(params['origin'] == 'integration' && params['source'] == 'orion'){
        let data = {
          fullName: params['fullName'],
          email: params['email'],
          clientStatus: params['clientStatus'],
          status: params['status'],
          origin: params['source']
        }

        localStorage.setItem("orion", 'true');
        localStorage.setItem("orionData", JSON.stringify(data));

        this.router.navigate(['/advisor/clients']);

      }
    });

    this.renderer.addClass(document.body, 'login-page');

  }

  maintenanceCommon(){
    if (this.isMaintenanceBreak) {
      this.startTimer();
      this.isSubmitDisabled = true;
    }
  }

  ngOnInit() {
    let isOverrideMaintenanceActive = localStorage.getItem('elmoRise') === 'letItBurn';
    this.isMaintenanceBreak = this.isMaintenanceBreak && !isOverrideMaintenanceActive; //A && !B
    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);
    this.activeRoute.queryParams.subscribe(params => {
      this.originUrl = params.originUrl;
    });

    this.advisorService.isLoginEnabled().then( nothing =>{
      this.isMaintenanceBreak = false && !isOverrideMaintenanceActive;
      this.maintenanceCommon();
    }).catch(error => {
      this.isMaintenanceBreak = true && !isOverrideMaintenanceActive;
      this.maintenanceCommon();
    })

    let ua = window.navigator.userAgent;
    let isIE = /MSIE|Trident/.test(ua);

    if ( isIE ) {

      this.ieSniffer = true;
    } else {

    }


    this.signinForm = new FormGroup({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      rememberMe: new FormControl(false)
    })

    // reset login status if any session is active
    if (localStorage.sessionToken)
      this.authenticationService.logout(null, 'Client went to login page').then(data => {
        console.log("Force session to logout");
      });

    // Open a modal if that the route param a indicates


    let activityParam = this.route.snapshot.queryParamMap.get("a");

    if(activityParam){
      if(activityParam == 'inactivity'){
        this.dialog.open(ExpiredSessionModalComponent, {
          data: {}
        });
      }
    }

    let pendoBadgeElement = document.getElementById('_pendo-badge_LpjY1Dw3OQBGJs5b-4ueiUIgpus');

    if(pendoBadgeElement){
      pendoBadgeElement.style.display = 'none';
    }

    this.maintenanceCommon();

  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'login-page');
  }

  get f() { return this.signinForm.controls; }

  contactSupport() {

    this.mailText = "mailto:team@fpalpha.com?subject=Account blocked";
    window.location.href = this.mailText;
  }

  signin() {

    this.isSubmitDisabled = true;
    this.authenticationService.login(this.f.email.value, this.f.password.value)
      .subscribe(
        async data => {
          if(this.authenticationService.isSessionActive())
          this.socketService.initSocketAndSubscribeToDefaultEvents();
          if(this.authenticationService.isAdvisor()) {
            if (this.originUrl) {
              location.href = this.originUrl;
            } else {
                // Payment logic to show modal if user does not have a valid payment method
                // Keep for future reference

                // const { hasPaymentMethod, hasActiveDefaultPaymentMethod, needsPaymentMethod, isOnFreeTrialPeriod }: any = await this.advisorService.userHasPaymentMethod();
                // setObjectToStorage('hasPaymentModality', hasPaymentMethod);
                // setObjectToStorage('hasActiveDefaultPaymentMethod', hasActiveDefaultPaymentMethod);

                // if ((!isOnFreeTrialPeriod && (!hasPaymentMethod || !hasActiveDefaultPaymentMethod) && needsPaymentMethod)) {
                //   this.dialog.open(ReviewPaymentMethodComponent, {
                //     panelClass: 'modal-dialog-with-form',
                //     width: '724px',
                //     data: {
                //       hasActiveDefaultPaymentMethod,
                //       hasPaymentMethod,
                //       needsPaymentMethod
                //     },
                //   });
                // }

              this.router.navigate([urlPrependRootContext('/clients')]);
            }
          }
          if(this.authenticationService.isClient())
          this.router.navigate([urlPrependRootContext('/dashboard')]);
        },

        error => {
          let errorMsg = error.error.message || error.statusText;

          this.errorDesc = errorMsg;

          if (this.errorDesc == 'Account blocked') {

            let snackBarRef = this.snackBarError.open(
              this.errorDesc + ".",
              "Contact support.",
              {
                duration: 9000,
                verticalPosition: "top",
                panelClass: "error-snackbar"
              }
            );

            snackBarRef.onAction().subscribe(() => this.contactSupport());
          // Concurrent session dialog
          } else if (this.errorDesc == 'CONCURRENT_LOGIN_LIMIT') {
            // Open dialog
            const dialogRef = this.dialog.open(ConcurrentSessionModalComponent, {
              data: {
                browser: error.error.browser ? error.error.browser : 'Undefined',
                os: error.error.os ? error.error.os : 'Undefined'
              }
            });
            // Subscribe to result
            dialogRef.afterClosed().subscribe(result => {
              if (result) {

                this.authenticationService.forceLogout(error.error.token, this.f.email.value).subscribe(result2 => {

                  this.signin();
                })
              }
            });

          // Two-factor authentication dialog

          } else if (this.errorDesc == '2FA_REQUIRED') {

            let recursiveDialog = () => {
              // Open dialog
              const dialogRef = this.dialog.open(TwoFactorAuthModalComponent, {
                data: {
                  idAdvisor: error.error.idAdvisor ? error.error.idAdvisor: 'Undefined'
                }
              });
              // Subscribe to result
              dialogRef.afterClosed().subscribe(result => {

                if (result) {
                  this.authenticationService.twoFactorAuth(error.error.idAdvisor, result).subscribe(result2 => {

                    this.signin();
                  });
                }
              });
            }

            // Open dialog
            const dialogRef = this.dialog.open(TwoFactorAuthModalComponent, {
              data: {
                idAdvisor: error.error.idAdvisor ? error.error.idAdvisor: 'Undefined'
              }
            });

            // Subscribe to result
            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                this.authenticationService.twoFactorAuth(error.error.idAdvisor, result).subscribe(
                  result2 => {
                    this.signin();
                  },
                  error => {
                    this.snackBarError.open(
                      "Invalid verification code",
                      "",
                      {
                        duration: 9000,
                        verticalPosition: "top",
                        panelClass: "error-snackbar"
                      });

                    recursiveDialog();

                  });
              }
            });

          } else {
            const msg: string = `Something is wrong with your credentials. \n ${this.errorDesc}`;
            this.snackBarError.open(
              msg,
              "Dismiss",
              {
                duration: 9000,
                verticalPosition: "top",
                panelClass: "error-snackbar"
              }
            );
          }

          this.isSubmitDisabled = false;
        });
  } //Ends signin()

  startTimer() {
    this.counter = { min: 120, sec: 0 } // choose whatever you want
    let intervalId = setInterval(() => {
      if (this.counter.sec - 1 == -1) {
        this.counter.min -= 1;
        this.counter.sec = 59
      }
      else this.counter.sec -= 1
      if (this.counter.min === 0 && this.counter.sec == 0) clearInterval(intervalId)
    }, 1000)
  }
}

