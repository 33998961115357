import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { SpecialComplex } from 'app/views/reports/reportOutputPDF';
import { environment } from 'environments/environment';
import html2canvas from 'html2canvas';

import { ESettingsService } from '../../services/e-settings.service';
import { readFromStoragedObject } from 'app/shared/helpers/utils';

@Component({
  selector: 'app-tax-snapshot-pdf',
  templateUrl: './tax-snapshot-pdf.component.html',
  styleUrls: ['./tax-snapshot-pdf.component.scss']
})
export class TaxSnapshotPdfComponent implements OnInit {

  @Input() year: any;
  @Input() activatedLeft: any = 'ordinary'
  public backendResponse: any = null;
  public backendError: any = null;
  public verticalChartDataMarginalIncomeOrdinaryIncome: any;
  public verticalChartDataMarginalIncomeCapitalGains: any;
  public verticalChartDataStateIncomeOrdinaryIncome: any;
  public verticalChartDataStateIncomeCapitalGains: any;
  public verticalChartDataDeductionsIncome: any;
  public verticalChartDataChairtableIncome: any;
  public verticalChartDataRothConversion: any;
  public verticalChartDataRothConversionOverflow: any;
  public verticalChartDataEstimatedTax: any;
  public tableExtractions: any;
  public tableIncome: any;
  public phaseChartDataGrossIncomeTiers: any;
  public textTableDeductionSummary: any;
  public tableLTCDeducation: any;
  public tableHealthSavingsAccount: any;
  public dotDataMedicarePremiums: any;
  public tableSocialSecurity: any;
  public horizontalChartDataIncome: any;
  public horizontalChartQBI: any;
  public statePercentage: any;
  public secondaryVerticalDataMarginalIncomeOrdinaryIncome: any;
  public hiddenElements: string[] = [];
  public selectedIndicator: string = 'ordinary';

  public clientFullName: string = '';
  public isLoadingPdf: boolean = false;

  static tempCanvases: any = null;

  public clientHasBusiness: boolean = false;
  public clientHasScheduleC: boolean = false;

  public tableInterest: any;
  public tableOrdinaryDividends: any;

  public fullDisclaimerData: any;

  constructor(
    public http: HttpClient,
    public settingsService: ESettingsService
              ) { }

  ngOnInit() {
    this.loadDataSnapshot();
    this.settingsService.getCompanyData().toPromise().then(response => {
      this.fullDisclaimerData = response;
    })
  }

  removeConditionalVariables() {
    let bothZeros = true;
    const aux: any = [];
    let indexOfCapital = 0;
    let stop = false;
    for (const { subtitle, value } of this.tableExtractions.data) {
      if (subtitle === "Realized Short Term Capital G/L") {
        bothZeros = bothZeros && value === '$0'
      }
      if (subtitle === "Realized Long Term Capital G/L") {
        bothZeros = bothZeros && value === '$0'
      }
    }
    for (const { subtitle, value } of this.tableExtractions.data) {
      if (bothZeros) {
        if (subtitle !== "Realized Short Term Capital G/L" && subtitle !== "Realized Long Term Capital G/L") {
          if (subtitle !== 'Capital Gains Or Loss' && !stop) {
            indexOfCapital += 1;
          } else {
            stop = true;
          }
          aux.push({subtitle, value});
        }
      } else {
        if (subtitle !== "Capital Gains Or Loss") {
          aux.push({subtitle, value});
        }
      }
    }
    if (bothZeros) {
      const b = aux[indexOfCapital - 1]
      aux[indexOfCapital - 1] = aux[indexOfCapital];
      aux[indexOfCapital] = b;
    }
    this.tableExtractions.data = aux;
  }

  loadDataSnapshot(){
    this.getData().then((response: any[]) =>{
      console.log('Respuesta ', response);
      this.backendResponse = response;
      let hiddenComponents = response.filter(obj => obj.id == "hiddenComponents").pop();
      this.hiddenElements = hiddenComponents['items'];
      if (this.hiddenElements.includes('ordinary')) {
        this.activatedLeft = 'taxable';
      }
      this.tableExtractions = response.filter(obj => obj.id == "extractions").pop();
      this.tableIncome = response.filter(obj => obj.id == "income").pop();
      this.verticalChartDataMarginalIncomeOrdinaryIncome = response.filter(obj => obj.id == "marginalIncomeTaxBracketOrdinaryIncome").pop();
      this.verticalChartDataMarginalIncomeCapitalGains = response.filter(obj => obj.id == "marginalIncomeTaxBracketCapitalGains").pop();
      this.verticalChartDataStateIncomeOrdinaryIncome = response.filter(obj => obj.id == "stateIncomeTaxBracketOrdinaryIncome").pop();
      this.verticalChartDataStateIncomeCapitalGains = response.filter(obj => obj.id == "stateIncomeTaxBracketCapitalGains").pop();
      this.verticalChartDataDeductionsIncome = response.filter(obj => obj.id == "deductions").pop();
      this.verticalChartDataChairtableIncome = response.filter(obj => obj.id == "charitableContributionDeduction").pop();
      this.verticalChartDataRothConversion = response.filter(obj => obj.id == "rothConversion").pop();
      this.verticalChartDataRothConversionOverflow = response.filter(obj => obj.id == "rothConversionOverflow").pop();
      this.verticalChartDataEstimatedTax = response.filter(obj => obj.id == "estimatedTax").pop();
      this.phaseChartDataGrossIncomeTiers = response.filter(obj => obj.id == "modifiedAdjustedGrossIncomeTiers").pop();
      this.textTableDeductionSummary = response.filter(obj => obj.id == "deductionSummary").pop();
      this.tableLTCDeducation = response.filter(obj => obj.id == "ltcDeductionLimit").pop();
      this.tableHealthSavingsAccount = response.filter(obj => obj.id == "healthSavingsAccount").pop();
      this.dotDataMedicarePremiums = response.filter(obj => obj.id == "medicarePremiums").pop();
      this.tableSocialSecurity = response.filter(obj => obj.id == "socialSecurity").pop();
      this.horizontalChartDataIncome = response.filter(obj => obj.id == "incomeGraph").pop();
      this.horizontalChartQBI = response.filter(obj => obj.id == "qbiAnalysis").pop();
      this.statePercentage = response.filter(obj => obj.id == "stateIncomeTaxBracketEffectiveTaxRate").pop();
      this.tableInterest = response.filter(obj => obj.id == 'interest').pop();
      this.tableOrdinaryDividends = response.filter(obj => obj.id == 'ordinarydividends').pop();
      this.secondaryVerticalDataMarginalIncomeOrdinaryIncome = response.filter(obj => obj.id == 'taxableIncomeTaxBracketOrdinaryIncome').pop();
      let additionalInfo = response.filter(obj => obj.id == "additionalInfo").pop();
      this.dotDataMedicarePremiums['taxReturnYear'] = additionalInfo['taxReturnYear'];
      this.dotDataMedicarePremiums['filingStatus'] = additionalInfo['filingStatus'];
      this.clientHasBusiness = additionalInfo['hasBusiness'] === 'Yes' ? true : false;
      this.clientHasScheduleC = additionalInfo['hasScheduleC'] === 'Yes' ? true : false;

    }).catch(error=>{
      console.log("Error", error)
      this.backendError = "An error occurred while loading your data"
    });
  }

/*   ngAfterViewInit() {

    setTimeout( ()=>{
      TaxSnapshotPdfComponent.tempCanvases = TaxSnapshotPdfComponent.getTaxSummaryReportCanvases();
    },
     800);
  } */

  ngOnDestroy() {
    TaxSnapshotPdfComponent.tempCanvases = null;
  }

  async getData() {
    let currentClient: string = sessionStorage.getItem('currentClient');
    this.clientFullName = `${readFromStoragedObject(
      "currentClient",
      "fullName",
      "Session"
    )}`;

    let clientId = JSON.parse(currentClient).clientId;
    //return this.http.get(`${environment.apiAdvisorSummary}/${clientId}/tax-summary`).toPromise();
    return this.http.get(`${environment.apiAdvisorSummary}/${clientId}/tax-summary?year=${this.year}`).toPromise();
  }

  static async getTaxSummaryReportCanvases(onlyFromThisListOfIds: string[] = []): Promise <SpecialComplex[]> {
    //if(TaxSnapshotPdfComponent.tempCanvases != null) return TaxSnapshotPdfComponent.tempCanvases
    //sizes: F (full page), W (full width), E (eighth)
    let listOfComponents: any[] = [
      { id: 'taxSummary_top', size: 'W', index: 1},
      { id: 'taxSummary_marginalIncome', size: 'E', index: 2},
      { id: 'taxSummary_stateIncome', size: 'E', index: 3},
      { id: 'taxSummary_deductions', size: 'E', index: 4},
      { id: 'taxSummary_charitable', size: 'E', index: 5},
      { id: 'taxSummary_magi', size: 'W', index: 6},
      { id: 'taxSummary_qbi', size: 'E', index: 7},
      { id: 'taxSummary_roth', size: 'E', index: 8},
      { id: 'taxSummary_medicare', size: 'E', index: 9},
      { id: 'taxSummary_estimatedTax', size: 'E', index: 10},
      { id: 'taxSummary_schB', size: 'F', index: 11},
      { id: 'taxSummary_ltcDeductions', size: 'E', index: 12},
      { id: 'taxSummary_healthSavingsAccount', size: 'E', index: 13},
      { id: 'taxSummary_socialSecurity', size: 'W', index: 14},
      { id: 'taxSummary_futureLegislature', size: 'W', index: 15},
      { id: 'taxSummary_summary995', size: 'W', index: 16},
      { id: 'taxSummary_deductionsSummary', size: 'F', index: 17},
    ];


    if(onlyFromThisListOfIds.length > 0){
      listOfComponents =  listOfComponents.filter(component => { return onlyFromThisListOfIds.includes(component.id)});
    }

    let taxSummaryReportCanvases = []

    await Promise.all(listOfComponents.map(async component => {
      let htmlElement = document.getElementById(component.id);
      if(htmlElement != null){
        let canvas =  await html2canvas(document.getElementById(component.id), { scale: 1, useCORS: true });
        taxSummaryReportCanvases.push({
          canvases: [canvas],
          extraData: {size: component.size, id: component.id},
          title: '',
          index: component.index
        });
        return canvas;
      }else{
        console.log("ALERT. Coudn't find the element with id: " + component.id);
      }
    }));

    let sortedTaxSummaryCanvases = taxSummaryReportCanvases.sort((a, b) => (a.index > b.index) ? 1 : -1)

    return sortedTaxSummaryCanvases;

  }

    // All changes to input data will be processed here.
    ngOnChanges(changes: SimpleChanges) {
      if (changes?.activatedLeft && changes?.activatedLeft?.currentValue) {
        this.activatedLeft = changes?.activatedLeft?.currentValue;
      }
      if(changes.year && changes.year.currentValue !== undefined && changes.year.currentValue !== ''){
        this.loadDataSnapshot();
      }
      console.log(this.activatedLeft);
    }


}
