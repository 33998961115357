import { SECTIONS_CATALOG } from './../../tax-projections/scenarios/Scenario.class';
//Angular
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AfterViewChecked, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DialogGenericNoticeComponent } from 'app/shared/components/dialog-generic-notice/dialog-generic-notice.component';
import { FullPageSpinnerComponent } from 'app/shared/components/full-page-spinner/full-page-spinner.component';
import {
  ReviewExtractionsDialogComponent,
} from 'app/shared/components/review-extractions-dialog/review-extractions-dialog.component';
import { ENVIRONMENT_URL_PRODUCTION, getEndPointUrlByContext, urlHelperIsInEnvironment, UrlParamEditor } from 'app/shared/helpers/url.helper';
import { cleanNameForPDF, delay, findTablesFilingStatus } from 'app/shared/helpers/utils';
import { saveAs } from 'file-saver';

import { environment } from '../../../../environments/environment';
import { fpAnimations } from '../../../shared/animations/fp-animations';
import { Area } from '../../../shared/helpers/areas.catalog';
import { WINDOW } from '../../../shared/helpers/window.provider';
import { AuthenticationService } from '../../../shared/services/auth/authentication.service';
import { CommaFormatedNumberToJSNumber } from '../DataTransformation.class';
import { FileUploaderComponentComponent, FileUploaderOptions, UPLOADER_STATUS } from '../file-uploader-component/file-uploader-component.component';
import { FileUploaderExtended } from '../FileUploaderExtended.model';
import { FileUploadersArrayHelper } from '../FileUploadersArrayHelper';
import { general_questions } from '../q-general/general.model';
import {
  CONST_ABSOLUTES,
  CONST_ABSOLUTES_NOTSURE,
  CONST_ABSOLUTES_UNSURE,
  CONST_ABSOLUTES_UNSURE_TAX_ONLY,
  CONST_FILLING_STATUSES,
  CONST_FILLING_TAX_GENDERS,
  CONST_HOME_BUILDINGS,
  CONST_FILLING_STATUSES_2,
  CONST_HOUSE_OR_FARM,
  CONST_STATES_KEY,
  CONST_TAX_SALARY_INCREMENTS,
  CONST_CAR_BUSINESS_PURPOSE,
  CONST_IRAS,
  CONST_CONTRIB
} from '../Questionnaire.constants';
import { QuestionnaireController } from '../Questionnaire.controller';
import { Questionnaire } from '../Questionnaire.model';
import { uploadersValidator, validateAndSubmit, Validations } from '../Questionnaire.validations';
import { FieldsMetadata, TAX_SECTIONS_CATALOG } from './q-tax.metadata';
import { FullValidationFlag, taxfull_questions } from './taxfull-model';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { GeneralDialogV2Component } from 'app/shared/components/general-dialog-v2/general-dialog-v2.component';
import { ClientService } from 'app/shared/services/client/client.service';

const UPDATE = true;

@Component({
  selector: 'app-q-tax',
  templateUrl: './q-tax.component.html',
  styleUrls: ['./q-tax.component.scss'],
  animations: fpAnimations
})
export class QTaxComponent implements OnInit, OnDestroy {

  @ViewChild("taxform", { static: false }) autoform;
  @ViewChild('tabRef', { static: false }) tabRef: MatTabGroup ;
  @ViewChild('target', { static: false }) targetEl: ElementRef;

  public tabsCatalog = TAX_SECTIONS_CATALOG;
  public currentTabQuestions: TabSubsectionContent[] = [];
  public hasInitialYear: boolean = false;
  public auxModel: any;
  public model: taxfull_questions;
  public clientHasAnsweredQuestionnaire: boolean;
  public clientData: any;
  public clientHasBusiness: string = "";
  public clientTypeOfBusiness: string;
  public clientMaritalStatus: string;
  public clientAge: number;
  public clientHasChildren: string;
  public clientIDChildOneAge: number;
  public clientIDChildTwoAge: number;
  public clientIDChildThreeAge: number;
  public clientIsRetired: boolean = false;
  public clientSpouseIsRetired: boolean = false;
  public companyId: string;
  public creditsInformation: any;
  public companyType: string = 'legacy';
  public newTaxFieldsMetadata: any = FieldsMetadata;

  public showChildrenSection: boolean;

  public haveFilePDF: boolean = false;
  public uploader: FileUploaderExtended;
  public hasBaseDropZoneOver: boolean = false;
  public whoAnswered: string;

  public answers: Array<string>[] = [];

  public CONSTANTS = { CONST_FILLING_STATUSES_2, CONST_CAR_BUSINESS_PURPOSE, CONST_HOUSE_OR_FARM, CONST_ABSOLUTES, CONST_ABSOLUTES_UNSURE, CONST_STATES_KEY, CONST_FILLING_STATUSES, CONST_FILLING_TAX_GENDERS, CONST_TAX_SALARY_INCREMENTS, CONST_ABSOLUTES_UNSURE_TAX_ONLY, CONST_ABSOLUTES_NOTSURE, CONST_IRAS, CONST_CONTRIB };
  public validations = Validations;
  public fileUploaderArray: FileUploadersArrayHelper;
  public policyUploaderOptions2019: FileUploaderOptions;
  public policyUploaderOptions2020: FileUploaderOptions;
  public policyUploaderOptions2021: FileUploaderOptions;
  public policyUploaderOptions2022: FileUploaderOptions;
  public policyUploaderOptions2023: FileUploaderOptions;
  public extractionResults: any[] = [];

  public isAdvisor: boolean = false;
  public isManualInputEnabled = true;
  public isProduction:boolean;

  public ntabs: number;
  public progress: number;
  public progressnumber: number = 0;
  public selected: number;
  public progresscompleted: boolean = false;
  // private isFirstTimeHere: string;
  public isLoading: boolean;
  public bulletColor;
  public generalData: general_questions = new general_questions();
  public childCount: number;
  public occupation: string[] = ["health", "law", "accounting", "actuarial science", "performing arts", "consulting", "athletics", "financial services", "investing", "investment management", "trading"];
  public removeIRAq: boolean = false;
  public joinIncome: number;
  public clientIDNetWorth: number;
  public clientIDSpouseNetWorth: number;
  public clientHasSCorp: boolean;
  public clientLivingState: string;
  public pdfUrl: string = environment.apiPDFQuestionnaire;
  public toggleSearchBar: boolean = false;
  public documentIsValid: boolean = false;
  public loadingCredits = true;
  public isBetaTester: boolean = false;
  public taxYearsAvaliable: any = ['2021', '2022', '2023'];
  public taxYearsAvaliableInitial: any = [];
  public disableQuest: boolean = false;

  public commaFormattedToNumber = CommaFormatedNumberToJSNumber;


  public filingStatusRequired: boolean = false;

  public listOfActiveValidationFlags: FullValidationFlag[] = [];
  public isModalOpenNoticeNeedsReview: boolean = false;

  // Listo to verify that only one modal of the same type.
  public listOfOpenModals: string[] = [];

  // For front end flag validations

  public extendedFilingStatus: string = '';

  public isToggleDisabled: boolean = false;

  public fullPageSpinnerModal;

  public isFirstTabLoad: boolean = true;

  public advisorPreferences: any;

  public loadingQuestions: boolean = false;

  public clientTaxYears: any = [];

  public questionnaireSent: boolean = false;

  retirements = [
    { value: "Me", display: "Client has it" },
    { value: "Spouse", display: "Spouse has it" },
    { value: "No", display: "No" }
  ];

  retirementsingle = [
    { value: "Me", display: "Yes" },
    { value: "notsure", display: "Not sure" },
    { value: "No", display: "No" }
  ];

  couples = [
    { value: "Spouse", display: "Yes" },
    { value: "No", display: "No" }
  ];

  iras = [
    { value: "By IRA/Roth IRA account", display: "By IRA account" },
    { value: "By the beneficiary", display: "By the owner" }
  ];

  analysis = [
    { value: "Instant tax analysis", display: "Instant tax analysis" },
    { value: "Indepth analysis", display: "In-depth analysis" }
  ];

  public indexTab: number = 0;

  public isProcessingFile  = false;
  public allValidModalRef: MatDialogRef<any>;

  constructor(
    private http: HttpClient,
    private router: Router,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public qController: QuestionnaireController,
    public authService: AuthenticationService,
    public advisorService: AdvisorService,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private currentClientService: ClientService,
    @Inject(WINDOW) private window: Window
  ) { }

  ngOnInit() {
    let preLoadImage = new Image();
    const ans: any = JSON.parse(localStorage.getItem("advisorInfo"));
    this.companyId = ans?.companyId;
    console.log('COMPANY: ', this.companyId, ans);
    this.getCompanyCredits();
    preLoadImage.src = "assets/images/fp_loader_background_gray.gif";
    console.log(this.CONSTANTS);
    this.getTaxYearsAvailable();
    this.taxYearsAvaliableInitial = JSON.parse(JSON.stringify(this.taxYearsAvaliable));
    this.isAdvisor = this.authService.isAdvisor();
    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    this.clientMaritalStatus = this.clientData.isMarried;
    this.clientHasBusiness = this.clientData.hasBusiness;
    this.clientTypeOfBusiness = this.clientData.typeOfBusiness;
    this.clientHasChildren = this.clientData.hasChildren;
    this.clientAge = parseInt(this.clientData.age);
    this.clientIDChildOneAge = this.clientData.childOneAge;
    this.clientIDChildTwoAge = this.clientData.childTwoAge;
    this.clientIDChildThreeAge = this.clientData.childThreeAge;
    this.childCount = this.clientData.childrenCount + 1;
    if (this.clientData.isRetired === 'Yes') this.clientIsRetired = true;
    if (this.clientData.spouseIsRetired === 'Yes') this.clientSpouseIsRetired = true;
    this.isAdvisor ? this.whoAnswered = 'Advisor' : this.whoAnswered = 'Client';
    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);

    this.advisorPreferences = JSON.parse(localStorage.getItem('advisorPreferences'));

    // Loading screen ON
    this.isLoading = true;

    //Initialize the questionnaire model
    this.model = new taxfull_questions();


    this.model.afterMappingLoad = (model) => {
      if (this.clientIDChildOneAge < 19 || this.clientIDChildTwoAge < 19 || this.clientIDChildThreeAge < 19) this.model.clientHas1040Box6c = 'Yes';
      if (this.clientMaritalStatus === 'Single') this.model.advisorIDOneSpouseWorks = 'Yes';
      if (model.clientIDFillingStatus === 'Joint') { this.model.clientIDFillingStatus = 'Married Filing Jointly'}
      if (model.clientIDFillingStatus === 'Other') { findTablesFilingStatus(this.clientData.isMarried, 'Other')}

      //if(this.clientMaritalStatus === 'Single') this.model.clientIDFillingStatus = 'Single';

    };

    // this.clientAge > 65 ? this.model.clientIDIsOnMedicare = 'Yes' : this.model.clientIDIsOnMedicare = 'No';
    if (this.clientMaritalStatus === 'Single') this.model.advisorIDOneSpouseWorks = 'Yes';

    this.clientTypeOfBusiness === 'S Corp' || this.clientTypeOfBusiness === 'Partnership' ? this.model.clientHasForm1120S = 'Yes' : this.model.clientHasForm1120S = 'No';
    this.clientData.isRetired === 'Yes' && this.clientData.spouseIsRetired === 'Yes' ? this.model.advisorIDOneSpouseWorks = 'No' : this.model.advisorIDOneSpouseWorks = 'Yes';

    //Show sections with complex conditionals

    if (this.clientHasChildren === 'Yes' && this.clientIDChildOneAge < 13 && this.clientIsRetired === false || this.clientHasChildren === 'Yes' && this.clientIDChildTwoAge < 13 && this.clientIsRetired === false || this.clientHasChildren === 'Yes' && this.clientIDChildThreeAge < 13 && this.clientIsRetired === false) {
      this.showChildrenSection = true;

    } else {
      this.showChildrenSection = false;

    }

    // Declare the auto save logic
    /*let commonAutoSaveFunction = () =>{
      console.log("AUTO SAVE");
      this.saveAllFlags();
      this.qController.submitForm(this, this.model, this.clientData.clientId, 'fulltax', true, null, true);
    }*/

    // Does the questionnaire exist in DB?
    let isQuestionnaireInDB: boolean = false;
    this.qController.advisorService.getClientQuestionnaireAsyncV2(this.clientData.clientId, 'fulltax', 1).then(
      async response => {

        if(response.status == 204){
          console.log('top','No questionnare in DB');
          isQuestionnaireInDB = false;
          this.isLoading = false;
          // Get client filing status before flags validations
          let filinStatusResponse = await this.qController.advisorService.getClientFilingStatus(this.clientData.clientId);
          this.extendedFilingStatus = filinStatusResponse.filingStatus;

          this.completeBaseFilingStatusCase();
          if(this.model) this.qController.submitForm(this, this.model, this.clientData.clientId, 'fulltax', true, null, true);
        }else {
          console.log('Questionnare found in DB');
          this.isLoading = false;
          isQuestionnaireInDB = true;
          this.model.populateWithArrayOfObjects(response.answers);
          let selectedYearFileResponse = null;
          this.auxModel = {
            ...(this.auxModel ? { ...this.auxModel } : {}),
            ...this.model,
          };
          await this.qController.loadExtractionsResults(this.clientData.clientId, 'fulltax', '1').then(result => {
            this.extractionResults = result;

            //Read and set the file validation status for the currently selected year
            selectedYearFileResponse = result['clientIDFulltaxPolicy' + this.model.clientIDTaxYear];
            this.documentIsValid = Boolean(selectedYearFileResponse != undefined && selectedYearFileResponse == 'COMPLETE');

            if(!Boolean(this.model.clientIDFillingStatus)){
              this.completeBaseFilingStatusCase();
            }
          });
          // Get client filing status before flags validations
          let filinStatusResponse = await this.qController.advisorService.getClientFilingStatus(this.clientData.clientId);
          this.extendedFilingStatus = filinStatusResponse.filingStatus;

          console.log('getListOfActiveFlags - init');
          this.listOfActiveValidationFlags = this.getListOfActiveFlags();

          //Display review modals only if you are an advisor
          if(this.isAdvisor){
            // Open the Review flags modal if there is any pending flag and status isn’t under review or invalid
            if(!(selectedYearFileResponse == 'UNDER_REVIEW' || selectedYearFileResponse == 'INVALID') && this.listOfActiveValidationFlags.length > 0 ){
              this.openNoticeValidationsDocumentReview();
            } // If there is a INVALID status at loading, display the needs review modal.
            if(selectedYearFileResponse == 'INVALID'){
              this.openNoticeNeedsReview();
            }
            if(selectedYearFileResponse == 'UNDER_REVIEW'){
              this.handleOnlySchBFlagsAreActive()
            }
          }
        }

        // Don't init the autosave routine
        //this.qController.initAutoSave2(commonAutoSaveFunction);
        this.updateTabs();
        let lastLoadedTab = this.tabRef._tabs.toArray()[this.tabRef.selectedIndex];
        let lasLoadedTabSection = TAX_SECTIONS_CATALOG.find(section => section.name === lastLoadedTab.textLabel);
        console.log('LAST LOADED: ', lasLoadedTabSection);
        let tabId: string = (lasLoadedTabSection != undefined) ? lasLoadedTabSection.id : 'extractions';
        console.log('TAB ID: ', tabId);
        if (this.model.clientIDTaxYear) {
          this.hasInitialYear = true;
        }
        setTimeout(()=> {
          this.currentTabQuestions = this.generateTabQuestionsContent(tabId);
          console.log('CURRENT TAB QUESTIONS: ', this.currentTabQuestions);
        },600)
      },
      async error => {
        console.log('Hay error!');
        // Don't init the autosave routine
        //this.qController.initAutoSave2(commonAutoSaveFunction);
      });


    //Is the first time here? Show modal
    /*this.isFirstTimeHere = JSON.parse(sessionStorage.getItem('isFirstTime'));
    if (this.isFirstTimeHere === 'fulltax') {
      //Make a forced autosave to create a fresh questionnaire where the extractions can live.
      //if(this.model) this.qController.submitForm(this, this.model, this.clientData.clientId, 'fulltax', true, null, true);
      // Open first time dialog
      this.dialog.open(DialogFirstTime, {
        panelClass: 'modal-dialog-questionnaire',
        height: '250px',
        width: '500px',
        data: {hasUploader: true}
      });
    }*/

    //Load extraction results for file uploaders components
    /* this.qController.loadExtractionsResults(this.clientData.clientId, 'fulltax', '1').then(result => {
      this.extractionResults = result;
    }); */

    this.policyUploaderOptions2019 = new FileUploaderOptions(
      {
        fileID: 'clientIDFulltaxPolicy2019',
        fileVal: this.model.clientIDFulltaxPolicy2019,
        uploader: this.qController.fileUploaderFactoryAutoUpload(this.window, this.clientData.clientId, 'fulltax'),
        documentReadableName: 'tax return',
        blockPasswordProtectedPDF: true,
        extraQueryParams: {year: '2019'},
        actionsAfterResponse: {
          validatedVoid : ()=>{ this.openNoticeNeedsReview(); this.getCompanyCredits(); },
          validatedValid: ()=>{ this.onUploaderValidResponse(this.policyUploaderOptions2019.componentRef) },
          uploadedPasswordProtected: ()=>{ this.openModalInputPassword('2019'); this.getCompanyCredits(); }
        }

      }
    );

    this.policyUploaderOptions2020 = new FileUploaderOptions(
      {
        fileID: 'clientIDFulltaxPolicy2020',
        fileVal: this.model.clientIDFulltaxPolicy2020,
        uploader: this.qController.fileUploaderFactoryAutoUpload(this.window, this.clientData.clientId, 'fulltax'),
        documentReadableName: 'tax return',
        blockPasswordProtectedPDF: true,
        extraQueryParams: {year: '2020'},
        actionsAfterResponse: {
          validatedVoid : ()=>{ this.openNoticeNeedsReview(); this.getCompanyCredits();},
          validatedValid: ()=>{ this.onUploaderValidResponse(this.policyUploaderOptions2020.componentRef) },
          uploadedPasswordProtected: ()=>{ this.openModalInputPassword('2020'); this.getCompanyCredits(); }
        }
      }
    );

    this.policyUploaderOptions2021 = new FileUploaderOptions(
      {
        fileID: 'clientIDFulltaxPolicy2021',
        fileVal: this.model.clientIDFulltaxPolicy2021,
        uploader: this.qController.fileUploaderFactoryAutoUpload(this.window, this.clientData.clientId, 'fulltax'),
        documentReadableName: 'tax return',
        blockPasswordProtectedPDF: true,
        extraQueryParams: {year: '2021'},
        actionsAfterResponse: {
          validatedVoid : ()=>{ this.openNoticeNeedsReview(); this.getCompanyCredits(); },
          validatedValid: ()=>{ this.onUploaderValidResponse(this.policyUploaderOptions2021.componentRef) },
          uploadedPasswordProtected: ()=>{ this.openModalInputPassword('2021'); this.getCompanyCredits(); }
        }
      }
    );

    this.policyUploaderOptions2022 = new FileUploaderOptions(
      {
        fileID: 'clientIDFulltaxPolicy2022',
        fileVal: this.model.clientIDFulltaxPolicy2022,
        uploader: this.qController.fileUploaderFactoryAutoUpload(this.window, this.clientData.clientId, 'fulltax'),
        documentReadableName: 'tax return',
        blockPasswordProtectedPDF: true,
        extraQueryParams: {year: '2022'},
        actionsAfterResponse: {
          validatedVoid : ()=>{ this.openNoticeNeedsReview(); this.getCompanyCredits(); },
          validatedValid: ()=>{ this.onUploaderValidResponse(this.policyUploaderOptions2022.componentRef); this.getCompanyCredits(); },
          uploadedPasswordProtected: ()=>{ this.openModalInputPassword('2022') },
          validating: ()=>{this.haveFilePDF = true; console.log(this.haveFilePDF, 'Sí tiene archivo 🐰'); this.getCompanyCredits();}
        }
      }
    );

    this.policyUploaderOptions2023 = new FileUploaderOptions(
      {
        fileID: 'clientIDFulltaxPolicy2023',
        fileVal: this.model.clientIDFulltaxPolicy2023,
        uploader: this.qController.fileUploaderFactoryAutoUpload(this.window, this.clientData.clientId, 'fulltax'),
        documentReadableName: 'tax return',
        blockPasswordProtectedPDF: true,
        extraQueryParams: {year: '2023'},
        actionsAfterResponse: {
          validatedVoid : ()=>{ this.openNoticeNeedsReview(); this.getCompanyCredits(); },
          validatedValid: ()=>{ this.onUploaderValidResponse(this.policyUploaderOptions2023.componentRef); this.getCompanyCredits(); },
          uploadedPasswordProtected: ()=>{ this.openModalInputPassword('2023') },
          validating: ()=>{this.haveFilePDF = true; console.log(this.haveFilePDF, 'Sí tiene archivo 🐰'); this.getCompanyCredits();}
        }
      }
    );

    //Get context, clientHasAnswered?
    let clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));
    this.clientHasAnsweredQuestionnaire = clientHasAnswered.ansFulltax;

    //Load questionnarie data, if clientHasAnswered
    //this.qController.loadQuestionnaireData(this.clientData.clientId, 'fulltax', this.model, this.clientHasAnsweredQuestionnaire);

    //By default the client has the input policy by file
    if (!this.isAdvisor) this.model.clientIDWantsToInputTaxData = 'No';

    //Debug

    this.bulletColor = Area.findAreaById("fulltax").colorHex;
    this.auxModel = {...this.model};


    console.log('Fields METADATA: ', this.newTaxFieldsMetadata, this.auxModel.clientIDTaxYear);
    console.log('Fields METAAAAAAAA: ', this.newTaxFieldsMetadata, this.auxModel);
    // this.currentTabQuestions = this.generateTabQuestionsContent('extractions');


  }

  public tabs: any;
  public tabSubSections: any;
  public finalFormInputs: any;
  public dividers: any = {};
  public tabsTitles: any = {
    extractions: `Tax Return manual input`,
    income: 'Income',
    charityAndGifts: 'Charity and gifts',
    retirement: 'Retirement',
    tuition: 'Tuition',
    lending: 'Lending',
    other: 'Other',
    advisor: 'Advisor section',
    employer: 'Employer'
  };
  public readyToUpload = false;

  handleCreditBoxResponse(isReady: any) {
    this.readyToUpload = isReady;

  }

    async getCompanyCredits() {
      try {
        const ans2: any = await this.advisorService.getCompanyCreditsV2(this.companyId, 'fulltax');
        const fulltax = ans2.fulltax;
        this.creditsInformation = {};
        this.companyType = fulltax.uploadModelType;
        this.creditsInformation['hasCredits'] = fulltax.remaining > 0;
        this.creditsInformation['creditsAvailable'] = fulltax.remaining;
        this.loadingCredits = false;
        if (this.companyType === 'legacy' || this.companyType === 'adoption') {
          this.readyToUpload = true;
        }
      } catch(e) {

      }
    }



    //Open review extractions dialog
    async openReviewExtractionsDialog(modelRef: string, description?: string){
      //Detect if there is a fileUnderReview status in any uploader

      if(this.isModalUniqueAddOrDelete('ReviewExtractionsDialogComponent') && this.listOfActiveValidationFlags.length > 0){

        this.isModalUniqueAddOrDelete('ReviewExtractionsDialogComponent', UPDATE);

        //let imageUrl =  URL.createObjectURL(image);
        let hasScheduleB = false;
          for (let i = 0; i < this.listOfActiveValidationFlags.length; i++) {
            if (this.listOfActiveValidationFlags[i].keyFlag.includes('scheduleB_1') || this.listOfActiveValidationFlags[i].keyFlag.includes('scheduleB_5')) {
              hasScheduleB = true;
              break;
            }
          }

          console.log('antes de abrirrr',  JSON.parse(JSON.stringify(this.model)));

        const dialogRef = this.dialog.open(ReviewExtractionsDialogComponent, {
          panelClass: 'modal-dialog-review-extractions',
          height: '710px',
          // width: '650px',
          maxWidth: '90vw',
          maxHeight: '710px',
          disableClose: true,
          data: {
            hasScheduleB,
            modelRef: this.model,
            flagKey: modelRef,
            listOfValidationFlagsRef: this.listOfActiveValidationFlags,
            yearSelected: Number(this.model.clientIDTaxYear)
          }
        });

        dialogRef.afterClosed().subscribe(result => {

          this.isModalUniqueAddOrDelete('ReviewExtractionsDialogComponent', UPDATE);
          //Set the internal flag (metadata) to validated
          this.model.allFlagsValidatedByUser = true;
          //
          console.log('Guardando antes de enviar');
          console.log({... this.model});
          //Submit form
          this.qController.submitForm(this, this.model, this.clientData.clientId, 'fulltax', true, null, true);
        });
      }
    }

  ngAfterViewChecked(): void {

    if(this.model.clientIDFillingStatus !== ''){
      this.filingStatusRequired = false;
    }

  }

  determineTabGroupProgress(){
    if (this.tabRef != undefined){
      this.ntabs = Number(this.tabRef._tabs.length);
      this.progress = 100 / this.ntabs;
    }
  }

  redirectOnManualValidation() {
    this.submit(true, true);
  }

  openNoticeValidationsDocumentReview(){
    if(this.isModalUniqueAddOrDelete('openNoticeValidationsDocumentReview')){

      this.isModalUniqueAddOrDelete('openNoticeValidationsDocumentReview', UPDATE);
      let fileName = this['policyUploaderOptions'+this.model.clientIDTaxYear].fileID;

      const dialogRef = this.dialog.open(GeneralDialogV2Component, {
        disableClose: true,
        panelClass: 'modal-dialog-no-padding',
        width: '55vw',
        data: {
          success: true,
          title: `Your file is valid`,
          subtitle: `For 100% accuracy we detected ${this.listOfActiveValidationFlags.length} items for you to review.`,
          header: {
            buttonLabel: 'Approve Now',
            headerAction: () => {
              dialogRef.close();
              this.model.clientIDWantsToInputTaxData = 'No';
              this.openReviewExtractionsDialog(this.listOfActiveValidationFlags[0].keyFlag);
            }
          },
          footer: {
            disclaimer: `<p class="text-center fz-24 fp-font-bold">To have our team verify the extractions on your behalf select "Notify Me When Ready". <br /><br />
            A confirmation email will be sent to you when the process is complete.</p>`,
            buttonLabel: 'Notify Me When Ready',
            footerAction: () => {
              dialogRef.close();
              this.qController.advisorService.retainAreaFile(this.clientData.clientId, 'fulltax', 1, fileName).then(response => {
                console.log("Retain file.", response);
                this.snackBarSuccess.open("We have received the return and the data will be available shortly.", "OK", {
                  duration: 3000,
                  panelClass: 'success-snackbar'
                });
                this.router.navigate(["/hub"]);
            })
            }
          },
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.isModalUniqueAddOrDelete('openNoticeValidationsDocumentReview', UPDATE);
      })
    }

  }

  openModalInputPassword(year: string){
    if(this.isModalUniqueAddOrDelete('openModalInputPassword')){

      this.isModalUniqueAddOrDelete('openModalInputPassword', UPDATE);

      const dialogRef = this.dialog.open(DialogGenericNoticeComponent, {
        disableClose: true,
        panelClass: 'modal-dialog-review-extractions',
        width: '55vw',
        data: {
          title: 'Password required',
          buttonsContainerClass: 'align-left',
          body:
            `<strong>Please input your file's password.</strong><br>`,
          inputFields: [
            {type: 'password', key: 'password', text: "File's password",}
          ],
          actionButtons: [
            {
              text: 'Cancel',
              class: 'button-secondary-on-white',
              action: () => {
                dialogRef.componentInstance.data.outputFieldsData['password'] = '';
                dialogRef.close();
              }
            },
            {
              text: "Submit",
              class: 'button-primary',
              action: () => {
                // Find the uploader url
                let uploaderRef: FileUploaderOptions;
                uploaderRef = this['policyUploaderOptions' + year];

                // Append the password to the url
                let uploaderUrl = UrlParamEditor(uploaderRef.uploader.currentUrl, 'password', dialogRef.componentInstance.data.outputFieldsData['password']);
                uploaderUrl = UrlParamEditor(uploaderUrl, 'incorrectPasswordOption', 'true');

                // Update the dialog view
                dialogRef.componentInstance.data.title = `We are processing your data please wait.`;
                dialogRef.componentInstance.data.body = '';
                dialogRef.componentInstance.data.inputFields = undefined;
                dialogRef.componentInstance.data.actionButtons = [];

                //Wait for a response

                this.qController.advisorService.genericPost(uploaderUrl).then(response =>{
                  if(response.message && response.message === "policy file for Fulltax uploaded and saved with success"){
                   console.log('File sended');
                   this['policyUploaderOptions' + year].status = UPLOADER_STATUS.FILE_VALIDATING;
                   this['policyUploaderOptions' + year].componentRef.sectionB = 'Please wait';
                  }
                  dialogRef.close();
                })

              }
            }
          ]
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.isModalUniqueAddOrDelete('openModalInputPassword', UPDATE);
      })

    }
  }

  openModalLoadinError(){
    if(this.isModalUniqueAddOrDelete('openModalLoadinError')){

      this.isModalUniqueAddOrDelete('openModalLoadinError', UPDATE);

      const dialogRef = this.dialog.open(DialogGenericNoticeComponent, {
        disableClose: true,
        panelClass: 'modal-dialog-review-extractions',
        width: '55vw',
        data: {
          title: 'Warning',
          buttonsContainerClass: 'align-left',
          body:
            `<strong>The system couldn't retrieve the expected data.</strong><br><br>
            Please try again later
            <br><br>
            `,

          inputFields: [],
          actionButtons: [
            {
              text: 'Ok',
              class: 'button-secondary-on-white',
              action: () => {
                dialogRef.close();
              }
            },
          ]
        }
      });

      dialogRef.afterClosed().subscribe(result => {
          this.isModalUniqueAddOrDelete('openModalLoadinError', UPDATE);
          this.qController.deleteAllStoragedQuestionnareData();
          this.router.navigate(['/hub']);
      })

    }
  }

  async openNoticeNeedsReview(){
    if(this.isModalUniqueAddOrDelete('openNoticeNeedsReview')){
      let correctYear = 'Yes';
      let realYear = '';

      if (!this.isProduction) {
        const ansTax: any = await this.qController.advisorService.getClientQuestionnaireAsyncV2(this.clientData.clientId, 'fulltax', 1);

        if (ansTax && ansTax.answers && !this.isProduction) {
          for (const answer of ansTax.answers) {
            if (answer && answer.name === 'correctYear') {
                correctYear = answer.answer;
            }
            if (answer && correctYear === 'No' && answer.name === 'realYear') {
              realYear = answer.answer;
              break;
            }
          }
        }
      }

      if (correctYear === 'No' && !this.isProduction) {
        let uploaderUrl = UrlParamEditor(this[`policyUploaderOptions${this.model.clientIDTaxYear}`].uploader.currentUrl, 'correctYear', correctYear);
        uploaderUrl = UrlParamEditor(uploaderUrl, 'realYear', realYear);
        await this.qController.advisorService.genericPost(uploaderUrl);
        const auxYear = this.model.clientIDTaxYear;
        this.model.clientIDTaxYear = realYear;
        this.loadExtractionsFromSelectedYear({ setYes: true });
        this.snackBarSuccess.open(`We have detected that you uploaded a tax return for the year ${realYear} in ${auxYear}. We have automatically made the change to the correct year.`, "OK", {
          duration: 3000,
          panelClass: 'success-snackbar'
        });
      } else {
        let auxInterval = null;
      this.isModalUniqueAddOrDelete('openNoticeNeedsReview', UPDATE);

      let fileName = this['policyUploaderOptions'+this.model.clientIDTaxYear].fileID;

      this.qController.advisorService.retainAreaFile(this.clientData.clientId, 'fulltax', 1, fileName, 'manual').then(response => {
        console.log("Retain file.", response);
        this.snackBarSuccess.open("We have received the return and the data will be available shortly.", "OK", {
          duration: 3000,
          panelClass: 'success-snackbar'
        });
    })

    let dialogRef;

    auxInterval = setInterval(() => {
      dialogRef.close();
      this.redirectOnManualValidation();
    }, 10000);

    dialogRef = this.dialog.open(GeneralDialogV2Component, {
      disableClose: true,
      panelClass: 'modal-dialog-review-extractions',
      width: '55vw',
      data: {
        error: true,
        title: 'File reading failed',
        header: {
          disclaimer: `<p class="fz-24 font-bold text-center color-white">This could be due to the quality of the physicial document, or inconsistencies in the numbers extracted. <br><br> Our team will make the extractions accessible in about one business hour (CT). A confirmation email will be sent to you when the process is complete</p>`,
        },
        footer: {
          buttonLabel: 'Notify Me When Ready',
          footerAction: () => {
            dialogRef.close();
            this.redirectOnManualValidation();
            clearInterval(auxInterval);
          }
        },
        // body:
        //   `<strong>Based on our initial reading of your document, our proprietary validation algorithm is raising a flag.<br>
        //   It may be the quality of the document, or inconsistencies in the numbers extracted</strong><br><br>
        //   Our team will make the extractions accessible in under one business hour (CT). A confirmation email
        //   will be sent to you when the process is complete.<br>
        //   If you are receiving this notice after business hours the extractions will be accessible by the morning of the next business day.
        //   <br><br>
        //   `,
        actionButtons: [
          // {
          //   text: "Dismiss",
          //   class: 'button-primary',
          //   action: () => {
          //     dialogRef.close();
          //     this.redirectOnManualValidation();
          //     clearInterval(auxInterval);
          //   }
          // },

          /* {
            text: "Approve Now",
            class: 'button-primary mat-elevation-z4',
            action: () => {
              dialogRef.close();
              this.model.clientIDWantsToInputTaxData = 'Yes';
              this.openReviewExtractionsDialog(this.listOfActiveValidationFlags[0].keyFlag);
            }
          } */
        ]

      }
    });

      dialogRef.afterClosed().subscribe(response => {
        this.isModalUniqueAddOrDelete('openNoticeNeedsReview', UPDATE);
        clearInterval(auxInterval);
      })
    }
   }
  }

  openAllValidModal(){

    if(this.isModalUniqueAddOrDelete('openAllValidModal')){

      this.isModalUniqueAddOrDelete('openAllValidModal', UPDATE);

      this.allValidModalRef = this.dialog.open(GeneralDialogV2Component, {
        disableClose: true,
        panelClass: 'modal-dialog-no-padding',
        width: '55vw',
        data: {
          success: true,
          title: `Your file is valid`,
          subtitle: `Reading was 100% accurate. We detected no items to review.`,
          header: {
            buttonLabel: 'Continue',
            headerAction: () => {
              this.allValidModalRef.close();
              this.isModalUniqueAddOrDelete('openAllValidModal', UPDATE);
            }
          }
        }
      });
    }

  }

  openFullPageLoader(){
    if(this.isModalUniqueAddOrDelete('openFullPageLoader')){

      this.isModalUniqueAddOrDelete('openFullPageLoader', UPDATE);
      this.fullPageSpinnerModal = this.dialog.open(FullPageSpinnerComponent, {
        disableClose: true,
        width: '170px',
        height: '170px',
      });
    }

    this.fullPageSpinnerModal.afterClosed().subscribe(response => {
      this.isModalUniqueAddOrDelete('openFullPageLoader', UPDATE);
    })

  }

  openNoticeSubmitWarning(message: string){
    if(this.isModalUniqueAddOrDelete('openNoticeSubmitWarning')){

      this.isModalUniqueAddOrDelete('openNoticeSubmitWarning', UPDATE);

    const dialogRef = this.dialog.open(DialogGenericNoticeComponent, {
      disableClose: true,
      panelClass: 'modal-dialog-review-extractions',
      width: '500px',
      data: {
        title: 'Notice',
        body:
          `<strong>To submit, please review the next:</strong><br><br>
            ${message}
          `,
        actionButtons: [
          {
            text: "Ok",
            class: 'button-blue',
            action: () => {
              dialogRef.close();
            }
          }
        ]
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      this.isModalUniqueAddOrDelete('openNoticeSubmitWarning', UPDATE);
    })
  }
  }

  async ngAfterViewInit() {

    let clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'))
    //Load general questionnaire
    if (clientHasAnswered.ansGeneral) {
      this.currentClientService.data.generalQuestionnaire.subscribe((data) => {
        console.log('DATA: ', data);
        if (data?.personalInformation) {
          const answers = data?.personalInformation?.data;
          answers.forEach(({ name, answer }) => {
            this.generalData[name] = answer === 'undefined' || !answer ? '' : answer
          })
        }
        if (data?.family) {
          const assetsData = data?.family?.data;
          const business = assetsData.business;
          const retirement = assetsData.retirement;
          this.clientHasSCorp = business?.some(({ subtype }) => subtype === 'S Corp')
          this.generalData.clientIDHasAccessTo457Plan = retirement.some(({ subtype }) => subtype === '457 Plan') ? 'Yes' : 'No';
          this.generalData.clientIDHasAccessTo401KPlan = retirement.some(({ subtype }) => subtype === '401(k)') ? 'Yes' : 'No';
          this.model.clientIDHasTraditionalOrRolloverIRA = retirement.some(({ ownerData, subtype }) =>
              subtype ===  'Traditional IRA' ||
              subtype == 'Roth IRA' ||
              subtype == '401(k)' ||
              subtype == '457 Plan'
            ) ? 'Yes' : 'No';
        }
      })

      this.model.clientIDHasAccessTo457Plan = Boolean(this.model.clientIDHasAccessTo457Plan) ? this.model.clientIDHasAccessTo457Plan : this.generalData?.clientIDHasAccessTo457Plan;
      this.model.clientIDHasAccessTo401KPlan = Boolean(this.model.clientIDHasAccessTo401KPlan) ? this.model.clientIDHasAccessTo401KPlan : this.generalData?.clientIDHasAccessTo401KPlan;
    }

    if (this.occupation.includes(this.generalData.clientIDOccupation.toLowerCase())) {
      this.removeIRAq = true;
    }

    this.clientIDNetWorth = this.generalData.clientIDNetWorth;
    this.clientIDSpouseNetWorth = this.generalData.clientIDSpouseNetWorth
    this.joinIncome = this.generalData.clientIDNetWorth + this.generalData.clientIDSpouseNetWorth;
    this.clientLivingState = this.generalData.clientIDLivingState;

    //this.indexTab = this.tabRef.selectedIndex;
    this.updateTabs()
    setTimeout(()=>{
      this.currentTabQuestions = this.generateTabQuestionsContent('extractions');
    },600)
  }

  onSaveForLater() {
    this.submit(true);
  }

  showUploader(prop: string) {
    this.model[prop] = '';
    this.uploader.clearQueue();
  }

  onAnyUploaderReset($event: string) {
    this.model[$event] = '';
    this.extractionResults[$event] = '';
    this.model.allFlagsValidatedByUser = false;
     //If the flags hasn't be validated then validate.

  }

  submit(saveForLater: boolean = false, isManualValidation: boolean = false) {
    console.log('Guardando:::::');

    if (saveForLater == false) {
      if(this.model.clientIDFillingStatus === '' || this.model.clientIDFillingStatus === undefined){
        this.qController.validateRequiredQuestions(this);
        this.model.clientIDFillingStatus == '' ? this.filingStatusRequired = true : this.filingStatusRequired = false;
        return;
      }
      this.model.progressBar = 100;
      this.model.progressCompleted = true;
      this.model.progressTab = 0;
    }

    this.saveAllFlags();



/*
    let beforeModifications = new taxfull_questions();
    Object.assign(beforeModifications, this.model);

    for (let value of Object.keys(this.model)) {
      if (value.endsWith('_2')) {
        if (this.model[value.substring(0, value.length - 2)] == 'No' && this.model[value] == 'Yes') {
          this.model[value.substring(0, value.length - 2)] = 'Yes'
        }
      }

      if ((value.startsWith('scheduleb_1') || value.startsWith('scheduleb_5')) && value.endsWith('_text')){
        let questionLower = '';
        questionLower = this.model[value]
        this.model[value]=questionLower.toUpperCase();
      }
    }

    //Deprecated clientIDWantsToInputTaxData. All clients will have clientIDWantsToInputTaxData='No' Jan 23, 2024
    this.model.clientIDWantsToInputTaxData = 'No';

    this.model.clientIDTypeOfAnalysis === 'Instant tax analysis' ? this.model.clientIDUploadTaxReturnOnly = 'Yes' : this.model.clientIDUploadTaxReturnOnly = 'No';

    if(this.model.clientIDIncomeExpectationsFollowingYear === 'No'){
      this.model.clientID2018Income = this.model.taxableIncome;
    }

    if (this.model.clientIDHasAccessToWorkplaceRetirementPlan == 'Yes' && this.model.clientIDHasAccessToWorkplaceRetirementPlan_2 == 'Yes') {
      this.model.clientIDHasOrSpouseAccessToRoth401k = 'Me'
    }

    if (this.model.clientIDHasAccessToWorkplaceRetirementPlan == 'Yes' && this.model.clientIDHasAccessToWorkplaceRetirementPlan_2 == 'No') {
      this.model.clientIDHasOrSpouseAccessToRoth401k = 'Me'
    }

    if (this.model.clientIDHasAccessToWorkplaceRetirementPlan == 'No' && this.model.clientIDHasAccessToWorkplaceRetirementPlan_2 == 'Yes') {
      this.model.clientIDHasOrSpouseAccessToRoth401k = 'Spouse'
    }

    if (this.model.clientIDHasAccessToWorkplaceRetirementPlan == 'No' && this.model.clientIDHasAccessToWorkplaceRetirementPlan_2 == 'No') {
      this.model.clientIDHasOrSpouseAccessToRoth401k = 'No'
    }

    if (this.model.clientIDHouseOrFarmToCharity === 'House or farm') this.model.clientIDHouseOrFarmToCharity = 'Yes';
    if (this.model.clientIDHouseOrFarmToCharity === 'Other property') this.model.clientIDOtherThanHouseOrFarmToCharity = 'Yes';
    if (this.model.clientIDHouseOrFarmToCharity === 'None') this.model.clientIDHouseOrFarmToCharity = 'No';

    console.log('Modelo justo antes de guardar: ', this.model);
    */

    validateAndSubmit(
      [
        /*{validationPremise: () => {return true}, onFalse: ()=>{}}
        uploadersValidator(this, [this.policyUploaderOptions2019, this.policyUploaderOptions2020, this.policyUploaderOptions2021, this.policyUploaderOptions2022, this.policyUploaderOptions2023]),
        */
       //The validation is now on the action of the button isSubmitDisabled()
        {validationPremise: () => {return true}, onFalse: ()=>{}}
        ],
      () => {
        let hasManualInput = this.model.clientIDWantsToInputTaxData === 'Yes';
        let afterSent = () => {
          // Set uploader status to Submitted if current estatis is UNDER_REVIEW
          let currentYear = this.model.clientIDTaxYear;


          if( saveForLater == false && this.extractionResults['clientIDFulltaxPolicy' + currentYear] === 'UNDER_REVIEW'){
            this.qController.advisorService.setTaxUploaderStatusToSubmitted(this.clientData.clientId, 'fulltax', '', '1', currentYear).then(response => {

            });
          }

          // Send questionnaire data to save without internall modifications

          //this.qController.submitForm(this, beforeModifications, this.clientData.clientId, 'fulltax', true, null, true, hasManualInput, false, this.auxModel);
        }

        // Send questionnaire to proccess with the internall modifications
        //this.qController.submitForm(this, this.model, this.clientData.clientId, 'fulltax', saveForLater, afterSent, false, hasManualInput, isManualValidation, this.auxModel);
        this.qController.submitForm(this, this.model, this.clientData.clientId, 'fulltax', saveForLater, afterSent, false, hasManualInput, isManualValidation, this.auxModel);
      },
      saveForLater
    )

  }


  nextTab(index: number) {
    this.indexTab = index + 1;
    this.model.progressTab = index + 1;
    this.targetEl.nativeElement.scrollIntoView();
  }

  previousTab(index: number) {
    this.indexTab = index - 1;
    this.model.progressTab = index - 1;
    this.targetEl.nativeElement.scrollIntoView();
  }

  public tabChangedSelect(tabChangeEvent: MatTabChangeEvent): void {
    this.currentTabQuestions = [];

    console.log('MatTabChangeEvent', tabChangeEvent);

    this.model.progressTab = tabChangeEvent.index;
    this.indexTab = tabChangeEvent.index;
    if (this.model.progressCompleted == true) {
      this.model.progressBar = 100;
    } else {
      this.model.progressBar = Math.round(tabChangeEvent.index * this.progress);
    }

    let tabByTabName = this.tabsCatalog.find(tab => tab.name === tabChangeEvent.tab.textLabel);
    console.log(tabByTabName);
    setTimeout(()=>{
      this.currentTabQuestions = this.generateTabQuestionsContent(tabByTabName.id);
    },600)

    // Save tab progess due autosave is inactive

    this.isFirstTabLoad ? this.isFirstTabLoad = false : this.autosaveNow(null);

  }

  public tabChangedFocus(tabChangeEvent: MatTabChangeEvent): void {
    this.model.progressTab = tabChangeEvent.index;
  }

  blockManualInput() {
    this.isManualInputEnabled = false;
  }

  handleProcessingFile(status){
    this.isProcessingFile = status
  }

  onUploaderValidResponse(uploaderInstance: FileUploaderComponentComponent){
    //Load the extractions data
    this.qController.loadExtractionsDataToModel(this.model, taxfull_questions,  this.clientData.clientId, 'fulltax').then(response => {
      this.isManualInputEnabled = true;
      ReviewExtractionsDialogComponent.deleteBufferedImages();
      console.log('this.model', this.model);
      console.log('getListOfActiveFlags - onUploaderValidResponse');
      this.listOfActiveValidationFlags = this.getListOfActiveFlags();
      let lastLoadedTab = this.tabRef._tabs.toArray()[this.tabRef.selectedIndex];
      let lasLoadedTabSection = TAX_SECTIONS_CATALOG.find(section => section.name === lastLoadedTab.textLabel);
      this.currentTabQuestions = this.generateTabQuestionsContent(lasLoadedTabSection.id);
      if(this.listOfActiveValidationFlags.length > 0) {
        this.openNoticeValidationsDocumentReview();
      }else{
        if(uploaderInstance.options.isComponentAlive && uploaderInstance.progressEndAndValid && uploaderInstance.lastNotificationIsFromSocket){
          this.openAllValidModal();
        }

      }
    });
  }

  public isInternallChange: boolean = false;

  onManualUserSelectionChange(event) {
  }

  ngOnDestroy() {
    console.log('on destroy. Is allValidModalRef != undefined: ', String(Boolean(this.allValidModalRef != undefined)))
    // sessionStorage.removeItem('isFirstTime');
    this.qController.stopAutoSave();
    if(this.allValidModalRef != undefined) {
      this.allValidModalRef.close();
    }
  }

  resetQuestion(event, el) {

    event.preventDefault();
    const target = el.name;

    if (this.model[target] && this.model[target] === el.value) {
      el.checked = false;
      this.model[target] = '';
    } else {
      this.model[target] = el.value
      el.checked = true;
    }
  }

  toggleSearch() {
    this.toggleSearchBar = !this.toggleSearchBar;
    this.debug();
  }

  generatePDFQuestionnaire() {

    const options: {
      headers?: HttpHeaders,
      observe?: 'body',
      params?: HttpParams,
      reportProgress?: boolean,
      responseType: 'blob',
      withCredentials?: boolean
    } = {
      headers: null,
      params: null,
      responseType: 'blob'
    };

      let fillingStatus = this.model.clientIDFillingStatus;
      if(fillingStatus == 'Other'){
        fillingStatus = 'Other'
      }

      this.http.get(`${this.pdfUrl}${'fulltax/?options='+fillingStatus}`, options)
      .subscribe(blob => {
        saveAs(blob, 'tax-questionnaire.pdf');
      });
  }

  getListOfActiveFlags(): FullValidationFlag[]{

    let listOfFlags: FullValidationFlag[]  = [] ;

    let tempFlagsMetadata = taxfull_questions.getFlagKeys();

    console.log('getListOfActiveFlags. Inner flags -> ', tempFlagsMetadata);

    //If user has activated general quest we remove living state validation
    if(this.clientData.hasGeneralQuest){
      tempFlagsMetadata = tempFlagsMetadata.filter(element => element !== 'livingState');
    }
    tempFlagsMetadata.map(flag => {

      let fullValidationFlag: FullValidationFlag = this.model.readFlag(flag);
      //If the flags hasn't be validated then validate.

      if(!this.model.allFlagsValidatedByUser){ this.flagApplyAditionalValidations(fullValidationFlag); }
      //
      if(fullValidationFlag.isActive){ listOfFlags.push(fullValidationFlag) }
    })

    if(this.isProduction){
      let flagB1 = listOfFlags.findIndex(flag => (flag.keyFlag == 'flag_scheduleb_1' ));
      if(flagB1 >= 0){
        listOfFlags.splice(flagB1, 1);
      }
      let flagB5 = listOfFlags.findIndex(flag => (flag.keyFlag == 'flag_scheduleb_5'));
      if(flagB5 >= 0){
        listOfFlags.splice(flagB5, 1);
      }
    }
    console.log('getListOfActiveFlags. response -> ', listOfFlags);

    return listOfFlags;
  }

  saveAllFlags(){
    console.log('saving flags: listOfActiveValidationFlags -> ', JSON.parse(JSON.stringify(this.listOfActiveValidationFlags)))
    this.listOfActiveValidationFlags.map(flag => {
      this.model.saveFlag(flag)
    });
  }

  isFlagActive(keyFlag:string): boolean{
    let flag = this.listOfActiveValidationFlags.find(flag => flag.keyFlag == keyFlag);
    return flag != undefined && flag.isActive;
  }

  /**
   * Extra validations that need to be executed on the front.
   */
  flagApplyAditionalValidations(flag: FullValidationFlag){
    //
    if(flag.url != 'url') return; // A reviewed flag will be different from 'url', don't review or change an already reviewd flag.
    switch(flag.keyFlag){
      case 'wages': //1. Ask for validation if, wages = 0 && employed = true;

        if(this.generalData.clientIDRetiredOrNotWorking == 'No' && this.model.wages == 0){

          flag.isActive = true;
          flag.url = this.model.clientIDTaxYear === '2020' ?'form1040_1_1.jpg' : 'form1040_1_1_val.jpg';
          this.model.saveFlag(flag);
        }
      break;
      case 'socialSecurityBenefits': //2. Ask for validation if, client age > 65 && socialSecurityBenfits = 0;
        if(this.clientAge > 65  && this.model.socialSecurityBenefits == 0){
          flag.isActive = true;
          flag.url = (this.model.isFormSR === 'Yes') ? 'form1040_1_3_val.jpg' : 'form1040_1_3.jpg';
          this.model.saveFlag(flag);
        }
      break;
      case 'iraDistribution': //3. Ask for validation if, client age > 72 && iraDistribution = 0;
        if(this.clientAge > 72  && this.model.iraDistribution == 0){
          flag.isActive = true;
          flag.url = (this.model.isFormSR === 'Yes') ? 'form1040_1_3_val.jpg' : 'form1040_1_3.jpg';
          this.model.saveFlag(flag);
        }
      break;
      //taxInterest
      case 'taxExemptInterest': //4. activar flagTaxExemptInterest  si: TaxExemptInterest(2a) == 0 && taxInterest(2b) > 0 && ((taxableIncome > 160k && unmarried) || (taxableIncome > 320k && (MFJ || widower)))
        if(this.model.taxExemptInterest == 0  && this.model.taxInterest > 0 &&
          ((this.model.taxableIncome > 160000 && this.clientData.isMarried=='Single') ||
           (this.model.taxableIncome > 320000 && (
              (this.model.clientIDFillingStatus=='Married Filing Jointly' && this.clientData.isMarried=='Married')
             || this.clientData.isMarried=='Widowed')))
          ){
          flag.isActive = true;
          flag.url = (this.model.isFormSR === 'Yes') ? 'form1040_1_3_val.jpg' : 'form1040_1_3.jpg';;
          this.model.saveFlag(flag);
        }
      break;
      /* 5.
      activate standarDeductionFromTable flag if:
        standar deductions from table != input standar deductions
        OR
        input summationsItemizedDeductions = input standardDeductions
      */
      case 'standardDeductions':

        let yearAsNumber: number = Number(this.model.clientIDTaxYear);

        if(!isNaN(yearAsNumber) && yearAsNumber >= 2022){
          break;
        }

        let standarDeductionFromTable: number =
          this.model.clientIDTaxYear == '2021' ?
            STANDAR_DEDUCTIONS_TABLE_2021[this.model.clientIDFillingStatus] :
          this.model.clientIDTaxYear == '2020' ?
            STANDAR_DEDUCTIONS_TABLE_2020[this.model.clientIDFillingStatus] :
          this.model.clientIDTaxYear == '2019' ?
            STANDAR_DEDUCTIONS_TABLE_2019[this.model.clientIDFillingStatus] :
          this.model.clientIDTaxYear == '2022' ?
            STANDAR_DEDUCTIONS_TABLE_2021[this.model.clientIDFillingStatus] :
          undefined;

        let flagImageUrl: string = '';
        switch (this.model.clientIDTaxYear) {
          case '2019':
            flagImageUrl = 'form1040_1_2.jpg';
            break;
          case '2020':
            flagImageUrl = this.model.isFormSR == 'Yes' ? 'form1040_2_1.jpg' : 'form1040_1_1_val.jpg';
            break;
          case '2021':
            flagImageUrl = this.model.isFormSR == 'Yes' ? 'cutfor12aSR.jpg' : 'cutfor12a_val.jpg';
            break;
          case '2022':
            flagImageUrl = this.model.isFormSR == 'Yes' ? 'form1040_2_1.jpg' : 'form1040_1_1_val.jpg';
            break;
        }


        //Debug line
        console.log(
          'flag review: standardDeductions:',
          {
            taxYear: CommaFormatedNumberToJSNumber(this.model.clientIDTaxYear),
            standarDeductionFromTable: standarDeductionFromTable,
            standardDeductions: CommaFormatedNumberToJSNumber(this.model.standardDeductions),
            summationsItemizedDeductions: CommaFormatedNumberToJSNumber(this.model.standardDeductions),
            selectedFlagImageUrl: flagImageUrl
          }
        )

        if(
          (standarDeductionFromTable != (CommaFormatedNumberToJSNumber(this.model.standardDeductions) + (this.clientAge > 65 ? STANDAR_DEDUCTIONS_AGE_DEDUCTIONS[this.model.clientIDFillingStatus] : 0) ) ||
          CommaFormatedNumberToJSNumber(this.model.summationsItemizedDeductions) == CommaFormatedNumberToJSNumber(this.model.standardDeductions)) &&
          !(this.model.clientIDTaxYear == '2021' && CommaFormatedNumberToJSNumber(this.model.standardDeductions) == 25100) //Deactivate the flag if 2021 standardDeductions == 25100, this overrides all previous conditions
          ){
          flag.isActive = true;
          flag.url = flagImageUrl;
          this.model.saveFlag(flag);
        }

      break;
    }

  }


  isAnyFlagActive(): boolean{
    return this.listOfActiveValidationFlags.find(flag => flag.isActive) != undefined;
  }

  isUploaderReady(year: number): boolean{
    if(this['policyUploaderOptions'+year] === undefined){
      throw(`The uploader policyUploaderOptions${year} doesn't exist`);
    }
    if(!this['policyUploaderOptions'+year].isComponentAlive){
      return true;
    }
    return ((this['policyUploaderOptions'+year].status == UPLOADER_STATUS.FILE_VALID) != (this['policyUploaderOptions'+year].status == UPLOADER_STATUS.INITIAL || this['policyUploaderOptions'+year].status == UPLOADER_STATUS.FILE_UNDER_REVIEW))
  }

  isSubmitDisabled(): boolean{
    return this.questionnaireSent || this.isAnyFlagActive() || !this.isUploaderReady(2021) || !this.isUploaderReady(2022) || !this.isUploaderReady(2023) || this.model.clientIDTaxYear === '2019' || this.model.clientIDTaxYear === '2020' || !this.model.clientIDTaxYear
  }

  isSaveForLaterDisabled(): boolean{
    return this.questionnaireSent || this.model.clientIDTaxYear === '2019' || this.model.clientIDTaxYear === '2020' || !this.isUploaderReady(2021) || !this.isUploaderReady(2022) || !this.isUploaderReady(2023) || !this.model.clientIDTaxYear //this.isProcessingFile
  }

  /**
   * Used to determine if any of the uploaders is in the requested status
   * @param status
   */
  isAnyUploaderInStatus(status: string): boolean{
    let statusFound: boolean = false;
    this.taxYearsAvaliable.map(year =>{
      if(this['policyUploaderOptions'+year] !== undefined){
        statusFound = (statusFound || this['policyUploaderOptions'+year].status == status)
      }
    })
    return statusFound;
  }

  debug(){
    console.log({
      allFlagsClear: this.isAnyFlagActive() ? 'No': 'Yes',
      isComponentAlive2019: this.policyUploaderOptions2019.isComponentAlive? 'Yes': 'No',
      status2019: this.policyUploaderOptions2019.status,
      isComponentAlive2020: this.policyUploaderOptions2020.isComponentAlive? 'Yes': 'No',
      status2020: this.policyUploaderOptions2020.status,
      isComponentAlive2021: this.policyUploaderOptions2021.isComponentAlive? 'Yes': 'No',
      status2021: this.policyUploaderOptions2021.status,
      isComponentAlive2022: this.policyUploaderOptions2022.isComponentAlive? 'Yes': 'No',
      status2022: this.policyUploaderOptions2022.status,
      isComponentAlive2023: this.policyUploaderOptions2023.isComponentAlive? 'Yes': 'No',
      status2023: this.policyUploaderOptions2023.status,
      policyUploaderIsReady2019: this.isUploaderReady(2019) ? 'Yes': 'No',
      policyUploaderIsReady2020: this.isUploaderReady(2020) ? 'Yes': 'No',
      policyUploaderIsReady2021: this.isUploaderReady(2021) ? 'Yes': 'No',
      policyUploaderIsReady2022: this.isUploaderReady(2022) ? 'Yes': 'No',
      policyUploaderIsReady2023: this.isUploaderReady(2023) ? 'Yes': 'No',
      /* policyUploaderOptions2019: this.policyUploaderOptions2019,
      policyUploaderOptions2020: this.policyUploaderOptions2020 */
    })
  }


  removeCommas(string:string) :number {
    return CommaFormatedNumberToJSNumber(string);
  }

  handleOnlySchBFlagsAreActive(){
    let currentTaxYear: string = this.model.clientIDTaxYear;
    if( this.listOfActiveValidationFlags.length <= 2 && (this.listOfActiveValidationFlags.some(flag => flag.keyFlag === 'scheduleB_1' || flag.keyFlag === 'scheduleB_5'))){
      this.openNoticeValidationsDocumentReview()
    }
  }

  autosaveNow($event){
    // Autosave only if there is no data waiting

    let currentTaxYear: string = this.model.clientIDTaxYear;
    let isUploaderAlive: boolean = Boolean (this['policyUploaderOptions' + currentTaxYear].isComponentAlive);
    let currentUploaderStatus: string = String(this['policyUploaderOptions' + currentTaxYear].status);



    // Save if uploader isn't alive or if is alive validate that the uploader status isn't validating and isn't progress
    if(!isUploaderAlive || (currentUploaderStatus != UPLOADER_STATUS.FILE_VALIDATING && currentUploaderStatus != UPLOADER_STATUS.FILE_PROGRESS)){
      this.qController.submitForm(this, this.model, this.clientData.clientId, 'fulltax', true, null, true, false, false, this.auxModel);
    }else{
      console.log("WARNING. Waiting for extractions. Autosave disabled");
    }

  }


  /**
   *
   * @param year Ex.: 2020
   */

  async loadExtractionsFromSelectedYear(event): Promise<void>{
    if (!this.hasInitialYear) {
      this.currentTabQuestions = this.generateTabQuestionsContent('extractions');
    }
    this.isToggleDisabled = true;
    this.openFullPageLoader();

    let year = this.model.clientIDTaxYear;
    this.disableQuest = (year === '2019' || year === '2020') ? true : false;
    if (event && event.setYes && !this.isProduction) {
      this.model.clientIDWantsToInputTaxData = 'No';
    } else {
      this.model.clientIDWantsToInputTaxData = this.clientTaxYears.years.includes(year) ? 'Yes' : 'No';
    }
    if(year === '2019'){
      this.model.clientIDWantsToInputTaxData = 'Yes';
    }


    let tempQuest = new taxfull_questions();

    /* if( this.qController.autoSaveJob ) { this.qController.autoSaveJob.isActive = false; } //isToggleDisabled
    let response: any = await this.qController.advisorService.getTaxToggleYearQuestions(this.clientData.clientId, year);
    tempQuest.populateWithArrayOfObjects(response);
    console.log(tempQuest);
    this.model.clearAllFlags();
    Questionnaire.updateModelFromModel(this.model, tempQuest, this.model.getExtractionsAtributes());
    this.listOfActiveValidationFlags = this.getListOfActiveFlags();
    if( this.qController.autoSaveJob ) { this.qController.autoSaveJob.isActive = true; } */



    let onErrorBehaviour = (error)=>{

      let errorData = error.error;
      console.log('ERROR Retrieving data from toggle year', errorData);


      if(errorData.message != undefined && errorData.message == 'no existing questionnaire!!!' && this.model){

        let cleanQuestionnaire = new taxfull_questions();
        cleanQuestionnaire.clientIDTaxYear = this.model.clientIDTaxYear;
        cleanQuestionnaire.clientIDFillingStatus = this.model.clientIDFillingStatus;

        let updateCurrentModel = ()=>{
          // Set the current model with the data of the new clean questionnaire.
          this.model = cleanQuestionnaire;
          this.isToggleDisabled = false;
          if(this.fullPageSpinnerModal){
            this.fullPageSpinnerModal.close();
            if (event && event.setYes && !this.isProduction) {

              this[`policyUploaderOptions${this.model.clientIDTaxYear}`].status = 'validating';
            }
          }
        }

        this.qController.submitForm(this, cleanQuestionnaire, this.clientData.clientId, 'fulltax', true, updateCurrentModel, true, false, false, this.auxModel);

      }else{
        this.openModalLoadinError();
      }


    }


    //Wait before gathering data
    this.window.setTimeout(()=>{

      this.qController.advisorService.getTaxToggleYearQuestions(this.clientData.clientId, year, this.model.clientIDFillingStatus).then((response: any) => {
        if( this.qController.autoSaveJob ) { this.qController.autoSaveJob.isActive = false; }
        console.log('loadExtractionsFromSelectedYear. Response to load: ', response);
        tempQuest.populateWithArrayOfObjects(response);

        this.model.clearAllFlags();
        Questionnaire.updateModelFromModel(this.model, tempQuest, this.model.getExtractionsAtributes());
        console.log('getListOfActiveFlags - loadExtractionsFromSelectedYear');
        this.listOfActiveValidationFlags = this.getListOfActiveFlags();
        this.isToggleDisabled = false;
        console.log('loadExtractionsFromSelectedYear. Model updated: ', JSON.parse(JSON.stringify(this.model)));
        if(this.fullPageSpinnerModal){ this.fullPageSpinnerModal.close(); }

        if(!Boolean(this.model.clientIDFillingStatus)){
          this.completeBaseFilingStatusCase();
        }
        if( this.qController.autoSaveJob ) { this.qController.autoSaveJob.isActive = true; }

        if (event && event.setYes && !this.isProduction) {

          this[`policyUploaderOptions${this.model.clientIDTaxYear}`].status = 'validating';
        }
          setTimeout(()=>{
            this.currentTabQuestions = this.generateTabQuestionsContent('extractions');
          },600)

        }, onErrorBehaviour
      /* (error => {

         console.log('ERROR Retrieving data from toggle year', error)
        this.isToggleDisabled = false;
        if(this.fullPageSpinnerModal){ this.fullPageSpinnerModal.close(); }
        this.openModalLoadinError();
      }) */
      );

    }, 600)


  }

  isModalUniqueAddOrDelete(modalName: string, update: boolean = false): boolean{
    let openModalIndex: number = this.listOfOpenModals.findIndex(modalName_ => modalName_ == modalName)
    if(openModalIndex >= 0){ // The modal name is in the list: return true, and delete.
      if(update){this.listOfOpenModals.splice(openModalIndex, 1)}  // Delete from list
      return false; //It's in  the list, not unique
    }else{
      if(update){this.listOfOpenModals.push(modalName);} // Add to the list
      return true; //Isn't in the list, is unique
    }
  }

  private completeBaseFilingStatusCase(){
    console.log('QUESTIONNAIRE: completeBaseFilingStatusCase', this.clientMaritalStatus);
    if (!this.clientMaritalStatus) return;
    this.model.clientIDFillingStatus = (this.clientMaritalStatus === 'Married') ? 'Married Filing Jointly' : 'Single';
  }

  async getTaxYearsAvailable(){
    let storagedClient = JSON.parse(sessionStorage.getItem('currentClient'));
    let clientId = storagedClient.clientId;
    const response = await this.http.get(`${getEndPointUrlByContext('GetTaxYears')}?idcli=${clientId}`).toPromise();
    this.clientTaxYears = response;

            //this.taxYearsAvaliable = response.years;
      this.disableQuest = (response['current'] === '2019' || response['current'] === '2020') ? true : false;
      if(response['years'].includes('2020')){
        this.taxYearsAvaliable.unshift('2020');
      }
      if(response['years'].includes('2019')){
        this.taxYearsAvaliable.unshift('2019');
      }
    this.findSchedBModel(1,1,'amount')
  }

  public findSchedBModel(section: (1 | 2), index:number, type:('text' | 'amount')): Object{
    return this.model[`scheduleb_${section}_${index}_${type}`];
  }

  async downloadCSV(event){

    event.preventDefault();
    let CSVfile = await this.qController.advisorService.getTaxExtractionsAsCSV(this.clientData.clientId, this.model.clientIDTaxYear);
    if(CSVfile) saveAs(new Blob([CSVfile], {type: 'text/csv;charset=utf-8;'}), `${cleanNameForPDF(this.clientData.fullName)}_taxExtractions${this.model.clientIDTaxYear}.csv`);

  }


  generateTabQuestionsContent(tabId: string){

    this.loadingQuestions = true;
    console.log('generateTabQuestionsContent TAB ID', tabId);
    let tab = this.tabsCatalog.find(tab => tab.id === tabId);


    let tabQuestions = [];
    FieldsMetadata.forEach((field, key) => {
      if(field.tabSection === tabId){
        field['inputDescription'] = field.description(this.isAdvisor, this.model);
        field['key'] = key;
        tabQuestions.push(field);
      }
    });
    console.log('Length', tab.subsections.length === 0);
    let tabQuestionContent: TabSubsectionContent[] =
      (tab.subsections.length === 0) ? [{id: null, name: '', questions: tabQuestions, isExpanded: true}]
      :
      tab.subsections.map(subsection => {
        return {
        id: subsection.id,
        name: subsection.name,
        questions: [],
        isExpanded: typeof(subsection.isExpandedRuleFunction) !== 'undefined' ?  subsection.isExpandedRuleFunction(this.model, this) : true,
        isVisible: typeof(subsection.displayRuleFunction) !== 'undefined' ?  subsection.displayRuleFunction(this.model, this) : true,
      }
      });
    if(tab.subsections.length > 0){
      tab.subsections.map(catalogSubsection => {
        let foundSection = tabQuestionContent.find(subsection => subsection.id === catalogSubsection.id);
        foundSection.questions = tabQuestions.filter(question => question.subsection ===  catalogSubsection.id);
        let sectionHasContent: boolean = false;
        for(const question of foundSection.questions){
          sectionHasContent = sectionHasContent || question.displayRuleFunction(this.model, this) && question.availableInYears.includes(Number(this.model.clientIDTaxYear))
        }
        foundSection.isVisible = foundSection.isVisible && sectionHasContent;

      })
    }

    console.log('Questions tabs content', tabQuestionContent);
    console.log(typeof (+this.model.clientIDTaxYear));

    setTimeout(()=>{ this.loadingQuestions = false; }, 700);


    return tabQuestionContent;
  }

updateTabs(){

    setTimeout(()=>{
      this.currentTabQuestions = this.generateTabQuestionsContent('extractions');
      /*console.log('updateTabs***', this.model.clientIDTypeOfAnalysis);
      if (this.model.clientIDTypeOfAnalysis === 'Indepth analysis'){
        //this.tabsCatalog = TAX_SECTIONS_CATALOG;
      }else if (this.model.clientIDTypeOfAnalysis === 'Instant tax analysis'){
        //this.tabsCatalog = [TAX_SECTIONS_CATALOG[0]];
      }*/
      this.determineTabGroupProgress();
    }, 600)
    //this.tabsCatalog = [];
  }

}

interface TabSubsectionContent{
  id: string,
  name: string,
  questions: any[];
  isVisible?: boolean,
  isExpanded?: boolean;
}

const STANDAR_DEDUCTIONS_TABLE_2020 = {
  'Single' : 12400,
  'Married Filing Jointly': 24800,
  'Married Filing Separately': 12400,
  'Head of Houseold': 18650,
  'Head of Household': 18650,
  'Qualifying Widow(er)': 24800
}

const STANDAR_DEDUCTIONS_TABLE_2019 = {
  'Single' : 12200,
  'Married Filing Jointly': 24400,
  'Married Filing Separately': 12200,
  'Head of Houseold': 18350,
  'Head of Household': 18350,
  'Qualifying Widow(er)': 24400
}

const STANDAR_DEDUCTIONS_TABLE_2021 = {
  'Single': 12550,
  'Married Filing Jointly': 24800,
  'Married Filing Separately': 12550,
  'Head of Household': 18800,
  'Qualifying Widow(er)': 24800,
}


const STANDAR_DEDUCTIONS_AGE_DEDUCTIONS = {
  'Single': 1750,
  'Married Filing Jointly': 1400,
  'Married Filing Separately': 1400,
  'Head of Household': 1750,
  'Qualifying Widow(er)': 300,
}
