// Angular
import { Component, OnInit, Input, HostListener, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HttpClient} from '@angular/common/http';

// Rx
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

// Project
import { ThemeService } from '../../services/theme.service';
import { LayoutService } from '../../services/layout.service';
import { AdvisorService } from '../../services/advisor.service';
import { ESettingsService } from 'app/shared/services/e-settings.service';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { AppSocketIoService } from '../../services/socket.io.service';
import { AgreementDialog } from '../agreement-dialog/agreement-dialog.component';
import { EndTrialDialog } from '../end-trial-dialog/end-trial-dialog.component';
import { urlHelperIsInEnvironment, ENVIRONMENT_URL_PRODUCTION, urlPrependRootContext, getLastUrlSegment } from 'app/shared/helpers/url.helper';
import { initPendo } from 'app/shared/helpers/pendo.helper';

import { environment } from '../../../../environments/environment';
import { saveAs } from 'file-saver';
import { DialogConfirm } from '../dialog-confirm/dialog-confirm.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { readFromStoragedObject } from 'app/shared/helpers/utils';
import { ClientService } from 'app/shared/services/client/client.service';
import { getFullName } from 'app/shared/services/client/utils/gettters';
import { ClientDataService } from 'app/shared/services/client-data-service';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html'
})

export class HeaderSideComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() notificationsPanel;
  @Input() notificationsContainer;
  @Input() pendingNotificationsCount: number = 0;

  public  pdfUrl: string = environment.apiPDFQuestionnaire;
  public  fpalphaThemes;
  public  layoutConf:any;
  public  currentClient: any;
  public  hideElement = false;
  public  urlWithoutParams;
  public  isAdvisor: boolean = false;
  public  isProduction:boolean;

  public  advisorData;
  public  advisorClientCount: number;
  public  headerUserName: string = '';
  public  showHamburger: boolean = false;
  public  urlHasAreaName:boolean = false;
  public  advisorHasClientSide: boolean;

  public toggleSearchBar: boolean = false;

  private advisorPreferences;
  public  advisorHasNotificationsEnabled: boolean;

  public visitorPendoData: any;
  public accountPendoData: any;

  public activateHeader: boolean = false;
  public isNewReleaseAvailable: boolean = false;
  public isNewNotificationAvailable: boolean = false;
  public currentClientFullname: any;
  private subscriptionClientDataService: Subscription;
  private getUpdateHeaderSubscription: Subscription;

  //public currentLevel = 0;

  constructor(
    private http: HttpClient,
    private router: Router,
    private themeService: ThemeService,
    private layout: LayoutService,
    private advisorService: AdvisorService,
    private settingsService: ESettingsService,
    private socketService: AppSocketIoService,
    private activatedRouter: ActivatedRoute,
    private authService: AuthenticationService,
    public  dialog: MatDialog,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public currentClientService: ClientService,
    private clientDataService: ClientDataService
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      const urlTree = this.router.parseUrl(this.router.url);
      const urlWithoutParams = urlTree.root.children['primary'].segments.map(it => it.path).join('/');
      this.urlWithoutParams = urlWithoutParams
      this.urlWithoutParams.includes('/areas/') ?  this.urlHasAreaName = true : this.urlHasAreaName = false;
      console.log('🔮 ***ON ROUTER EVT SAYS YOU ARE HERE***', this.urlWithoutParams);
      // Load client data

        setTimeout(HeaderSideComponent.loadClientData, 500, this, urlWithoutParams);

    });
  }

  static loadClientData = (instanceOfHeaderSide: HeaderSideComponent, currentUrlWithoutParams: string) => {

    let currentClientDataInSession = sessionStorage.getItem('currentClient');

    if(currentUrlWithoutParams != 'advisor/clients' && currentClientDataInSession != null){
      instanceOfHeaderSide.currentClient = JSON.parse(currentClientDataInSession);
     }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let windowSize:number = window.innerWidth;
    windowSize <= 959 ? this.showHamburger = true : this.showHamburger = false;
  }

  ngOnInit() {

    let windowSize:number = window.innerWidth;
    windowSize <= 959 ? this.showHamburger = true : this.showHamburger = false;
    this.isAdvisor = this.authService.isAdvisor();
    this.fpalphaThemes = this.themeService.fpalphaThemes;
    this.layoutConf = this.layout.layoutConf;

    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);
    this.currentClientService.data.generalQuestionnaire.subscribe((item) => {
      if(this.currentClient && item){
        console.log('currentClientService.data.generalQuestionnaire.subscribe', item);
        this.currentClient.level = item.dashboardData.optLevel;
        //this.currentLevel = item.dashboardData.optLevel;
      }
    })
    //
    console.log('Subscribe to afterSetFullCurrentClient')
    this.subscriptionClientDataService = this.clientDataService.afterSetFullCurrentClient('header-side').subscribe(clientData => {
      //console.log('===>THIS MEANS THE CLIENT WAS SET', client)
      console.log('afterSetFullCurrentClient. header-side')
      this.currentClient = clientData;
      this.currentClientFullname = clientData.fullName;
      console.log('header side. Client data updated', clientData);
    });

    this.getUpdateHeaderSubscription = this.clientDataService.getUpdateHeaderSubscription().subscribe(nothing => {
      console.log('getUpdateHeaderSubscription subscribe');
      this.reloadHeaderFromSessionData();
    })


    const urlTree = this.router.parseUrl(this.router.url);
    this.urlWithoutParams = urlTree.root.children['primary'].segments.map(it => it.path).join('/');
    this.urlWithoutParams.includes('/areas/') ?  this.urlHasAreaName = true : this.urlHasAreaName = false;

    this.advisorService.getAdvisorData()
      .toPromise()
      .then(
        data => {
          this.advisorData = data;

          let prospectingPreferences = {
            leadPageHeader : this.advisorData.leadPageHeader,
            leadPageDescription : this.advisorData.leadPageDescription,
            leadThanksPageHeader :  this.advisorData.leadThanksPageHeader,
            leadThanksPageDescription : this.advisorData.leadThanksPageDescription,
            leadPageAreas : this.advisorData.leadPageAreas,
            leadPageCustomQuestions : this.advisorData.customQuestions,
            leadDisclaimer: this.advisorData.leadDisclaimer,
            leadMeetingLink: this.advisorData.leadMeetingLink,
            leadAdvisorName: this.advisorData.leadAdvisorName,
            leadAdvisorEmail: this.advisorData.leadAdvisorEmail,
            leadAdvisorPhone: this.advisorData.leadAdvisorPhone,
            leadAdvisorCompany: this.advisorData.leadAdvisorCompany
          }

          //Set external-providers-interactions values
          localStorage.setItem("external-providers-interactions", JSON.stringify(this.advisorData.integrations));

          //Set prospecting preferences
          localStorage.setItem("prospectingPreferences", JSON.stringify(prospectingPreferences));

          // Check that an advisor is authenticated
          if(this.authService.isAdvisor()){

            // Need to know which external-providers-interactions are active
            let integrationKeys = Object.keys(this.advisorData.integrations);
            let activeIntegrations = [];

            integrationKeys.map(integrationKey => {
              if(this.advisorData.integrations[integrationKey] == true) {
                activeIntegrations.push(integrationKey)
              }
            })

            //Need this prop for PENDO, but it's an object sooo...
            const integrations = Object.keys(this.advisorData.integrations);

            let advisorInfo = {
              //These are use for PENDO as well
              fullName: this.advisorData.fullName,
              email: this.advisorData.email,
              phone: this.advisorData.phone,
              has2FA: this.advisorData.hasEnabled2FA,
              type: this.advisorData.type,
              company: this.advisorData.nameCompany,
              companyId: this.advisorData.companyId,
              creationDate: this.advisorData.creationDate,
              renewalDate: this.advisorData.renewalDate,
              firstLogin: this.advisorData.firstTimeLoginDate,
              lastLogin: this.advisorData.lastLogin,
              timesLoggedIn: this.advisorData.timesLoggedIn,
              onTrial: this.advisorData.isOnFreeTrialPeriod,
              trialExpirationDate: this.advisorData.freeTrialExpiresOn,
              numberOfClients: this.advisorData.clientList.length,
              betaAccess: this.advisorData.betaAccess,
              integrationList: integrations,
              activeIntegrations: activeIntegrations,
              numberOfIntegrations: activeIntegrations.length,

              //Not used for PENDO
              accountRestriction: this.advisorData.isRestrictedAccount,
              isMasterAccount: this.advisorData.isMasterAccount,

            }

            this.headerUserName = this.isAdvisor ? this.advisorData.fullName : readFromStoragedObject('currentClient', 'name', 'Session');

            localStorage.setItem("advisorInfo", JSON.stringify(advisorInfo));
            console.log('===> ADVISOR INFO SET <===');

            this.settingsService.getDefaultDisclaimer(this.advisorData.companyId).then(res => {
              const newAdvisorPreferences = this.advisorData.settings;
              if (res['default-disclaimer'] && res['default-disclaimer']['disclaimer']) {
                newAdvisorPreferences.disclaimer = res['default-disclaimer']['disclaimer']
              }

              localStorage.setItem("advisorPreferences", JSON.stringify(this.advisorData.settings));
              this.readPreferencesFromStorage();
            }).catch(
              error => console.log('🛑 Error setting advisor preferences!', error)
            )

            if(this.advisorData.showAgreement === true ) this.openTermsAgreementDialog();
            if(this.advisorData.freeTrialExpiredAccount === true && this.advisorData.purchasedLicense === false) this.openExpiredTrialDialog();

            if (this.advisorData.showClientSide !== undefined) {
              sessionStorage.setItem("hasClientSide", JSON.stringify(this.advisorData.showClientSide));
            } else {
              this.advisorHasClientSide = true;
              sessionStorage.setItem("hasClientSide", JSON.stringify(this.advisorHasClientSide));
            }

            this.advisorData.isNewReleaseAvailable ? this.isNewReleaseAvailable = true : this.isNewReleaseAvailable = false;

          }else{ //Client side
            this.headerUserName = readFromStoragedObject('currentClient', 'name', 'Session');
          }
        }).catch(
        error => {
          console.log('🛑 Advisor Service Error', error);
        });

    if(this.isAdvisor){ this.getCompanyData(), this.getPendoData()}
  }

  async ngAfterViewInit(): Promise<void> {

    //Load data from session storage
    let currentClient = JSON.parse(sessionStorage.getItem('currentClient'));
    if(currentClient != null){
      this.currentClient = currentClient;
      //this.currentClient.level = this.currentLevel
      this.currentClientFullname = currentClient.fullName;
    }

    //Get event notifications and react accordingly
    this.socketService.notificationGenerated.subscribe(notification => {
      //Handle notification style passed trough notification data.
      this.isNewNotificationAvailable = true;
    });

    // Delay the Pendo initialization to ensure all data is set on Storage
    setTimeout(() => {
      this.pendoInit();
      //this.currentClientService.refreshAll();
    }, 1200);

  }

  ngOnDestroy(){
    this.dialog.closeAll();
    this.subscriptionClientDataService.unsubscribe();
    console.log('unsubscribe header-side', this.subscriptionClientDataService);
    if(this.getUpdateHeaderSubscription != undefined){
      this.getUpdateHeaderSubscription.unsubscribe();
    }
  }

  getFullName(data){
    const hasCoClient = data.profile.client.maritalStatus == "Married";
    const hasCoClientSameLastname = data.profile.spouse.spouseUsesLastName == "Yes";
    const clientName =  data.profile.client.firstName ;
    const clientLastname =  data.profile.client.lastName;
    const coClientName = data.profile.spouse.spouseName;
    const coClientLastname = data.profile.spouse.spouseLastName
    const isCoClientEmpty = hasCoClient && (coClientName.length > 0);
    let composedName =  '';

    if (!hasCoClient || !isCoClientEmpty) {
      composedName = `${clientName} ${clientLastname}`;
    }

    if (hasCoClient && isCoClientEmpty) {
      if (hasCoClientSameLastname) {
        composedName = `${clientName} & ${coClientName} ${clientLastname}`
      }
      if (!hasCoClientSameLastname) {
        composedName = `${clientName} ${clientLastname} & ${coClientName} ${coClientLastname}`
      }
    }
    return composedName;
  }

  /**
   * @name getPendoData
   * @description Get advisor and company data from the portfolio/advisor-profile service and stores it in Local Storage to be accesible by the pendo.helper.
   * @param none
   */
  getPendoData(){
    this.advisorService.getPendoDataPromise()
      .then(
        pendoData => {
          this.visitorPendoData = pendoData.advisorInfo;
          this.accountPendoData = pendoData.companyInfo;

          let pendoDataToStorage = {
            ////****ADVISOR DATA */
            //Personal data
            id: this.visitorPendoData.id,
            fullName: this.visitorPendoData.fullName,
            company: this.visitorPendoData.company,
            type: this.visitorPendoData.type,
            email: this.visitorPendoData.email,
            phone: this.visitorPendoData.phone,
            has2FA: this.visitorPendoData.hasEnabled2FA,
            creationDate: this.visitorPendoData.creationDate,
            renewalDate: this.visitorPendoData.renewalDate,
            firstLogin: this.visitorPendoData.firstTimeLoginDate,
            lastLogin: this.visitorPendoData.lastLogin,
            timesLoggedIn: this.visitorPendoData.howManyLogins,
            onTrial: this.visitorPendoData.onTrial,
            trialExpirationDate: this.visitorPendoData.freeTrialExpiresOn,
            numberOfClients: this.visitorPendoData.numberOfClients,
            betaAccess: this.visitorPendoData.betaAccess,
            accountStatus: this.visitorPendoData.status,
            //Integrations
            activeIntegrations: this.visitorPendoData.activeIntegrations,
            numberOfIntegrations: this.visitorPendoData.activeIntegrations.length,
            //Snapshots
            autoSnapshots: this.visitorPendoData.autoSnapshots,
            homeSnapshots: this.visitorPendoData.homeSnapshots,
            estateSnapshots: this.visitorPendoData.estateSnapshots,
            fulltaxSnapshots: this.visitorPendoData.fulltaxSnapshots,
            totalSnapshots: this.visitorPendoData.totalSnapshots,
            ////****COMPANY DATA */
            companyId: this.accountPendoData.companyId,
            company_name: this.accountPendoData.company,
            poc: this.accountPendoData.pocName,
            billing_advisor: this.accountPendoData.billingadvisorName,
            subscription_type: this.accountPendoData.subscriptionType,
            company_size: this.accountPendoData.countAdvisors,
            creation_date: this.accountPendoData.creationDate,
            business_category: this.accountPendoData.businessCategory,
            uploadModelType: this.accountPendoData.uploadModelType,
          }

          //Set data in storage
          localStorage.setItem("AnalyticsData", JSON.stringify(pendoDataToStorage));
        }

      ).catch(
        error => {
          console.log('🛑 ERROR with getPendoData', error);
        }
      );
  }

  /**
   * @name pendoInit
   * @description Initializes the Pendo script if a token was provided. This script reads the props set on the AnalyticsData prop inside Local storage.
   * @param none
   */
  pendoInit(){
    console.log('Initializing Pendo...💿');
    initPendo();
  }

  /**
   * @name getCompanyData
   * @description Gets the company data from the advisor service and then assigns it to the companyData variable.
   * @param none
   */
  getCompanyData() {
    this.advisorService.getCompanyData()
      .toPromise()
      .then(
        company => {
            let companyInfo = {
              company: company.name,
              companyId: company._id,
              poc: company.pocName,
              billingAdvisor: company.billingadvisorName,
              subscriptionType: company.hasSubscription,
              companySize: company.advisorsCount,
              creationDate: company.creationDate
            }

            localStorage.setItem("companyInfo", JSON.stringify(companyInfo));
        }
      )
      .catch(
        error => {
          console.log('🛑 ERROR with getCompanyData', error);
        }
      );
  }

  /**
   * @name readPreferencesFromStorage
   * @description Read Advisor preferences from local storage to check for the notification settings.
   * @param none
   */
  readPreferencesFromStorage(){
    this.advisorPreferences = JSON.parse(localStorage.getItem('advisorPreferences'));
    this.advisorPreferences.notifications === 'Email' ? this.advisorHasNotificationsEnabled = false : this.advisorHasNotificationsEnabled = true;
  }

  /**
   * @name showMenu
   * @description Show hamburger menu if the screen size is within the mobile device range.
   * @param none
   */
  showMenu(){
    this.layout.isMobile ? this.showHamburger = true : this.showHamburger = false;
    return this.showHamburger;
  }

  /**
   * @name toggleSidenav
   * @description Toggles the sidenav is the screen size is within the mobile device range.
   * @param none
   */
  toggleSidenav() {
    if(this.layoutConf.sidebarStyle === 'closed') {
      this.showHamburger = true;
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      })
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }

  /**
   * @name toggleNotifications
   * @description Open or close the notifications drawer.
   * @param none
   */
  toggleNotifications() {
    this.notificationsPanel.toggle();
    this.notificationsContainer.search()
  }

  /**
   * @name toggleCollapse
   * @description Detects which layout is being used and reacts accordingly.
   * @param none
   */
  toggleCollapse() {
    // compact --> full
    if(this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      }, {transitionClass: true})
    }
    // * --> compact
    this.layout.publishLayoutChange({
      sidebarStyle: 'compact'
    }, {transitionClass: true})
  }

/**
   * @name toggleSearch
   * @description Toggles search bar.
   * @param none
   */
  toggleSearch() {
    this.toggleSearchBar = !this.toggleSearchBar;
  }

  /**
   * @name openSettingsDialog
   * @description  Navigates user to the specified route.
   * @param none
   */
  openSettingsDialog(): void {
    this.router.navigate(['/settings/profile']);
  }

  /**
   * @name openTermsAgreementDialog
   * @description Opens a modal with the terms so the user can accept them. Accepting the terms is required to continue using the platform.
   * @param none
   */
  openTermsAgreementDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      'company': this.advisorData.nameCompany
    };
    dialogConfig.width = "380px";
    dialogConfig.height = "200px";
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "modal-dialog-with-form";

    const dialogRef = this.dialog.open(AgreementDialog, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      if (data === 'cancelled') this.authService.logout(null, 'openTermsAgreementDialog dindt agree');
    },
      error => {
        console.log('🛑 ERROR with openTermsAgreementDialog', error);
      });
  }

  /**
   * @name openExpiredTrialDialog
   * @description Opens a modal if the trial ended. User cannot dismiss this modal unless an extension is granted or purchases a license.
   * @param none
   */
  openExpiredTrialDialog(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {};
    dialogConfig.width = "500px";
    dialogConfig.height = "300px";
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "modal-dialog-with-form";

    const dialogRef = this.dialog.open(EndTrialDialog, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      if (data === 'cancelled') this.authService.logout(null, 'openExpiredTrialDialog expired trial');
    },
      error => {
        console.log('🛑 ERROR with openExpiredTrialDialog', error);
      });

  }

  onLogout(){
    this.authService.logout(null, 'logout by user').then(data => {});
   }

   goToProfile(){
    if(!this.currentClient.isProspect){
      this.router.navigate([urlPrependRootContext('/dashboard')])
    }else{
      console.log("Prospects don't have access to the profile section");
    }

   }

  /**
   * @name upgradeToClient
   * @description Upgrades a prospect to a client, we ask to confirm before upgrading.
   * @param none
   */
  upgradeToClient() {

    const dialogRef = this.dialog.open(DialogConfirm, {
      data: 'Do you want to upgrade this prospect to client? This action cannot be undone.'
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result != '') {
          this.currentClient.isProspect = false

          let updatedStatus = this.currentClient;

          this.advisorService.prospectToClient(this.currentClient.clientId).then(response => {

            sessionStorage.currentClient = JSON.stringify(updatedStatus);
            this.router.navigate(['/advisor/dashboard']);

            let snackBarRef = this.snackBarSuccess.open("Prospect successfully upgraded to client", "Ok", {
              duration: 10000,
              panelClass: 'success-snackbar'
            });

          });

        }
      },
      error => {
        console.log(error);
        let snackBarRef = this.snackBarError.open("Can't upgrade prospect, please try again. If problem persists, please contact support.", "Ok", {
          duration: 10000,
          panelClass: 'error-snackbar'
        });
      });

  }

  reloadHeaderFromSessionData(){
    let currentClient = JSON.parse(sessionStorage.getItem('currentClient'));
    console.log('reloadHeaderFromSessionData', currentClient);
    this.currentClient = currentClient;
    this.currentClientFullname = currentClient.fullName;
  }




}
