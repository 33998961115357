//Angular
import { Component, OnInit, AfterViewInit, Inject, EventEmitter, Output, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';

//3rd party
import { FileUploader } from 'ng2-file-upload';

//Project
import { AdvisorService } from '../../shared/services/advisor.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { WINDOW } from "../../shared/helpers/window.provider";
import { environment } from '../../../environments/environment';
import { fpAnimations } from "../../shared/animations/fp-animations";
import { urlHelperIsInEnvironment, ENVIRONMENT_URL_PRODUCTION, urlPrependRootContext } from 'app/shared/helpers/url.helper';

/***Dialogs***/
import { AddNewClientDialog } from './add-new-client/add-new-client-dialog.component';
import { AddNewProspectDialog } from './add-new-prospect/add-new-prospect.component';
import { BulkClientDialog } from './bulk-client-upload/bulk-client-dialog.component';
import { DeleteClientDialog } from './delete-client/delete-client.component';
import { ShareClientDialog } from './share-client/share-client.component';
import { ShareClientMultipleDialog } from '../../shared/components/share-client-multiple/share-client-multiple.component';
import { DialogConfirm } from '../../shared/components/dialog-confirm/dialog-confirm.component';
import { ActivatedRoute } from '@angular/router';

import { Router } from "@angular/router";
import { AppSocketIoService } from 'app/shared/services/socket.io.service';
import { AnnouncementModalComponent } from 'app/shared/components/announcement-modal/announcement-modal.component';
import { IntegrationsModalComponent } from 'app/views/enterprise-settings/integrations/templates/interactions/integrations-modal/integrations-modal.component';
import { isDisabledAdvisorFeature } from 'app/shared/helpers/storageControllers';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';
import { ClientService } from 'app/shared/services/client/client.service';
import { IntegrationAvailabilityService } from 'app/shared/services/integration/integration-availability.service';
import {IntegrationAvailability, AvailabilityStatus, InfoIntegrations} from 'app/shared/model/Integration-Availability.model'
import { ClientDataService } from 'app/shared/services/client-data-service';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  animations: fpAnimations
})

export class ClientsComponent implements OnInit, AfterViewInit, OnDestroy {

  message:string;

  @Output() clientClicked = new EventEmitter<any>();
  @Output() tokenGenerated = new EventEmitter<any>();
  @Output() clientWasDeleted = new EventEmitter<void>();

  private rawToken: string;
  private advisorPreferences: any;
  private advisorInfo: any;
  public restrictedActions: any = [];
  public clientName: string;
  public clientLastName: string;
  public clientEmail: string;
  public confirmEmail: string;
  public areaTaxPlan: string;
  public areaStatePlan: string;
  public areaHomeInsur: string;
  public areaAutoInsur: string;
  public term: string;
  public isProspect: boolean = false;
  public isRestrictedAccount: boolean = false;
  public isMasterAccount: boolean = false;
  public ascendingOrderName: boolean = false;
  public ascendingOrderScore: boolean = false;
  public ascendingOrderDate: boolean = false;
  public ascendingOrderType: boolean = false;
  public fetching: boolean = false;
  public showExtras: boolean = false;
  public firstAccess: boolean = true;
  public currentClientExtrasAreVisible: number = -1;

  //Mass mutual thingie
  public isMassMutual: boolean = false;

  //Pagination
  public pageCount: number;
  public pageQty: number[];
  public itemsPerPage: number;
  public totalItems: number;
  public pageEvent: PageEvent;
  // public actualPageSize: number = 10;
  public actualPageIndex: number = 0;

  public advisorEmail: string;
  public clientID: string;

  public advisorWantsToShareClients: boolean;

  public uploadUrl: string;
  public batchUpload = environment.apiBatchUpload;
  public shareThisClient = environment.apiShareClient;
  public isProduction:boolean;
  public uploader: FileUploader;
  public hasBaseDropZoneOver: boolean = false;
  public objectKeys = Object.keys;
  public isEarlyTester: boolean;
  public isFeatureEnabled_prospect: boolean = false;

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public advisorClients: any[];

  public tourActivate: boolean = false;
  public lastClientId: string = 'clientProfile0';

  public clientPageSize: number = 25;
  public defaultPageSize: number = 25;
  public generateRandomEmail: boolean;
  public isLoading: boolean = false;
  public redirecting: boolean = false;

  public integrations = [
    {
      name:'outlook',
      img: '',
      active: false,
      status: ''
    },
    {
      name:'redtail',
      img: 'https://storage.googleapis.com/fpalpha-assets/Integrations_Logos/Redtail-logo.png',
      active: false,
      status: ''
    },
    {
      name:'salesforce',
      img: 'https://storage.googleapis.com/fpalpha-assets/Integrations_Logos/Salesforce.png',
      active: false,
      status: ''
    },
    {
      name: 'wealthbox',
      img: 'https://storage.googleapis.com/fpalpha-assets/Integrations_Logos/Wealthbox.png',
      active: false,
      status: ''
    },
    {
      name: 'moneyguide',
      img: 'https://storage.googleapis.com/fpalpha-assets/Integrations_Logos/MoneyGuide.png',
      active: false,
      status: ''
    },
    {
      name: 'riskalyze',
      img: 'https://storage.googleapis.com/fpalpha-assets/Integrations_Logos/Nitrogen.png',
      active: false,
      status: ''
    },
    {
      name: 'precisefp',
      img: 'assets/images/precisefp.svg',
      active: false,
      status: ''
    },
    {
      name: 'orion',
      img: 'assets/images/orion.jpg',
      active: false,
      status: ''
    }
  ]

  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private advisorService: AdvisorService,
    private clientDataService: ClientDataService,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public route: ActivatedRoute,
    @Inject(WINDOW) private window: Window,
    private router: Router,
    private socketService: AppSocketIoService,
    private authenticationService: AuthenticationService,
    public currentClientService: ClientService,
    private integrationAvailabilityService: IntegrationAvailabilityService
  ) {

    this.route.queryParams.subscribe(params => {
      if (params['session_id']) {
        this.snackBarSuccess.open(
          "Your subscription plan was activated successfully. You can see more in your account's settings.",
          'Dismiss', {
            duration: 9000,
            verticalPosition: 'top',
            panelClass: 'success-snackbar',
          }
        );
      }
    });


    //MGP Integrations
    if(localStorage.getItem('mgp')){
      let data = JSON.parse(localStorage.getItem('mgpData'))
      const dialogRef = this.dialog.open(IntegrationsModalComponent, {
        disableClose: true,
        panelClass: 'modal-dialog-with-form',
        height: '470px',
        width: '500px',
        data: data
      });

      dialogRef.afterClosed().subscribe(data => {
        if (data == 'gotit') {
          localStorage.removeItem('mgp');
          localStorage.removeItem('mgpData');
        }
      });

    }

    //Orion Integrations
    if(localStorage.getItem('orion')){
      let data = JSON.parse(localStorage.getItem('orionData'))
      const dialogRef = this.dialog.open(IntegrationsModalComponent, {
        disableClose: true,
        panelClass: 'modal-dialog-with-form',
        height: '470px',
        width: '500px',
        data: data
      });

      dialogRef.afterClosed().subscribe(data => {
        if (data == 'gotit') {
          localStorage.removeItem('orion');
          localStorage.removeItem('orionData');
        }
      });

    }


  }

  ngOnInit() {
    this.isLoading = true;
    this.restrictedActions = this.authenticationService.getCurrentRoleRestrictions();
    // Clear all data from clients from storage
    sessionStorage.clear();

    // Know if we are in prod mode so we can show/hide stuff
    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);

    this.getIntegrationAvailability()

    // Get the actual clients and roll the spinner
    this.advisorService.getAdvisorData()
      .toPromise()
      .then(res => {
        const temporalPageSize = Number(localStorage.getItem('temporalClientPageSize')) || null;
        this.fetchClients(this.actualPageIndex, temporalPageSize || res?.settings?.clientPageSize);
        this.clientPageSize = temporalPageSize || res?.settings?.clientPageSize || 25;
        this.clientPageSize = temporalPageSize || res?.settings?.clientPageSize || 25;
        this.defaultPageSize = res?.settings?.clientPageSize;

      });

    // Get session token for batch client uploader request
    let sessionToken = localStorage.getItem("sessionToken");

    // Batch upload logic
    this.uploadUrl = `${this.batchUpload}`;
    this.uploadUrl = this.advisorService.dynamicIPAddress(
      this.window,
      this.uploadUrl
    );

    this.uploader = new FileUploader({
      url: this.uploadUrl,
      authTokenHeader: "Authorization",
      authToken: `Bearer ${sessionToken}`,
      itemAlias: "clientIDFiles"
    });

    this.uploader.onAfterAddingFile = file => {
      file.withCredentials = false;
    };

    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {

    };

    this.rawToken = localStorage.getItem('sessionToken')
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(this.rawToken);
    const expirationDate = helper.getTokenExpirationDate(this.rawToken);
    const isExpired = helper.isTokenExpired(this.rawToken);
    this.tokenGenerated.emit(decodedToken.idAdvisor);
    this.advisorService.setIdAdvisor(decodedToken.idAdvisor);


    //Socket. Update client list on events.
    this.socketService.notificationClientsImportReady.subscribe(data => {
      this.fetching = true;
      this.fetchClients(this.actualPageIndex, this.clientPageSize);
    });

    this.socketService.notificationNewLeadReceived.subscribe(data => {
      this.fetching = true;
      this.fetchClients(this.actualPageIndex, this.clientPageSize);
    });

    this.socketService.notificationClientSharedWithMe.subscribe(data => {
      this.fetching = true;
      this.fetchClients(this.actualPageIndex, this.clientPageSize);
    });

    this.socketService.notificationClientsImportReadyFinal.subscribe(data =>{

      this.fetching = true;
      this.fetchClients(this.actualPageIndex, this.clientPageSize);
    })

    this.checkIfNewUpdate();

    // this.loadStatusServices(); ---> To use this (extracctable outage message) uncomment function on line 786 as well (RT)

    //Mass Mutual conditional
    let massMutualAccounts: string[] = ['6228b550cdff4049dd327f35', '6228b37ccdff4049dd32563a',];
    massMutualAccounts.includes(localStorage.getItem('userId')) ? this.isMassMutual = true : this.isMassMutual = false;

    // Is feature enabled
    setTimeout(()=>{
      this.isFeatureEnabled_prospect = !isDisabledAdvisorFeature('prospect');

    }, 3500)
  }

  ngAfterViewInit(){
    // Must make sure that the damn preferences are loaded (RT)
    /*if (localStorage['advisorPreferences']) {
      this.parseLocalStorageSettings();
    } else {
      setTimeout(() => { this.parseLocalStorageSettings(); }, 1000);
    }*/
    console.log('localStorage[\'advisorPreferences\']*', localStorage['advisorPreferences']);

    localStorage['advisorInfo'] ? this.parseLocalStorageSettings() :  setTimeout(() => { this.parseLocalStorageSettings(); }, 1200);
  }

  ngOnDestroy(){
    this.dialog.closeAll();
    this.snackBarError.dismiss();
  }

  /**
   * Updates the availability status of integrations based on the response from the
   * integrationAvailabilityService.
   */
  getIntegrationAvailability(){
    this.integrationAvailabilityService.getIntegrationAvailability().subscribe(({infoIntegrations}: {infoIntegrations: InfoIntegrations}) => {
      Object.keys(infoIntegrations).forEach((item, i) => {
        this.integrations.forEach((arg, j) => {
          if(item == arg.name){
            this.integrations[j].active = infoIntegrations[item].isActive
            this.integrations[j].status = infoIntegrations[item].status
          }
        })
      })
    })
  }

  /**
   * @name fetchClients
   * @description Gets the client list of the advisor, results are paginated if the params are provided, if no params then you get all the items.
   * @param page
   * @param items
   */

  fetchClients(page?:number, items:number = 10){

    console.log('Page:', page, 'Items:', items);


    this.fetching = true;

    this.advisorService.getClientListV2(page, items)
      .subscribe(
        data => {
          console.log('getClientListv2', data)
          this.advisorClients = data.clients;
          this.fetching = false;
          this.totalItems = data.totalClientsCount;
        },
        error => {
          console.log(error);
          this.fetching = false;
          this.snackBarError.open("Can't get client list. [" + error.error.message + ']', "", {
            duration: 6000,
            panelClass: 'error-snackbar'
          });
        });
  }

  /**
   * @name getPage
   * @description Handles the paginator events and passes the params to the fetch clients function.
   * @param event
   */

  getPage(event){
    localStorage.setItem('temporalClientPageSize', event.pageSize);
    this.actualPageIndex = event.pageIndex;
    this.clientPageSize = event.pageSize;
    this.fetchClients(this.actualPageIndex, this.clientPageSize);
  }

  /**
   * @name updateClientList
   * @description Updates client list on event.
   */

  updateClientList() {

    this.fetching = true;
    const temporalPageSize = Number(localStorage.getItem('temporalClientPageSize')) || null;
    this.advisorService.getClientListV2(0, temporalPageSize || this.defaultPageSize)
      .subscribe(
        data => {
          this.advisorClients = data.clients;
          this.fetching = false;
          if(data.clients.length == 1){
            this.advisorService.setFirstTimeTutorial(false).subscribe(data=>{});
          }
        },
        error => {
          console.log(error);
          this.fetching = false;
          this.snackBarError.open("Can't update client list. [" + error.error.message + ']', "", {
            duration: 6000,
            panelClass: 'error-snackbar'
          });
        });
    this.advisorService.setClientCount();
  }

  /**
   * @name getSharedNamesFromClient
   * @param client
   * @returns
   */

  getSharedNamesFromClient(client: any){
    return client.sharedWith.slice(4).map(shared => shared.fullName).join(', ')
  }

  /**
   * @name sortBy
   * @description Sorts the client list by the term provided.
   * @param term Can be Name, Type, and Date added
   */

  sortBy(term) {

    this.term = term;
    this.fetching = true;

    this.advisorService.getClientListV2(0,this.clientPageSize)
      .subscribe(
        data => {
          let assortedClients = data.clients;
          if (this.term === 'name') {

            this.ascendingOrderName = !this.ascendingOrderName;

            let sortedClients = assortedClients.sort((a, b) => {
              a = a.clientIDLastName.toLowerCase();
              b = b.clientIDLastName.toLowerCase();
              if (this.ascendingOrderName == true) {
                return (a > b) ? -1 : (a == b) ? 0 : 1
              } else {
                return (a > b) ? 1 : (a == b) ? 0 : -1
              }
            });

            this.advisorClients = sortedClients;
            this.fetching = false;

          } else if (this.term === 'score') {

            let sortedClients = assortedClients.sort((a, b) => {
              a = a.optLevel;
              b = b.optLevel;
              return this.ascendingOrderScore ? a - b : b - a;
            });

            // toggle ascendingOrderScore
            this.ascendingOrderScore = !this.ascendingOrderScore;
            this.advisorClients = sortedClients;
            this.fetching = false;

          } else if (this.term === 'type') {


            let sortedClients = assortedClients.sort((a, b) => {
              a = a.isProspect;
              b = b.isProspect;
              return this.ascendingOrderType ? a - b : b - a;
            });

            // toggle ascendingOrderScore
            this.ascendingOrderType = !this.ascendingOrderType;
            this.advisorClients = sortedClients;
            this.fetching = false;

          } else if (this.term === 'date') {

            this.ascendingOrderDate = !this.ascendingOrderDate;

            let sortedClients = assortedClients.sort((a, b) => {
              a = a.creationDate;
              b = b.creationDate;

              if (this.ascendingOrderDate === true) {
                return (a > b) ? 1 : (a == b) ? 0 : -1
              } else {
                return (a > b) ? -1 : (a == b) ? 0 : 1
              }

            });
            this.advisorClients = sortedClients;
            this.fetching = false;
          }
        },
        error => {
          console.log(error);
        });
  }

  /**
   * @name setFullCurrentClient
   * @description Passes the selected client to advisorService and by doing this sets all the client info in storage for the app to use everywhere, this is critical!
   * @param client
   */

  async setFullCurrentClient(client: any, route: string) {
    if (!this.redirecting) {
      this.redirecting = true;
      console.log('loadingClientData.emit true');
      this.currentClientService.initClient(client.id).then(r=>{
        this.clientDataService.setFullCurrentClient(client);
        console.log('setFullCurrentClient after');
      }).catch(() => this.redirecting = false);

      this.router.navigate([route]);
    }
  }

  /**
   * @name parseLocalStorageSettings
   * @description Parses settings stored in local storage to show/hide elements depending on the advisor preferences.
   */

  parseLocalStorageSettings(){
      //Advisor preferences from settings
    this.advisorService.getAdvisorData()
      .subscribe(
        data => {
          this.advisorPreferences = data.settings;
          this.generateRandomEmail = this.advisorPreferences['generateRandomEmail'];
          this.advisorWantsToShareClients = Boolean (this.advisorPreferences?.shareClients);

        })


      //Advisor account info
      this.advisorInfo = JSON.parse(localStorage.getItem('advisorInfo'));
      this.isRestrictedAccount = this.advisorInfo.accountRestriction;
      this.isMasterAccount = this.advisorInfo.isMasterAccount;

      //Beta access
      localStorage.getItem('betaAccess').includes('earlyAccessUsers') ? this.isEarlyTester = true : this.isEarlyTester = false;
      setTimeout(() => {
        this.isLoading =  false;
      }, 1000);
  }

  /**
   * @fires AddNewClientDialog
   */

  openNewClientDialog(isProspect:boolean = false): void {
    const dialogRef  = this.dialog.open(AddNewClientDialog, {
      disableClose: false,
      panelClass: 'modal-dialog-with-form',
      width: '1120px',
      minHeight: '504px',
      maxHeight: '100%',
      data: { name: this.clientName, lastName: this.clientLastName, email: this.clientEmail, isProspect: isProspect, tourActivate: this.tourActivate },
    });
    dialogRef.componentInstance.integrations = this.integrations

    dialogRef.afterClosed().subscribe(data => {
      if (data !== 'cancelled') {
        setTimeout(() => {  this.updateClientList(); }, 500);
      }
    });
  }

  /**
   * @fires BulkClientDialog
   */

  openBulkClientDialog() {
    const dialogRef = this.dialog.open(BulkClientDialog, {
      panelClass: 'modal-dialog-with-form',
      width: '600px',
      data: { name: this.clientName, lastName: this.clientLastName, email: this.clientEmail, isProspect: this.isProspect }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'cancelled') {
        setTimeout(() => {  this.updateClientList(); }, 1000);
      }
    });
  }

  /**
   * @fires AddNewProspectDialog
   */

  openNewProspectDialog(): void {
    const dialogRef = this.dialog.open(AddNewProspectDialog, {
      panelClass: 'modal-dialog-with-form',
      height: '680px',
      width: '850px',
      data: { name: this.clientName, lastName: this.clientLastName, email: this.clientEmail, isProspect: this.isProspect }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'cancelled') {
        setTimeout(() => {  this.updateClientList(); }, 500);
      }
    });

    (<any>window).ga('send', 'event', {
      eventCategory: 'Button pressed',
      eventLabel: 'Prospect button',
      eventAction: 'Button clicked',
      eventValue: 10
    });

  }

  /**
   * @fires ShareClientDialog
   */

  openShareClientDialog(IDclient: string) {

    this.clientID = IDclient;
    const dialogRef = this.dialog.open(ShareClientDialog, {
      panelClass: 'modal-dialog-with-form',
      width: '450px',
      data: { email: this.advisorEmail, id: IDclient }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'cancelled') {
        setTimeout(() => { this.fetchClients(this.actualPageIndex, this.clientPageSize); /* this.updateClientList(); */}, 500);
      }
    },
      error => {
        this.snackBarError.open("Error. [" + error.error.message + ']', "", {
          duration: 6000,
          panelClass: 'error-snackbar'
        });
      });
  }

  /**
 * @fires ShareClientMultipleDialog
 */

    openShareClientMultipleDialog(IDclient: string, sharedWith: any) {



    this.clientID = IDclient;
    const dialogRef = this.dialog.open(ShareClientMultipleDialog, {
      panelClass: 'modal-dialog-with-form',
      width: '700px',
      // minHeight: '635px',
      data: { email: this.advisorEmail, id: IDclient, sharedBetween: sharedWith }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'cancelled') {
        setTimeout(() => { this.fetchClients(this.actualPageIndex, this.clientPageSize); /* this.updateClientList(); */}, 500);
      }
    },
      error => {
        this.snackBarError.open("Error. [" + error.error.message + ']', "", {
          duration: 6000,
          panelClass: 'error-snackbar'
        });
      });
  }

  /**
   * @fires DeleteClientDialog
   */

  openDeleteClientDialog(client: string, i: number) {

    const dialogRef = this.dialog.open(DeleteClientDialog, {
      panelClass: 'modal-dialog-with-form',
      width: '400px',
      data: { client: client, index: i }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'deleted') {
        setTimeout(() => { this.fetchClients(this.actualPageIndex, this.clientPageSize); /* this.updateClientList(); */}, 500);
      }
    },
      error => {
        this.snackBarError.open("Error. [" + error.error.message + ']', "", {
          duration: 6000,
          panelClass: 'error-snackbar'
        });
      });
  }

  /**
   * @name upgradeToCLient
   * @description Upgrades prospect to client.
   * @fires DialogConfirm
   * @param client
   * @param i
   */

  upgradeToCLient(client: string, i: number) {

    const dialogRef = this.dialog.open(DialogConfirm, {
      data: 'Do you want to upgrade this prospect to client? This action cannot be undone'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != '') {
        this.advisorService.prospectToClient(client).then(response => {
          this.fetchClients(this.actualPageIndex, this.clientPageSize); /* this.updateClientList(); */
        });
      }
    },
      error => {
        this.snackBarError.open("Error. [" + error.error.message + ']', "", {
          duration: 6000,
          panelClass: 'error-snackbar'
        });
      });
  }

  /**
   * @name showExtendedClientInfo
   * @description Shows more information of the client in the list.
   * @param i
   */

  showExtendedClientInfo(i) {
    this.showExtras = !this.showExtras;
    this.currentClientExtrasAreVisible = i;
  }

  hasProp(o, name) {
    return o.hasOwnProperty(name);
  }

  /**
   * @name initializeTour
   * @description Initializes and handles the tour component.
   * @fires Tour
   */

  checkIfNewUpdate(){
    this.http.get(`${environment.apiAnnouncement}`).toPromise().then(response =>{

      if(response['generalAnnouncement'] == true){
        this.openModalAnnoucement(response);
      }

    });
  }

  //Open tutorial modal
  openModalAnnoucement(data){
    const dialogRef = this.dialog.open(AnnouncementModalComponent, {
      disableClose: true,
      panelClass: 'modal-dialog-tutorial',
      height: '490px',
      width: '750px',
      data: data
    });

    dialogRef.afterClosed().subscribe(data => {

      if (data == 'gotit') {
        this.http.post(`${environment.apiAnnouncement}`,{}).toPromise().then(res =>{

        });
      }
    });
  }


  checkIfProspectHasCompletedQuest(prospect){
    let hasCompleted = prospect.prospectProgress.isSubmitted;
    if(prospect.allRecs > 0) hasCompleted = true;
    return hasCompleted;
  }

  public obtainRouteForClientCase(client): string{
    let route = ''
    if(client.firstAccess){
      route = '/questionnaire-general'
    }else if(client.hasGeneralQuest === false){
      if(client.clientHasAnsweredQuestionnaires.fulltax !== false && client.clientHasAnsweredQuestionnaires.estate === undefined){
        route = '/questionnaire-fulltax'
      }else if(client.clientHasAnsweredQuestionnaires.estate !== false && client.clientHasAnsweredQuestionnaires.fulltax === undefined){
        route = '/estate-documents'
      }else{
        route = '/hub'
      }
    }else{
      route = '/dashboard'
    }
    //
    return '/' + urlPrependRootContext(route);
    //client.firstAccess ? '/questionnaire-general' : (client.clientHasAnsweredQuestionnaires.fulltax === false && client.hasGeneralQuest === false) ? '/questionnaire-fulltax':'/dashboard'
  }

} //@end ClientsComponent

