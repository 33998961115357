// Angular
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';

// Project
import { JwtHelperService } from '@auth0/angular-jwt';
import { AdvisorService } from '../../services/advisor.service';
import { AppSocketIoService } from '../../services/socket.io.service';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { NOTIFY_STYLE_ALERT, NOTIFY_STYLE_ERROR, NOTIFY_STYLE_SUCCESFULL } from './notifications.constants';
import { urlPrependRootContext } from '../../helpers/url.helper';
import {FormControl, FormGroup} from "@angular/forms";

//Areas
import { AREAS, Area, PROSPECT_AREAS } from '../../../shared/helpers/areas.catalog';
import { ClientDataService } from 'app/shared/services/client-data-service';
import { ClientService } from 'app/shared/services/client/client.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html'
})
export class NotificationsComponent implements OnInit, OnDestroy {
  @Input() notificationsPanel;
  @Output() notificationsCounter: EventEmitter<number> = new EventEmitter();

  public notifications: string[] = [];
  public snackNotification: string;
  public advisorNotifications: any = [];
  public idAdvisor;
  public deleteNotifications: string = environment.apiAdvisorDeleteNotifications;
  public notificationStyle: string = '';
  public pendingNotificaitionsCount: number = 0;
  public defaultFilters: string[] = ['', 'pending'];
  public isLoading: boolean = false;
  private notificationGeneratedSubscription: Subscription;

  filterForm = new FormGroup({
    recommendations: new FormControl(false),
    documents: new FormControl(false),
    client_shared: new FormControl(false),
    new_prospect: new FormControl(false),
    tasks_reminder: new FormControl(false),
    payments_reminder: new FormControl(false)
  });

  statusForm = new FormGroup({
    pending: new FormControl(true),
    opened: new FormControl(false)
  })

  constructor(
    private router: Router,
    private socketService: AppSocketIoService,
    private advisorService: AdvisorService,
    public activeRoute: ActivatedRoute,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    private http: HttpClient,
    public authService: AuthenticationService,
    public clientDataService: ClientDataService,
    public currentClientService: ClientService
  ) { }

  ngOnInit() {

    this.router.events.subscribe((routeChange) => {
      if (routeChange instanceof NavigationEnd) {
        this.notificationsPanel.close();
      }
    });

    this.notificationGeneratedSubscription = this.socketService.notificationGenerated.subscribe(notification => {



      //Update internal notifications count
      this.pendingNotificaitionsCount++;
      //console.log('Notification content', notification);
      console.log(`notifications emmit: ${this.pendingNotificaitionsCount}`);
      this.notificationsCounter.emit(this.pendingNotificaitionsCount);

      //Handle notification style passed trough notification data.
      if (notification.interactionStyle == undefined) notification['interactionStyle'] = NOTIFY_STYLE_SUCCESFULL;

      if(notification.tag == 'NEW_SHARED_CLIENT'){
        this.snackNotification = notification.description;
      }else if (notification.tag === 'EstateSnapshot') {
        this.snackNotification = notification.description;
      } else if (notification.type == 'import') {
        this.snackNotification = notification.message;
      } else if(notification.type == 'lead'){
        this.snackNotification = notification.message;
      } else if(notification.type == 'advisorActions'){
        this.snackNotification = notification.message;
      } else {

        if (notification.message == 'General done') {
          this.snackNotification = 'You can now answer the questionnaire of each area.';
        } else {
          //this.snackNotification = `The Insights of ${notification.message} for ${notification.clientFullName} are ready`;
          this.snackNotification = `Insights result for ${notification.metadata.clientFullName}: ${notification.metadata.message}`;
          this.currentClientService.refreshAll();
        }
      }

      //Select the panel class option based on the current notification style.
      let selectedPanelClass = (notification.interactionStyle == NOTIFY_STYLE_ERROR) ? 'error-snackbar' : (notification.interactionStyle == NOTIFY_STYLE_ALERT) ? 'alert-snackbar' : (notification.interactionStyle == NOTIFY_STYLE_SUCCESFULL) ? 'success-snackbar' : 'success-snackbar';

      // Display the notification interaction
      console.log("Open snackBarSuccess", this.snackNotification);
      //Clear html tags
      const tempElement = document.createElement('div');
      tempElement.innerHTML = this.snackNotification;
      const stringWithoutTags = tempElement.textContent || tempElement.innerText || '';
      console.log(stringWithoutTags);
      //End clear html tags
      //this.snackNotification = this.snackNotification.split('<br>').join(' ');
      this.snackBarSuccess.open(stringWithoutTags, "Ok", {
        duration: 6000,
        panelClass: selectedPanelClass
      });

      this.search();

    });

  }

  ngAfterViewInit() {

    if (this.authService.isAdvisor()) {
      let rawToken = localStorage.getItem('sessionToken');
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(rawToken);
      this.idAdvisor = decodedToken.idAdvisor;
    }
    this.getAllNotifications();
  }

  getAllNotifications(filters: string = '', status: string = 'pending'){
    this.advisorService.getNotifications(filters, status)
      .subscribe(
        data => {

          /*let sortedData = data.sort((a, b) => {
            a = new Date(a.whenCreated);
            b = new Date(b.whenCreated);
            return b - a;
          });*/
          this.advisorNotifications = data.notifications;
          //Update internal notifications count.
          this.pendingNotificaitionsCount = data.pending;
          this.notificationsCounter.emit(this.pendingNotificaitionsCount);

          this.advisorNotifications.map((notification) =>{

            if(notification.metadata == undefined){
              notification['metadata'] = {};
            }

            if(Boolean(notification.metadata.area)){
              notification.metadata.area = Area.findAreaById(notification.metadata.area);
            }

            /*

              The client's email is now a prop of the metadata

            */
          })
        },
        error => {
          console.log(error);
          this.snackBarError.open("There was an error getting the notifications. " + error.error.message, "Ok", {
            duration: 6000,
            panelClass: 'error-snackbar'
          });
        });
  }

  /*deleteNotification(notificationID, notificationKey) {
    let url = `${this.deleteNotifications}${this.idAdvisor}/notifications/${notificationID}`;
    this.http.delete(url)
      .subscribe(
        response => {
          this.advisorNotifications.splice(notificationKey, 1);
        },
        error => {
          console.log(error);
          this.snackBarError.open("There was an error deleting this notification, please try again. " + error.error.message, "Ok", {
            duration: 6000,
            panelClass: 'error-snackbar'
          });
        }
      );
  }*/

  deleteNotification(notificationId: string){
    this.advisorService.deleteANotification(notificationId).then(() => this.getAllNotifications() );
  }

  readNotification(notificationId: string){
    this.advisorService.readANotification(notificationId).then(() => this.getAllNotifications() );
  }

  clearAll(e) {
    e.preventDefault();
    let url = `${this.deleteNotifications}${this.idAdvisor}/notifications`;
    this.http.delete(url)
      .subscribe(
        response => {
          this.notifications = [];
          this.advisorNotifications.splice(0);
          this.snackBarSuccess.open("All notifications have been deleted", "Ok", {
            duration: 3000,
            panelClass: 'success-snackbar'
          });
        },
        error => {
          console.log(error);
          this.snackBarError.open("There was an error deleting the notifications, please try again. " + error.error.message, "Ok", {
            duration: 6000,
            panelClass: 'error-snackbar'
          });
        }
      );
  }

  async linkToClient(notification: any) {

    if(Boolean(notification.metadata) && Boolean(notification.metadata.clientEmail) && !this.isLoading ){
      this.isLoading = true;
      let clientID = notification.clientId;
      this.advisorService.getClientById(clientID).then(clientResponse =>{
        this.currentClientService.initClient(clientID).then(r=>{
          this.clientDataService.setFullCurrentClient(clientResponse.client);
          this.isLoading = false;
        })
      })
      this.router.navigate([urlPrependRootContext('/dashboard')]);
    }
  }

  search() {
    let queryFilters = '';
    let queryStatus = '';
    for (const property in this.filterForm.value) {
      if (this.filterForm.value[property] === true) {
        queryFilters = queryFilters + property + ','
      }
    }
    for (const status in this.statusForm.value){
      this.statusForm.value[status] ? queryStatus = queryStatus + status + ',' : '';
    }

    this.getAllNotifications(queryFilters, queryStatus);
  }

  closeNotifications(){
    this.notificationsPanel.close();
  }

  markAllNotificationsAsRead(){
    this.advisorService.markAllNotificationsAsRead().then(() => this.getAllNotifications() )
  }

  ngOnDestroy(): void {
    if(this.notificationGeneratedSubscription != undefined){
      this.notificationGeneratedSubscription.unsubscribe();
    }
  }

}
