import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { filter } from 'rxjs/operators';

import { AdvisorService } from './shared/services/advisor.service';
import { AuthenticationService } from './shared/services/auth/authentication.service';
import { LayoutService } from './shared/services/layout.service';
import { RoutePartsService } from './shared/services/route-parts.service';
import { AppSocketIoService } from './shared/services/socket.io.service';
import { ThemeService } from './shared/services/theme.service';
import { WindowRef } from './shared/wrappers/window.service';
import { ClientService } from './shared/services/client/client.service';
import { DataPipe } from './shared/model/DataPipe.model';
import { DataManager } from './shared/classes/DataManager';
import { QuestionnaireHubService } from './shared/services/questionnaires/questionnaire-hub.service';
import { GeneralQuestionnaireService } from './shared/services/questionnaires/general-questionnaire.service';
import { reducerClientData, reducerDashboard, reducerGeneralQuestionnaire } from './shared/services/client/utils/reducers';
import { readFromStoragedObject } from './shared/helpers/utils';
import { ClientDataService } from './shared/services/client-data-service';



declare var gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {

  appTitle = 'FP Alpha';
  pageTitle = '';
  //Conection status

  connectionStatus = 'ONLINE';
  isConnected = true;

  isMobile: boolean;
  //
  private idAdvisor: string;


  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private themeService: ThemeService,
    private renderer: Renderer2,
    private advisorService: AdvisorService,
    private layoutService: LayoutService,
    private socketIO: AppSocketIoService,
    public snackBarSuccess: MatSnackBar,
    private authService: AuthenticationService,
    private winRef: WindowRef,
    public snackBarError: MatSnackBar,
    public dialog: MatDialog,
    public http: HttpClient,
    public currentClientService: ClientService,
    public clientDataService: ClientDataService,
    public generalQuestionnaireService: GeneralQuestionnaireService,
    public snackBar: MatSnackBar,
  ) {

    const navEndEvent$ = router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((e: NavigationEnd) => {
      gtag('config', 'UA-146782219-1', { 'page_path': e.urlAfterRedirects });
      console.log('refresh event');
      this.socketIO.vertifySocketOrReconnect();
    });


    let subscription = router.events.subscribe(async (event) => {
      if (event instanceof NavigationStart) {
        //localStorage.setItem('Navigated', !router.navigated ? 'Yes' : 'No');

        // The estate questionnaire uses the http socket.
        if(event.url.includes('questionnaire-estate')) {
          if(localStorage.getItem('socketType') === 'WS'){
            localStorage.setItem('socketSilentMode', 'true');
            this.http.post(environment['apiFlushNotifications'], {} ).toPromise().then(response => {
              this.socketIO.shiftSocketMode();
            }).catch(error=>{
              console.log("Coudn't flush notificaitions. Connecting to alternative socket" + error);
              this.socketIO.shiftSocketMode();
            })
          }
        }
        if(event.url.includes('advisor/clients') && localStorage.getItem('clientIDX')) {
          let clientID = localStorage.getItem('clientIDX');
          console.log('remove clietn context:', clientID);
          localStorage.removeItem('clientIDX');
          sessionStorage.removeItem('currentClient');
          this.clientDataService.currentClientService.dataManager.clearAllLocalData(clientID);
        }
        //Is the client service active?
        let currentClientID = readFromStoragedObject('currentClient', 'clientId', 'Session');
        console.log('currentClientService.loaded', this.currentClientService.loaded, 'currentClientID', currentClientID);
        if(!this.currentClientService.loaded && Boolean(currentClientID)){
          await this.currentClientService.initClient(currentClientID).then(r=>{
            console.log('currentClientService initClient FINISH');
            console.log('loadingClientData.emit false');
          });
        }
      }
    });


    this.activeRoute.queryParams.subscribe(params => {
      //MGP Integration

      if(params['origin'] == 'integration' && params['source'] == 'mgp'){
        let data = {
          fullName: params['fullName'],
          email: params['email'],
          clientStatus: params['clientStatus'],
          status: params['status'],
          origin: params['source']
        }

        localStorage.setItem("mgp", 'true');
        localStorage.setItem("mgpData", JSON.stringify(data));

        this.router.navigate(['/advisor/clients']);

      }

      if(params['origin'] == 'integration' && params['source'] == 'orion'){
        let data = {
          fullName: params['fullName'],
          email: params['email'],
          clientStatus: params['clientStatus'],
          status: params['status'],
          origin: params['source']
        }

        localStorage.setItem("orion", 'true');
        localStorage.setItem("orionData", JSON.stringify(data));

        this.router.navigate(['/advisor/clients']);

      }

    });

    const  questionnaireHub = new QuestionnaireHubService(this.snackBar);
    const pipeConfig: DataPipe = {
      dashboard: {
        sources: [
          (idClient: string) => this.advisorService.getClientDashboardData(idClient),
          (idClient: string) => this.advisorService.getClientDashboardAdvisorData(idClient),
        ],
        reducers: [
          reducerDashboard
        ],
      },
      generalQuestionnaire: {
        sources: [
          (idClient: string) => questionnaireHub.getAnswers(this.generalQuestionnaireService, 'personal', null, idClient),
          (idClient: string) => questionnaireHub.getAnswers(this.generalQuestionnaireService, 'assets', null, idClient),
          (idClient: string) => questionnaireHub.getAnswers(this.generalQuestionnaireService, 'family', null, idClient),
          (idClient: string) => this.advisorService.getClientDashboardData(idClient),
        ],
        reducers: [
          reducerGeneralQuestionnaire
        ],
      },
      /*
      clientData: {
        sources: [
          (idClient: string) => questionnaireHub.getAnswers(this.generalQuestionnaireService, 'personal', null, idClient),
          (idClient: string) => questionnaireHub.getAnswers(this.generalQuestionnaireService, 'assets', null, idClient),
          (idClient: string) => questionnaireHub.getAnswers(this.generalQuestionnaireService, 'family', null, idClient),
          (idClient: string) => this.advisorService.getClientDashboardData(idClient),
        ],
        reducers: [
          reducerClientData
        ],
      }*/
    }
    const dataManager: DataManager = new DataManager(pipeConfig);
    this.currentClientService.setDataManager(dataManager)


  }

  ngOnInit() {

    this.isMobile = isMobileSize();

    this.changePageTitle();






    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {

      }else if(!(evt instanceof NavigationEnd)) {

      }
      window.scrollTo(0, 0);

    });

    //EASTER EGGS!!!
    let cursor = 0;
    const KONAMI_CODE = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65];
    document.addEventListener('keydown', (e) => {
      cursor = (e.keyCode == KONAMI_CODE[cursor]) ? cursor + 1 : 0;
      if (cursor == KONAMI_CODE.length) this.activate();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = isMobileSize();
  }

  ngAfterViewInit() {
    this.themeService.applyMatTheme(this.renderer);
  }

  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      var routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length)
        return this.title.setTitle(this.appTitle);
      // Extract title From Parts;
      this.pageTitle = routeParts
        .reverse()
        .map((part) => part.title)
        .reduce((partA, partI) => { return `${partA} > ${partI}` });
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }

  // onConnectionChange() {
  //   if (this.connectionStatus == 'OFFLINE') {
  //     this.snackBarSuccess.open("Network connection lost", '', {
  //       duration: 0,
  //       verticalPosition: 'top',
  //       panelClass: 'alert-snackbar'
  //     });
  //   } else {
  //     this.snackBarSuccess.dismiss();
  //     this.socketIO.initSocketAndSubscribeToDefaultEvents();
  //   }

  // }

  activate() {
    this.snackBarSuccess.open("Cheats activated", 'OK!', {
      duration: 0,
      verticalPosition: 'top',
      panelClass: 'success-snackbar'
    });
  }

  onPopoverMouseleave(event: any){
    let element = event.target;
    // element.innerHTML = '';
    // element.classList.remove('show');
    // element.style.display = 'none';
    const timeOutEvent: any = setTimeout(() => {
      element.innerHTML = '';
      element.classList.remove('show');
      element.style.display = 'none';
      console.log('Cosas aquí');
    }, 500);
    localStorage.setItem('popover_timeout', timeOutEvent)

    /*const popover = document.getElementById('popover');
    element.classList.remove('show');
    popover.style.display = 'none';
    popover.innerHTML = '';?*/
  }

  onPopoverMouseEnter(){
    const timeoutId: number = Number(localStorage.getItem('popover_timeout'));
    if (timeoutId) {
      clearInterval(timeoutId);
    }
  }

}

function isMobileSize(): boolean{
  let currentPathName = window.location.pathname;
  if(currentPathName === '/join'|| currentPathName === '/prospect/form-new/submitted' || currentPathName === '/prospect/form' ) return false; // Doesn't apply for prospect
  let windowSize:number = window.innerWidth;
  return windowSize <= 767;
}


