//Angular
import { Injectable, EventEmitter, Output } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Router } from "@angular/router";

//Project
import { Questionnaire } from './Questionnaire.model';
import { AdvisorService } from "../../shared/services/advisor.service";
import { AuthenticationService } from "../../shared/services/auth/authentication.service";
import { FileItem, ParsedResponseHeaders } from "ng2-file-upload";
import { FileUploaderExtended } from './FileUploaderExtended.model';
import { AREAS } from '../../shared/helpers/areas.catalog';
import { FileUploaderOptions } from "./file-uploader-component/file-uploader-component.component";
import { getEndPointUrlByContext, urlPrependRootContext } from '../../shared/helpers/url.helper'
import { SequentialJobsTimerDispatcher, SequentialJob, MINUTES_TO_MS } from "../../shared/helpers/SequentialJobsTimerDispatcher";
import { isUrlOutOfClientOrAdvisorContext } from '../../shared/helpers/url.helper';
import { DialogConfirm } from '../../shared/components/dialog-confirm/dialog-confirm.component';
import { DialogValidDocument } from "./dialog-doc-valid/dialog-doc-valid.component";
import { DialogConfirmSend } from './dialog-confirm-send/dialog-confirm.component';
import { DialogInvalidDocumentComponent } from './dialog-invalid-document/dialog-invalid-document.component'
import { AppSocketIoService } from "app/shared/services/socket.io.service";
import { EstateSuccessDialogComponent } from "../../../../estate-success-dialog/estate-success-dialog.component";
import { initPendo } from 'app/shared/helpers/pendo.helper';
import { MatSnackBar } from "@angular/material/snack-bar";

export const QUEST_CHANGE_SENT = 'SENT';
export const QUEST_CHANGE_SENDING = 'SENDING';
export const QUEST_CHANGE_INITIAL = 'INITIAL';

@Injectable({
    providedIn: "root"
})
export class QuestionnaireController {

    public autoSaveJob: SequentialJob = null;
    @Output() questionnaireSentChanges = new EventEmitter<string>();

    constructor(
        private http: HttpClient,
        public advisorService: AdvisorService,
        public dialog: MatDialog,
        private router: Router,
        private authService: AuthenticationService,
        private socketInstance: AppSocketIoService,
        public snackBar: MatSnackBar
    ) {
      this.socketInstance.vertifySocketOrReconnect();
      this.pendoInit();
    }

    /**
     * @name loadQuestionnaireDataFromUrlToModel
     * @description Retrive questionnare data given an url, then load the data into the specifieded model.
     * @param questDataUrl GET data from given url.
     * @param model An instance of the model to load the data
     */

    async loadQuestionnaireDataFromUrlToModel(questDataUrl: string, model: Questionnaire){
        return new Promise(async (resolve, reject) => {
            try {
                let questData = await this.http.get<any>(questDataUrl).toPromise();
                model.populateWithArrayOfObjects(questData.answers);

                return resolve(true)
            } catch (error) {
                console.log('Error at loadQuestionnaireDataFromUrlToModel: ' + error);
                return reject(false);
            }
        })
    }

    /**
     *
     * @param clientID
     * @param area
     * @param model
     * @param isDataAviable
     */
    loadQuestionnaireData(clientID: string, area: string, model: Questionnaire, isDataAviable: boolean = true, index: number = 1) {
        if (isDataAviable == true) {
            this.advisorService.getClientQuestionnaireAsync(clientID, area, index).then(
                data => {
                    model.populateWithArrayOfObjects(data.answers);
                },
                error => {
                    console.log(error);
                }
            );
        } else {

        }
    }

    async loadQuestionnaireDataAsync(clientID: string, area: string, model: Questionnaire, index: number = 1) {
        try {
            console.log('Index: ', index);
            const data: any = await this.advisorService.getClientQuestionnaireAsyncV2(clientID, area, index);
            if (data) {
                model.populateWithArrayOfObjects(data.answers);
                return model
            }
        } catch(e) {
            console.log(e);
        }
        return;
    }

    /**
     *
     * @param window
     * @param model
     * @param clientID
     * @param area
     */
    submitForm(window, model: Questionnaire, clientID: string, area: string, saveForLater: boolean, beforeOnSuccess: any = null, silenceMode: boolean = false, hasManualInput: boolean = null, isManualValidation: boolean = false, originalModel = null, otherIndex = null) {



        //If user type is client, then he only can save with save for later.
        //if (this.authService.isClient()) saveForLater = true;
        console.log('MODELO RECIBIDO: ', model);
        //
        let whoAnswered = this.authService.isClient() ? 'Client' : 'Advisor';
        let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        let selectedIndex = otherIndex || sessionStorage.getItem(area + 'QuestIndex');
        let questIndex = selectedIndex != null ? selectedIndex : '1';
        let url = getEndPointUrlByContext('Questionnaire') + clientID + "/questionnaire/" + area + (saveForLater ? '?saveLater=true' : '?saveLater=false') + `&index=${questIndex}`;
        if (hasManualInput != null) url = url.concat(hasManualInput ? '&hasManualInput=true' : '&hasManualInput=false');
        let processQuestionnaire = () => {
            // If its a submit, set progress.
            if (!saveForLater) {
                this.stopAutoSave();
                if (model['progressBar'] != undefined) { model['progressBar'] = 100; }
                if (model['progressCompleted'] != undefined) model['progressCompleted'] = true;
                if (model['progressTab'] != undefined) model['progressTab'] = 0;
            }
            this.http.post(url, model.toSaveFormat(whoAnswered), options)
                .subscribe(
                    response => {
                        //Reload session answered questionnares array
                        let currentClientId = JSON.parse(sessionStorage.currentClient).clientId;
                        this.advisorService.getClientDashboardData(currentClientId).subscribe(response => {
                            let respondedQuests = response.clientHasAnsweredQuestionnaires;
                            let clientHasAnsweredQuestionnaires = {};
                            /* git  */
                            for (let index in respondedQuests) {
                                let areaIndexNewFormat = 'ans' + index.charAt(0).toUpperCase() + index.slice(1);

                                clientHasAnsweredQuestionnaires[areaIndexNewFormat] = respondedQuests[index];
                            }
                            if (JSON.stringify(model) === JSON.stringify(originalModel) && (!model['progressBar'] || model['progressBar'] <= 0)) {
                                clientHasAnsweredQuestionnaires['Fulltax'] = false;
                            }
                            sessionStorage.clientHasAnsweredQuestionnaires = JSON.stringify(clientHasAnsweredQuestionnaires);
                            //
                            if (beforeOnSuccess != null && typeof beforeOnSuccess == 'function') {

                                beforeOnSuccess();
                            }
                            //if (selectedIndex != null) this.deleteStoragedQuestionnareDatByArea(area); // Remove helper vars from session storage

                            if (!silenceMode) {
                                this.onSuccess(window, saveForLater); // Call onSuccess interaction
                            }
                        });
                        // Stop auto save if submit action is detected

                    },
                    error => {
                        this.onError(window);
                    }
                );
        }
        if (this.authService.isClient() && !saveForLater ){
          // On the client side, the dialog interaction isn't needed

          //Ask for notification adding a query param
          url += '&send_client_notification=true';
          processQuestionnaire();
          this.toHub();
        }else if (saveForLater == false) {

            let onConfirm = () => { this.stopAutoSave(); processQuestionnaire(); }
            let onCancel = () => { if(window['questionnaireSent'] != undefined){ window['questionnaireSent'] = false; }}
            this.throwConfirmationDialogInteraction("The questionnaire will be submitted, please choose an action to continue.", onConfirm, onCancel, area);
        } else if (saveForLater && !silenceMode && !isManualValidation) {

            processQuestionnaire();
            this.toHub();
        } else if (saveForLater && !silenceMode && isManualValidation) {

            processQuestionnaire();
            this.toDashboard();
        }else {

            processQuestionnaire();
        }
    }

    static async saveQuestionnaireGeneric(httpClientInstance: HttpClient, questionnareModel: Questionnaire, area: string, clientID: (string | number), questionnareIndex: number = 1, whoAnswered: number = 1, saveForLater: boolean = false, isGeneralQuest: boolean = false): Promise <any> {
        let whoAnsweredString = whoAnswered == 1 ? 'Advisor' : whoAnswered == 2 ? 'Client' : 'Advisor';
        let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        let url = getEndPointUrlByContext('Questionnaire') + clientID + (isGeneralQuest ? "/generalquest/" : "/questionnaire/" + area )  + (saveForLater ? '?saveLater=true' : '?saveLater=false') + `&index=${questionnareIndex}`;
        return httpClientInstance.post(url, questionnareModel.toSaveFormat(whoAnsweredString), options).toPromise();
    }

    /**
     * @name deleteStoragedQuestionnareDatByArea
     * @description Delete the data related to a multiquest from session storage given an area id.
     * @param areaId
     */
    deleteStoragedQuestionnareDatByArea(areaId: string) {
        sessionStorage.removeItem(areaId + 'QuestIndex');
        sessionStorage.removeItem(areaId + 'Name');
        sessionStorage.removeItem(areaId + 'HasBeenAnswered');
    }

    /**
    * @name deleteAllStoragedQuestionnareData
    * @description Delete all the data related to a multiquest from session storage given the full list of areas.
    */
    deleteAllStoragedQuestionnareData() {
        AREAS.forEach(area => {
            this.deleteStoragedQuestionnareDatByArea(area.id);
        });
    }

    getStoragedQuestionnareDatByArea(area: string): { index: number, name: string, answered: boolean } {
        return {
            index: Number(sessionStorage.getItem(area + 'QuestIndex')),
            name: String(sessionStorage.getItem(area + 'Name')),
            answered: sessionStorage.getItem(area + 'HasBeenAnswered') === "true"
        }

    }

    async getEstateFilesStored(clientId: string){
        return new Promise(async (resolve, reject) => {
            try {
                const ans = await this.advisorService.getEstateFilesFromClient(clientId);
                resolve(ans);
            } catch(e) {
                reject(e);
            }
        })
    }

    getStoragedQuestionnareDatByAreV2({ area, index }): { index: number, name: string, answered: boolean } {
        return {
            index: Number(sessionStorage.getItem(index)),
            name: String(sessionStorage.getItem(area + 'Name')),
            answered: sessionStorage.getItem(area + 'HasBeenAnswered') === "true"
        }
    }

    /*     static setStoragedQuestionnareData(area:string, index:number, name:string, answered: boolean){
            sessionStorage.setItem(area + 'QuestIndex', String(index));
            sessionStorage.setItem(area + 'Name', name);
            sessionStorage.setItem(area + 'HasBeenAnswered', String(answered));
        }
     */


    /**
     * getUploadUrl(string, string, window) : string
     * Returns the upload url given the client, section and window (environment) context
     * @param clientID
     * @param section
     * @param window
     */
    getUploadUrl(window: any, clientID: string, section: string, sectionExtended: boolean = false, index: number = 1, fileName: string = 'NA', nameOfInsuranceCompany: string = 'N/A'): string {
        return this.advisorService.dynamicIPAddress(
            window,
            getEndPointUrlByContext('Questionnaire') + clientID + (sectionExtended ? section : '/fileupload/' + section) + (`?index=${index}&policyFilename=${fileName}&nameOfInsuranceCompany=${nameOfInsuranceCompany}`),
        );
    }

    /**
 * @name setUrlPolicyParam(uploader: FileUploaderExtended, policyInsurnace: string) : void
 * @param uploader
 * @param policyInsurnace
 * @description Set the url for a given FileUploaderExtended appending the nameOfInsuranceCompany parameter to the url.
 */

    setUploaderInsuranceCompanyParam(uploader: FileUploaderExtended, policyInsurnace: string) {

        var url = new URL(uploader.currentUrl);
        var search_params = new URLSearchParams(url.search);
        search_params.set('nameOfInsuranceCompany', policyInsurnace);
        url.search = search_params.toString();
        uploader.setUrl(url.toString());

    }

    /**
     * fileUploaderFactoryFromUrl(uploadURL: string): FileUploader | null
     * Returns a new FileUploader instance with specific init values.
     * @param uploadURL
     */
    public fileUploaderFactoryFromUrl(uploadURL: string) {
        let sessionToken = localStorage.getItem("sessionToken");
        let fileUploader = null;
        if (sessionToken != null) {
            fileUploader = new FileUploaderExtended({
                url: uploadURL,
                authTokenHeader: "Authorization",
                authToken: `Bearer ${sessionToken}`,
                itemAlias: "clientIDFiles",
                queueLimit: 1
            });
            //Set uploader beahvior properties
            fileUploader.onAfterAddingFile = file => {
                file.withCredentials = false;
            };

            fileUploader.onCompleteItem = (
                item: any,
                response: any,
                status: any,
                headers: any
            ) => {

            };

            fileUploader.onErrorItem = (
                item: FileItem,
                response: string,
                status: number,
                headers: ParsedResponseHeaders
            ) => {
                let error = JSON.parse(response);

            };
        }
        return fileUploader;
    }

    /**
 * fileUploaderFactoryFromUrl(uploadURL: string): FileUploader | null
 * Returns a new FileUploader instance with specific init values.
 * @param uploadURL
 */
    public fileUploaderFactoryFromUrlAutoUpload(uploadURL: string, queueLimit: number = 1, isAsync = false) {
        let sessionToken = localStorage.getItem("sessionToken");
        let fileUploader = null;
        if (sessionToken != null) {
            fileUploader = new FileUploaderExtended({
                url: uploadURL,
                authTokenHeader: "Authorization",
                authToken: `Bearer ${sessionToken}`,
                itemAlias: "clientIDFiles",
                queueLimit,
                autoUpload: false,
                allowedMimeType: ['application/pdf']
            });

            fileUploader.onProgressAll = (progress => {

            });
            //Set uploader beahvior properties
            fileUploader.onAfterAddingFile = file => {
                file.withCredentials = false;
            };
            fileUploader.onBeforeUploadItem = file => {
                let sessionToken = localStorage.getItem("sessionToken");
                fileUploader.refreshTokenUrl(sessionToken);

            };
            fileUploader.onCompleteItem = (
                item: any,
                response: any,
                status: any,
                headers: any
            ) => {

            };

            fileUploader.onErrorItem = (
                item: FileItem,
                response: string,
                status: number,
                headers: ParsedResponseHeaders
            ) => {
                if (response) {
                    let error = JSON.parse(response);

                }
            };
            return fileUploader;
        }
    }

    /**
     *
     * @param window
     * @param clientID
     * @param section
     */
    fileUploaderFactory(window: any, clientID: string, section: string, extendedSection: boolean = false, index: number = 1, fileName: string = 'NA') {
        return this.fileUploaderFactoryFromUrl(this.getUploadUrl(window, clientID, section, extendedSection, index, fileName));
    }

    fileUploaderFactoryEstate(window: any, clientID: string, section: string, extendedSection: boolean = false, index: number = 1, fileName: string = 'NA') {
        return this.fileUploaderFactoryFromUrl(this.getUploadUrl(window, clientID, section, extendedSection, index, fileName));
    }

    fileUploaderFactoryAutoUpload(window: any, clientID: string, section: string, extendedSection: boolean = false, index: number = 1, fileName: string = 'NA', queueLimit: number = 1, isAsync = false, insuranceCompany: any = null) {
        return this.fileUploaderFactoryFromUrlAutoUpload(this.getUploadUrl(window, clientID, section, extendedSection, index, fileName, insuranceCompany), queueLimit, isAsync);
    }

    /**
     *
     * @param window
     * @param fileUploaders
     * @param onSuccessCallback
     */
    submitFiles(window: any, fileUploaders: FileUploaderExtended[], onSuccessCallback) {

        let successUploadsArray = Array(fileUploaders.length).fill(0);

        let markAndVerifyUpload = (i) => {
            successUploadsArray[i] = 1;
            if (this.areFilesUploaded(successUploadsArray)) {
                onSuccessCallback();
            }
        };

        fileUploaders.forEach(
            (fileUploader, i) => {
                if (fileUploader.queue.length > 0) {
                    fileUploader.onSuccessItem = (
                        item: FileItem,
                        response: string,
                        status: number,
                        headers: ParsedResponseHeaders
                    ) => {
                        markAndVerifyUpload(i);
                    };
                    fileUploader.uploadAll();

                } else { //No file to upload


                    //Check if the file is not required, to markit as completed.
                    if (!fileUploader.required && fileUploader.required != undefined) {
                        markAndVerifyUpload(i);
                    } else {
                        //Error file is required
                        QuestionnaireController.throwErrorInteraction(window, "Please upload a policy to continue.");
                    }


                }
            });
    }

    /**
     *
     * @param formView
     */
    onSuccess(formView, saveForLater = false) {

        let message = saveForLater ? "Your information has been saved for later, you are being redirected." : "Your information has been sent, you are being redirected.";
        formView.snackBarSuccess.open(message, "Ok", {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: 'success-snackbar'
        });
    }

    toHub() {
      setTimeout(() => {
        this.router.navigate(['/hub']);
      }, 3000);
    }

    toPreview() {
        setTimeout(() => {
            this.router.navigate(['/questionnaire-general-preview']);
        }, 3000);
    }

    toPlanningAreas(area) {
      console.log('pasarán 5 segundos areas');
      this.snackBar.open("You will be redirected to Insights.", "Ok", {
        duration: 5000,
        panelClass: 'success-snackbar'
      });
      setTimeout(() => {
        console.log('pasaron 5 segundos areas');
        this.router.navigate([urlPrependRootContext('/areas/' + area + '/opportunities')]);
      }, 8000);
    }

    toTaxSummary() {
      console.log('pasarán 5 segundos toTaxSummary');
      this.snackBar.open("You will be redirected to Tax Snapshot.", "Ok", {
        duration: 5000,
        panelClass: 'success-snackbar'
      });
      setTimeout(() => {
        console.log('pasaron 5 segundos toTaxSummary');
        this.router.navigate([urlPrependRootContext('/summary_new/tax')]);
      }, 8000);
    }

    toDashboard() {
        setTimeout(() => {
            this.router.navigate([urlPrependRootContext('/dashboard')]);
        }, 3000);
    }

    toOppotunities () {
        setTimeout(() => {
            this.router.navigate([urlPrependRootContext('/        advisor/areas/fulltax/opportunities')]);
        }, 3000);
    }

    /**
     *
     * @param formView
     */
    onError(formView) {
        console.log("Something went wrong sending the questionnaire.");
    }

    /**
     * throwErrorInteraction()
     * Show an error alert on the UI
     * @param formView
     * @param message
     * @param buttonText
     */
    static throwErrorInteraction(formView, message: string, buttonText: string = 'Dismiss') {

        formView.snackBarError.open(message, buttonText, {
            duration: 6000,
            verticalPosition: 'top',
            panelClass: 'error-snackbar'
        });
    }

    /**
     * throwSuccessInteraction()
     * Show an success alert on the UI
     * @param formView
     * @param message
     * @param buttonText
     */
    throwSuccessInteraction(formView, message: string, buttonText: string = 'Ok') {

        formView.snackBarSuccess.open(message, buttonText, {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: 'success-snackbar'
        });
    }

    /**
     *
     */

    throwConfirmationDialogInteraction(message: string, onConfirm: any, onCancel: any = null, area: string) {

        // Old state dialog
        let dialogRef;
        if (area === 'estate' && !localStorage.getItem('estate-error')) {
            dialogRef = this.dialog.open(EstateSuccessDialogComponent, {
                data: {
                    title: "We have received your request.",
                    advise: 'It can take up to 3-5 business days after submission to receive The Estate Snapshot.',
                },
                panelClass: 'modal-dialog-questionnaire-estate',
                width: '804px',
            });
        } else {
            dialogRef = this.dialog.open(DialogConfirmSend, {
                data: {dialogMessage: message, areaIn: area},
                panelClass: 'modal-dialog-questionnaire',
                width: '400px',
            });
        }





        dialogRef.afterClosed().subscribe(result => {
            if (result === 'to hub') {
                onConfirm();
                this.toHub();
            } else if (result === 'to areas') {

                onConfirm()
                this.toPlanningAreas(area);
            } else if (result === 'to summary') {

                onConfirm()
                this.toTaxSummary();
            } else if (result === 'to opportunities') {
                onConfirm();
                this.toPlanningAreas(area);
            } else {
                if (typeof onCancel == "function") onCancel();
            }
        });
    }

    throwConfirmationInteraction(message: string, onConfirm: any, onCancel: any = null) {
        const dialogRef = this.dialog.open(DialogConfirm, {
            data: message
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                onConfirm();
            } else {
                if (typeof onCancel == "function") onCancel();
            }
        });
    }

    onCancelSubmit(original: any = null, modified: any = null, otherRoute: string = null) {
        let isEqual = true;
        if (!original || !modified) {
            this.throwConfirmationInteraction("The changes recently made to the questionnaire will be lost, confirm to proceed",
            () => {
                this.deleteAllStoragedQuestionnareData();

                if (this.router.url.includes('questionnaire-estate')) {
                    this.router.navigate(['/estate-documents'], {
                        queryParams: {
                            s: 3
                        }
                    });
                } else if (otherRoute) {
                    this.router.navigate([otherRoute]);
                } else {
                    this.router.navigate(['/hub']);
                }

            });
        } else {
            Object.keys(original).forEach((key) => {
                if (
                    key.includes('OneAge') ||
                    key.includes('TwoAge') ||
                    key.includes('ThreeAge') ||
                    key.includes('FourAge')
                    ) {
                        if (original[key] % 1 > 0 && modified[key] % 1 === 0) {
                            isEqual = isEqual && true;
                        }
                } else if(
                    key.includes('clientIDNetWorth') ||
                    key.includes('clientIDBusinessValue_')
                ) {
                    if (typeof modified[key] === 'string') {
                        let aux = parseInt(modified[key].split(',').join(''), 10);
                        if (aux == original[key]) {
                            isEqual = isEqual && true;
                        } else {
                            isEqual = isEqual && false;
                        }
                    } else if (modified[key] === original[key]) {
                        isEqual = isEqual && true;
                    } else {
                        isEqual = isEqual && false;
                    }
                } else if (key.includes('Birthday')) {
                    const oriDate = new Date(original[key]).toLocaleDateString('en-US', {
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric'
                    });
                    const modDate = new Date(modified[key]).toLocaleDateString('en-US', {
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric'
                    });
                    if (oriDate == modDate) {
                        isEqual = isEqual && true;
                    } else {
                        isEqual = isEqual && false;
                    }
                } else if (typeof modified[key] === 'string' && typeof original[key] === 'number') {
                    let aux = parseInt(modified[key].split(',').join(''), 10);
                    if (aux == original[key]) {
                        isEqual = isEqual && true;
                    } else {
                        isEqual = isEqual && false;
                    }
                } else if (original[key] == modified[key]) {
                    isEqual = isEqual && true;
                } else {



                    isEqual = isEqual && false;
                }
            });
            if (!isEqual) {
                this.throwConfirmationInteraction("The changes recently made to the questionnaire will be lost, confirm to proceed",
                () => {
                    this.deleteAllStoragedQuestionnareData();

                    if (this.router.url.includes('questionnaire-estate')) {
                        this.router.navigate(['/estate-documents'], {
                            queryParams: {
                                s: 3
                            }
                        });
                    } else if (otherRoute) {
                        this.router.navigate([otherRoute]);
                    }  else {
                        this.router.navigate(['/hub']);
                    }
                });
            } else {

                if (this.router.url.includes('questionnaire-estate')) {
                    this.router.navigate(['/estate-documents'], {
                        queryParams: {
                            s: 3
                        }
                    });
                } else if (otherRoute) {
                    this.router.navigate([otherRoute]);
                }  else {
                    this.router.navigate(['/hub']);
                }
            }
        }
    }

    onCancelSubmitToPreview(){
        this.throwConfirmationInteraction("The changes recently made to the questionnaire will be lost, confirm to proceed",
            () => {
                this.deleteAllStoragedQuestionnareData();
                this.router.navigate(['/questionnaire-general-preview']);
            });
    }

    /**
     * areFilesUploaded(successUploadsArray: number[]): boolean
     * @description Returns true or false depending on whenever a file inside the array is uploaded.
     * @param successUploadsArray
     */
    private areFilesUploaded(successUploadsArray: number[]): boolean {

        if (typeof successUploadsArray == 'undefined' || successUploadsArray.length <= 0) {
            return false;
        }



        let generalStatus = true;
        successUploadsArray.forEach(val => {
            generalStatus = generalStatus && (val == 1);

        });
        return generalStatus;
    }

    private async asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    /**
     *  Get the extractions results given an area and index
     */
    async loadExtractionsResults(clientId: string, area: string, index: string): Promise<any[]> {
        let finalResponse = [];
        await this.advisorService.getQuestionnaireExtractionResults(clientId, area, index).then((extractionResults: any[]) => {
            extractionResults.forEach(extractionResult => {
                finalResponse[extractionResult.fileID] = extractionResult.validationStatus;
            });
        });
        return finalResponse
    }

    uploadersAreReady(uploaders: FileUploaderOptions[]): boolean {
        return uploaders.map(uploaderOptions => uploaderOptions.isAllValid()).reduce((a, b) => a && b);
    }

    /**
     * getUploaderValidationState
     * @param uploaderId
     * @param extractionResults
     */

    getUploaderValidationState(uploaderId: string, extractionResults: any[]): string {
        let existentResult = extractionResults.filter(extraction => { return extraction.fileID == uploaderId; })
        return existentResult[0] != undefined ? existentResult[0].validationStatus : '';
    }

    getFileUploadresValidationState(uploaderId: string, extractionResults: any[]): string {
        let existentResultMap = extractionResults.map(val => {
            return {}
        });
        let existentResult = extractionResults.filter(extraction => { return extraction.fileID == uploaderId; })
        return existentResult[0] != undefined ? existentResult[0].validationStatus : '';
    }

    /**
     * Verify all new file uploaders are ready, valid and then call submit form.
     * @param fileUploadersArray
     * @param submitFormFunction
     */

    verifyFileUploadersOptionsAreValidAndCallSubmit(fileUploadersArray: FileUploaderOptions[], submitFormFunction: Function, formView: any, isSaveForLater: boolean = false) {
        let areFileUploadersReady: boolean = fileUploadersArray.map(uploaderOptions => uploaderOptions.isAllValid()).reduce((a, b) => a && b);
        if (isSaveForLater || areFileUploadersReady) {
            submitFormFunction();
        } else {
            QuestionnaireController.throwErrorInteraction(formView, "You have an upload element that is not valid or is required. Please make sure all your uploaded elements are valid");
        }
    }

    initAutoSave(submitFunction: any, timeMin: number = 1.5): SequentialJobsTimerDispatcher {
        let sequentialJobWorker = new SequentialJobsTimerDispatcher();
        sequentialJobWorker.listOfSequentialWorks = [
            new SequentialJob(timeMin * MINUTES_TO_MS, submitFunction(), 'autoSaveJob'),
        ]
        sequentialJobWorker.restartAllJobs();
        return sequentialJobWorker;
    }

    initAutoSave2(submitFunction: any, timeMin: number = .5) {
        /* this.autoSaveJob = setInterval(() => {
            submitFunction();
        }, timeMin * MINUTES_TO_MS); */

        // Add to the global counter the update task. The task will execute only if the context is a questionnaire.

        //Wrap the function to execute only if on a questionnaire context.
        console.log('Init auto save');
        let wrapFunction = () => {
            console.log('Auto save call');
            if (isUrlOutOfClientOrAdvisorContext(window.location.pathname)) {
                console.log('Execute auto save');
                if(localStorage.getItem('sessionToken')){ // The current session is active
                submitFunction();
                }else{
                  console.log('top', 'ERROR. Auto save out of session context');
                }
            };
        }
        let jobsDispatcherRef = this.authService.sequentialJobWorker;
        this.autoSaveJob = new SequentialJob(timeMin * MINUTES_TO_MS, wrapFunction, 'autoSaveJob')
        jobsDispatcherRef.updateOrInstertSequentialJob(this.autoSaveJob);
    }

    stopAutoSave() {
        this.authService.sequentialJobWorker.stopOneJob('autoSaveJob');
    }

    ngOnDestroy(): void {

    }

    async loadExtractionsDataToModel(callerModel: Questionnaire, modelRef: any, clientId: string, areaName: string, index: number = 1) {
        if(this.autoSaveJob) { this.autoSaveJob.isActive = false; }
        let modelWithExtractions = new modelRef();
        await this.loadQuestionnaireDataAsync(clientId, areaName, modelWithExtractions, index);
        Questionnaire.updateModelFromModel(callerModel, modelWithExtractions, callerModel['getExtractionsAtributes']());
        if(this.autoSaveJob) { this.autoSaveJob.isActive = true; }
    }

    openModalWhatToDoNext(callerQuestionnaireRef) {
        let dialogRef = callerQuestionnaireRef.dialog.open(DialogValidDocument, {
            panelClass: 'modal-dialog-questionnaire',
            height: '310px',
            width: '500px',
            data: { area: 'fulltax' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === 'submit') callerQuestionnaireRef.submit();
        })
    }

    openInavlidDocDialog(callerQuestionnaireRef, idClient: string, areaName: string, assetIndex: number, fileName: string, changeToManualInputFunc = null) {
        let dialogRef = callerQuestionnaireRef.dialog.open(DialogInvalidDocumentComponent, {
            panelClass: 'modal-dialog-questionnaire',
            width: '500px',
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result === 'retainFile') {
                this.advisorService.retainAreaFile(idClient, areaName, assetIndex, fileName).then(response => {
                    console.log("Retain file.", response);
                })
            }
            else if (result === 'manualInput') {
                if (typeof changeToManualInputFunc === 'function') {
                    changeToManualInputFunc();
                } else {
                    callerQuestionnaireRef.model['clientIDWantsToInputPolicyData'] ? callerQuestionnaireRef.model['clientIDWantsToInputPolicyData'] = 'Yes' : null;
                }
                console.log('manualInputl');
            }
            else if (result === 'cancel') { console.log('Submit response cancel'); }
        })
    }

    validateRequireQuestions(window:any, requiredQuestions:any){
        requiredQuestions.forEach(element => {
            if(element == 'udefined'){
                return false;
            }
        });
    }

    validateRequiredQuestions(formView) {
        console.log("Error requiered questions");
        formView.snackBarError.open('You need to answer the required questions', "Ok", {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: 'error-snackbar'
        });
    }

  /**
   * @name pendoInit
   * @description Initializes the Pendo script if a token was provided. This script reads the props set on advisorInfo inside Local storage.
   * @param none
   */
  pendoInit(){
    console.log('Initializing Pendo on Questionnaires...💿');
    initPendo();
  }

}
