import { getElementHeight, getRandomStr } from "app/shared/helpers/utils";
import { table } from "console";
import { dataBalance } from "../dataBalance";


export function balanceSheetCutter(balanceData: any, scenario: any = 'today') {
  let pages: any = [];
  let heigthPerPage = 500;
  let dictionaryScenarios = {
    'today': 'today',
    1: 'scenario1',
    2: 'scenario2',
    3: 'scenario3'
  }
//se crea diccionario con la key de las tablas
  let dictionarySectionsIds = {
    tableEstateAssetsInside: {
      //primaryId: corresponde a toda la sección en el html
      primaryId: scenario + '_main_section_inside',
      //graphId: corresponde unicamente al espacio del html de la gráfica
      graphId: '',
      //tableId: corresponde a la tabla
      tableId: scenario + '_table_inside',
      //graphKey: corresponde a la gráfica correspondiente a la tabla
      graphKey: '',
      //keyRow: es el key para poder identificar el row en la vista de html, nos sirve para sacar la altura
      keyRow: 'inside',
      //spaceHeader: es el espacio que se debe dejar en el header, se usa cuando se va partiendo la tabla para saber cuanto dejar por los margenes, títulos, subtítulos, etc.
      spaceHeader: 30 + 26 + 12 + 38 + 32
    },
    tableEstateAssetsOutside: {
      primaryId: scenario + '_main_section_outside',
      graphId: scenario + '_graph_outside',
      tableId: scenario + '_table_outside',
      graphKey: 'doughnutOutsideEstate',
      keyRow: 'outside',
      spaceHeader: 32 + 26 + 12
    },
    tableLiabilities: {
      primaryId: scenario + '_main_section_liabilities',
      graphId: scenario + '_graph_liabilities',
      tableId: scenario + '_table_liabilities',
      graphKey: 'doughnutLiabilitiesBreakdown',
      keyRow: 'liebilities',
      spaceHeader: 32 + 26 + 12
    },
    tableTotals: {
      primaryId: scenario + '_main_section_totals',
      graphId: '',
      tableId: scenario + '_table_totals',
      keyRow: 'totals',
      spaceHeader: 32 + 26 + 12
    }
  };

  //this function allows to add a new page in the pages array, this array its returned in the end of the function
  let addPage = () =>{
    let page = {
      title: '',
      data: {},
      uid: getRandomStr(12),
      maxHeigth: heigthPerPage,
      sectionName: '',
      availableSpace: 0
    }
    pages.push(page);
  };

  addPage();


  /**
   * This function returns only a section of the all data received
   *
   * @param {*} section
   * @returns {dataSection}
   */
  let getDataOfSection = (section) => {
    let directData = ['estateBreakdown', 'ownerShipBreakDown'];
    if(directData.includes(section)){
      return balanceData[section]
    }
    return balanceData[dictionaryScenarios[scenario]][section];
  };


  let getItemsOfSection = (sectionData, remaingSpace, sectionName, firstPageBySection) => {
    //console.log('paginas actuales', JSON.parse(JSON.stringify(pages)), pages.length);
    let spaceHeader = firstPageBySection ? dictionarySectionsIds[sectionName].spaceHeader : 90;
    console.log('sectionData -> ', remaingSpace, sectionName, sectionData);
    let nodeHTMLElement;
    let node_height = 0;

    let copySectionData = JSON.parse(JSON.stringify(sectionData));
    if(!sectionData){
      console.log('No hay datos por mostrar', sectionData);
      return;
    }


    let heigthRows = [];
    let rowsPerPage = [];
    if(firstPageBySection){
      console.log('Primer parte de la sección', sectionName, remaingSpace);
      firstPageBySection = false;
      nodeHTMLElement = document.getElementById(dictionarySectionsIds[sectionName].primaryId);
      if(!nodeHTMLElement) {console.log('undefined elemnt with Id: ', dictionarySectionsIds[sectionName].primaryId)}
      node_height = getElementHeight(nodeHTMLElement);
      console.log('node', dictionarySectionsIds[sectionName].primaryId, node_height, remaingSpace);

      //verifica si entra todo el nodo en el if
      if(node_height <= remaingSpace){
        pages[pages.length - 1].data[sectionName] = sectionData;
        pages[pages.length - 1].data[dictionarySectionsIds[sectionName].graphKey] = getDataOfSection(dictionarySectionsIds[sectionName].graphKey);
        // addPage();
        remaingSpace -= node_height;
        pages[pages.length - 1].maxHeigth = remaingSpace;
        pages[pages.length - 1].availableSpace = remaingSpace;
        console.log('Entra todo el nodo', sectionName, dictionarySectionsIds[sectionName].graphKey, pages);

        return;
      }else{
        //entra en este caso si no entra
        nodeHTMLElement = document.getElementById(dictionarySectionsIds[sectionName].graphId);
        let graph_height;
        if(nodeHTMLElement){
          graph_height = getElementHeight(nodeHTMLElement);
        }
        //verifica si la grafica entra
        if(graph_height <= remaingSpace){
          console.log('Entra solo la grafica', sectionName, dictionarySectionsIds[sectionName].graphKey);
          pages[pages.length - 1].data[dictionarySectionsIds[sectionName].graphKey] = getDataOfSection(dictionarySectionsIds[sectionName].graphKey);
          remaingSpace -= spaceHeader;
          console.log('resta espacio de header', remaingSpace);
        }else{
          //no entra la grafica y entonces añade una hoja
          console.log('No entra la gráfica')
          if(graph_height){
            addPage();
            remaingSpace = heigthPerPage;
            console.log("se añade pag para grafica", remaingSpace);

            nodeHTMLElement = document.getElementById(dictionarySectionsIds[sectionName].primaryId);
            node_height = getElementHeight(nodeHTMLElement);
            //console.log("otra vez", node_height);
            //verifica nuevamente si entra todo el nodo
            if (node_height <= remaingSpace) {
              remaingSpace -= node_height;
              pages[pages.length - 1].maxHeigth = remaingSpace;
              pages[pages.length - 1].availableSpace = remaingSpace;
              // //console.log( "section in page", remaingSpace, sectionName, dictionarySectionsIds[sectionName].graphKey);
              pages[pages.length - 1].data[sectionName] = sectionData;
              pages[pages.length - 1].data[dictionarySectionsIds[sectionName].graphKey] = getDataOfSection(dictionarySectionsIds[sectionName].graphKey);
              // //console.log("pages***", pages);
              console.log('entra todo después de añadir pag', pages[pages.length - 1].maxHeigth, pages[pages.length - 1].availableSpace);

              return;
            } else {
              //entra solo la grafica
              console.log('Solo entra gráfica después de añadir pag.');
              pages[pages.length - 1].data[dictionarySectionsIds[sectionName].graphKey] = getDataOfSection(dictionarySectionsIds[sectionName].graphKey);
            }
          }
        }
      }
    }
    if(sectionData.dataRow.length){
      console.log('hay rows por ingresar en ', sectionData, remaingSpace);
      sectionData.dataRow.map((row: any, idx_row) => {
        // nodeHTMLElement = document.getElementById('today_' + dictionarySectionsIds[sectionName].keyRow + '_row_' + idx_row);

        /* if(nodeHTMLElement){
          node_height = getElementHeight(nodeHTMLElement);
          heigthRows.push(node_height);
          remaingSpace -= node_height;
          pages[pages.length - 1].maxHeigth = remaingSpace;
        } */
        if(!sectionData){
          return
        };
        node_height = sectionData.heigthRows[idx_row];
        console.log('idx_row', idx_row, node_height);
        remaingSpace -= node_height;
        pages[pages.length - 1].maxHeigth = remaingSpace;
        if(remaingSpace >= 0){
          console.log('hay espacio para row', idx_row, remaingSpace);
          pages[pages.length - 1].maxHeigth = remaingSpace;
          rowsPerPage.push(row);
          if(sectionData.dataRow.length === idx_row + 1){
            if(!pages[pages.length - 1].data.hasOwnProperty(sectionName)){
              rowsPerPage = [];
              pages[pages.length - 1].data[sectionName] = JSON.parse(JSON.stringify(sectionData));
              console.log('entraron en pag.', pages.length - 1, JSON.parse(JSON.stringify(pages)));
            }
            console.log('heigth before', pages[pages.length - 1].maxHeigth);

            // pages[pages.length - 1].maxHeigth -= 60;
            pages[pages.length - 1].availableSpace = pages[pages.length - 1].maxHeigth;
            console.log('heigth updated', pages[pages.length - 1].maxHeigth);
            return;
          }
        }else{
          copySectionData.dataRow = JSON.parse(JSON.stringify(rowsPerPage));
          console.log('NOOO hay espacio para row', idx_row, remaingSpace,  copySectionData.dataRow[idx_row]);

          if(!sectionData){
            console.log('Nada en sectionData', sectionData);
            return;
          }
          sectionData.dataRow.splice(0, idx_row);
          sectionData.heigthRows.splice(0, idx_row);

          copySectionData.totals = [];
          copySectionData.continue = true;
          rowsPerPage = [];
          pages[pages.length - 1].data[sectionName] = JSON.parse(JSON.stringify(copySectionData));
          console.log('crea pagina', sectionName, JSON.parse(JSON.stringify(sectionData)));
          addPage();
          pages[pages.length - 1].data['hasPreviousPage'] = true;
          let dataToSend = JSON.parse(JSON.stringify(sectionData));
          dataToSend['hasPreviousPage'] = true;
          sectionData = null;
          console.log('sectionName', sectionName, pages.length);
          getItemsOfSection(JSON.parse(JSON.stringify(dataToSend)), pages[pages.length - 1].maxHeigth, sectionName, false);
          return;
        }
      });
    }else{
      nodeHTMLElement = document.getElementById(dictionarySectionsIds[sectionName].primaryId);
        if(nodeHTMLElement){
          node_height = getElementHeight(nodeHTMLElement);
          heigthRows.push(node_height);
          remaingSpace -= node_height;
          console.log('altura restante antes de ingresar', pages[pages.length - 1].maxHeigth);
          console.log('altura del nodo', node_height);
          pages[pages.length - 1].maxHeigth = remaingSpace;
        }
        console.log('altura del nodo a ingresar', node_height, ' remaingSpace', remaingSpace, ' section', sectionName);
        if(remaingSpace > 0){
          pages[pages.length - 1].maxHeigth = remaingSpace;
          pages[pages.length - 1].data[sectionName] = sectionData;
        }else{
          addPage();
          getItemsOfSection(sectionData, pages[pages.length - 1].maxHeigth, sectionName, false);
        }
    }
    return;
  };


  /**
   * This function received an optional argument and checks if existe more of one page in the pages array
   *
   * @param {?*} [section]
   */
  let cutterBalance = (section?: any) => {
    let spaceInFirstPage = 212;
    //checks if there is a one page and there is not a section
    if(pages.length === 1 && !section){
      //assign te section name to the first page
      pages[pages.length - 1].sectionName = 'Balance Sheet - Today';
      // in the first page this function add the following sections
      ['estateBreakdown', 'ownerShipBreakDown','doughnutInsideEstate' ].map(section => {
        pages[pages.length - 1].data[section] = getDataOfSection(section);
      });
    }else{
      //get the section data and then it is sended to getItemsOfSection
      let dataToCut = getDataOfSection(section)
      if(dataToCut){
        getItemsOfSection(dataToCut, section === 'tableEstateAssetsInside' ? spaceInFirstPage : pages[pages.length - 1].availableSpace, section, true);
      }else{
        console.log('no hay data de',section);
      }
    }
  };

  /**
   * This block of code allows know the height of each row
   *
   */
  Object.keys(balanceData.today).map((section: any) => {
    //iterates over the sections and if section includes 'table' entries in the if
    if(section.includes('table')){
      if(balanceData.today[section]){
        let heigthRows = [];
        balanceData.today[section].dataRow.map((row, idxRow) => {
        // get id of each row and we know their heigth
         let  nodeHTMLElement = document.getElementById('today_' + dictionarySectionsIds[section].keyRow + '_row_' + idxRow);
          if(nodeHTMLElement){
            let node_height = getElementHeight(nodeHTMLElement);
            heigthRows.push(node_height);
          }
        });
        //creates a property in section to save heigths
        balanceData.today[section]['heigthRows'] = heigthRows;
        console.log('balanceData.today[section]', section, JSON.parse(JSON.stringify(balanceData.today[section])));
      }
    }
  });
  console.log('balanceData to cut', JSON.parse(JSON.stringify(balanceData)));


  /**
 * Beggins to execute cutterBalance to first page
 * No section is sent to first page
 */
  cutterBalance();

  /**
 * Executes cutterBalance to the next sections in dictionarySectionsIds
 */
  Object.keys(dictionarySectionsIds).map(section => {
    cutterBalance(section);
  });

  console.log('cutBalancePages', JSON.parse(JSON.stringify(pages)));


  return pages;
}
