//Angular
import { Injectable } from "@angular/core";

//RX
import { BehaviorSubject } from "rxjs";

//Project
import {
  urlPrependRootContext,
  getSideByContextInPriority,
} from "../../shared/helpers/url.helper";

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  class?: string; // Used for PENDO
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not appear in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  execute?: () => void;
  restrictionKey?: string;
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  constructor() {}

  //iconMenu: IMenuItem[] = [
  menuItemsCatalog: IMenuItem[] = [
    /* {
      name: 'Client profile',
      class: 'client-profile-link',
      type: 'link',
      tooltip: 'Profile',
      icon: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/01_Side_bar/02_sb_profile.svg',
      state: urlPrependRootContext('/client-profile'),
      restrictionKey: 'profile',
    }, */
    {
      name: "Client Dashboard",
      class: "dashboard-link",
      type: "link",
      tooltip: "Dashboard",
      icon: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/01_Side_bar/03_sb_dashboard.svg",
      state: urlPrependRootContext("/dashboard"),
      restrictionKey: "dashboard",
    },

    {
      name: "Data & Documents",
      class: "questionnaires-link",
      type: "link",
      tooltip: "Data & Documents",
      icon: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/01_Side_bar/07_sb_questionnaires.svg",
      state: "/hub",
      restrictionKey: "questionnaires",
    },
    {
      name: "Snapshots",
      class: "snapshots-link",
      type: "link",
      tooltip: "Snapshots",
      icon: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/01_Side_bar/12_sb_snap.svg",
      state: urlPrependRootContext("/summary_new"),
      disabled: getSideByContextInPriority() == "client",
      restrictionKey: "snapshots",
    },
    {
      name: "Insights",
      class: "Recommendations-link",
      type: "link",
      tooltip: "Insights",
      icon: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/01_Side_bar/04_sb_areas.svg",
      state: urlPrependRootContext("/areas"),
      disabled: getSideByContextInPriority() == "client",
      restrictionKey: "planningopps",
    },
    /*{
      name: 'Planning opportunities',
      class: 'planning-opps-link',
      type: 'link',
      tooltip: 'Areas',
      icon: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/01_Side_bar/04_sb_areas.svg',
      state: urlPrependRootContext('/areas'),
      disabled: getSideByContextInPriority() == 'client'
    },*/
    {
      name: "Task manager",
      class: "task-manager-link",
      type: "link",
      tooltip: "Task Manager",
      icon: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/01_Side_bar/05_sb_taskmanager.svg",
      state: urlPrependRootContext("/taskmanager"),
      restrictionKey: "taskmanager",
    },
    // {
    //   name: 'Summary',
    //   type: 'link',
    //   tooltip: 'Summary',
    //   icon: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/01_Side_bar/06_sb_summary.svg',
    //   state: urlPrependRootContext('/summary'),
    //   disabled: getSideByContextInPriority() == 'client'
    // },
    {
      name: "Simulators",
      class: "resources-link",
      type: "link",
      tooltip: "Simulators",
      icon: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/01_Side_bar/08_sb_education.svg",
      state: urlPrependRootContext("/assets"),
      disabled: getSideByContextInPriority() == "client",
      restrictionKey: "simulators",
    },
    {
      name: "Reports",
      class: "report-link",
      type: "link",
      tooltip: "Reports",
      icon: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/01_Side_bar/09_sb_reports.svg",
      state: urlPrependRootContext("/reports"),
      disabled: getSideByContextInPriority() == "client",
      restrictionKey: "reports",
    },
    {
      name: "Prospect questionnaire",
      class: "prospect-questionnaire-link",
      type: "link",
      tooltip: "Questionnare",
      icon: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/01_Side_bar/07_sb_questionnaires.svg",
      state: "/questionnaire-prospect",
      restrictionKey: "prospect_quest",
    },
  ];

  iconMenu: IMenuItem[] = this.menuItemsCatalog;

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle: string = "Frequently Accessed";
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    this.menuItems.next(this.iconMenu);
  }
}
