import { Component, OnInit, Output, EventEmitter, AfterViewInit, ViewChildren, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { GeneralQuestionnaireService } from 'app/shared/services/questionnaires/general-questionnaire.service';
import { QuestionnaireHubService } from 'app/shared/services/questionnaires/questionnaire-hub.service';
import { QuestionnaireResponse } from 'app/shared/model/questionnaires/Questionnaires.model';
import { clientPersonalInformationDTO, maritalStatusesWithCoClient, netWorthRanges, maritalStatusCatalog, servicePersonalInformationDTO } from './utils';
import { CONST_STATES_KEY, CONST_STATES_KEY_WITH_INTERNATIONAL } from 'app/views/questionnaires/Questionnaire.constants';
import { AmountOrRangeComponent } from 'app/shared/components/amount-or-range/amount-or-range.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClientService } from 'app/shared/services/client/client.service';
import { LocalStorageManager } from 'app/shared/classes/LocalStorageManager';
import { ClientDataService } from 'app/shared/services/client-data-service';
import { AdvisorService } from 'app/shared/services/advisor.service';

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss']
})
export class PersonalInformationComponent implements OnInit, AfterViewInit {

  @Output('handleNextStep') handleNextStep = new EventEmitter();

  @Output() onFormReady = new EventEmitter<FormGroup>();

  @Output() onDisabledNextChange = new EventEmitter<Boolean>();

  @Output() onFirstFormValid = new EventEmitter<Boolean>();

  @Output() onUnsavedChanges = new EventEmitter<Boolean>();

  @ViewChild('clientNetworthField') clientNetworthField: AmountOrRangeComponent;

  curentClientId = '';

  private _personalInformationForm: FormGroup;

  get personalInformationForm(): FormGroup {
    this.onDisabledNextChange.emit(this._personalInformationForm.invalid)
    return this._personalInformationForm;
  }

  set personalInformationForm(form: FormGroup) {
    if (form) {
      this._personalInformationForm = form;
    }
  }

  minDateForBirthdate: Date;

  maxDateForBirthdate: Date;

  netWorthRanges = netWorthRanges;

  statesCatalog = CONST_STATES_KEY_WITH_INTERNATIONAL;

  maritalStatusCatalog = maritalStatusCatalog;

  maritalStatusRequiresCoClient: boolean = false;

  coClientSameLastname: boolean = true;

  coClientSameState: boolean = true;

  coClientSameNetWorth: boolean = true;

  hasPreviousGQData: boolean = false;

  coClientFirstValue = null;

  isSendingQuestionnaire: boolean = false;

  isFirstFormValid: boolean;

  constructor(
    private formBuilder: FormBuilder,
    public generalQuestionnaireService: GeneralQuestionnaireService,
    public snackBar: MatSnackBar,
    public currentClientService: ClientService,
    public clientDataService: ClientDataService,
    public advisorService: AdvisorService
  ) {

    this.resetPersonalInformationForm();

    this.initBirthRanges();

  }

  ngAfterViewInit(): void {
      const localStorage = new LocalStorageManager();

      this.curentClientId = localStorage.getItem('clientIDX')

      this.currentClientService.data.generalQuestionnaire.subscribe(
        (arg)=>{
          if (arg) {
            this.hasPreviousGQData = true;

            const answers = arg.personalInformation

            const dto = clientPersonalInformationDTO(answers)

            if (this.coClientFirstValue == null) {
              this.coClientFirstValue = clientPersonalInformationDTO(answers)
            }

            this.handleMaritalStatusChange(String(dto.clientMaritalStatus))
            this.handleCoClientSameLastName(String(dto.coClientSameLastname))
            if (maritalStatusesWithCoClient.includes(dto.clientMaritalStatus)) {
              this.handleCoClientSameLastName(String(dto.coClientSameLastname))
              this.handleCoClientSameState(String(dto.coClientSameState))
              this.handleCoClientSameNetworth(String(dto.coClientSameNetWorth))
            }
            this.personalInformationForm.valueChanges.subscribe((form) => {
              this.onFormReady.emit(form)
              this.onUnsavedChanges.emit(true)
            })

            setTimeout(() => {
              this.personalInformationForm.patchValue(dto)
              this.personalInformationForm.markAllAsTouched();
              this.personalInformationForm.updateValueAndValidity();
              this.isFirstFormValid = this.isFirstFormValid === undefined ? this.personalInformationForm.valid : this.isFirstFormValid;
              if(this.isFirstFormValid){
                this.onFirstFormValid.emit(true)
                //@ts-ignore
                this.personalInformationForm['touched'] = false
                this.onUnsavedChanges.emit(false)
              }
            }, 200);
          }
        },
        error => console.error(' Error:', error)
      );

  }

  sendQuestionnaire(){

    const  questionnaireHub = new QuestionnaireHubService(this.snackBar);

    const dto = servicePersonalInformationDTO(this.personalInformationForm)

    this.isSendingQuestionnaire = true;

    questionnaireHub.postAnswers(this.generalQuestionnaireService, 'personal', dto, null, this.curentClientId).subscribe(
      (response) => {

        this.onFirstFormValid.emit(true)

        this.currentClientService.refreshAll();

        this.isSendingQuestionnaire = false;

        this.handleNextStep.emit();

        this.onUnsavedChanges.emit(false);

        this.advisorService.getClientById(this.curentClientId).then(async clientResponse => {
          await this.clientDataService.setFullCurrentClient(clientResponse.client);
        })

      },
      (error) =>{
        this.isSendingQuestionnaire = false;
      }
    )
  }

  ngOnInit(): void {
  }

  resetPersonalInformationForm(maritalStatus = 'Single'){
    this.personalInformationForm = this.formBuilder.group({
      clientMaritalStatus : [maritalStatus, []],
      clientFirstName : ['', [Validators.required, Validators.minLength(2)]],
      clientLastName : ['', [Validators.required, Validators.minLength(2)]],
      clientEmail : ['', [Validators.required, Validators.email]],
      clientGender : ['', []],
      clientBirthDate : ['', [Validators.required]],
      clientState : ['', [Validators.required]],
      clientIsRetired : ['', []],
      clientOccupation : ['', []],
      clientNetWorth : ['', []],
      clientIsNonResidentAlien : ['No', []],
      coClientFirstName : ['', []],
      coClientSameLastname : ['Yes', []],
      coClientLastName : ['', []],
      coClientGender : ['', []],
      coClientBirthDate : ['', []],
      coClientSameNetWorth: ['Yes', []],
      coClientNetWorth : ['', []],
      coClientState : ['', []],
      coClientSameState : ['Yes', []],
      coClientIsRetired : ['', []],
      coClientOccupation : ['', []],
      coClientIsNonResidentAlien : ['No', []]
    })

  }

  resetPersonalInformationCoClient(restauringValues?){
    this.coClientSameLastname = restauringValues?restauringValues.coClientSameLastname: false;
    this.coClientSameState = restauringValues?restauringValues.coClientSameState: true;
    this.coClientSameNetWorth = restauringValues?restauringValues.coClientSameNetWorth: true;
    this.personalInformationForm.patchValue(
      {
        coClientFirstName : restauringValues?restauringValues.coClientFirstName:  '',
        coClientSameLastname : restauringValues?restauringValues.coClientSameLastname:  'Yes',
        coClientLastName : restauringValues?restauringValues.coClientLastName:  '',
        coClientGender : restauringValues?restauringValues.coClientGender:  '',
        coClientBirthDate : restauringValues?restauringValues.coClientBirthDate:  '',
        coClientSameNetWorth: restauringValues && restauringValues.coClientSameNetWorth != '' ? restauringValues.coClientSameNetWorth:  'Yes',
        coClientNetWorth : restauringValues?restauringValues.coClientNetWorth:  '',
        coClientState : restauringValues?restauringValues.coClientState:  '',
        coClientSameState : restauringValues && restauringValues.coClientSameState != '' ?restauringValues.coClientSameState:  'Yes',
        coClientIsRetired : restauringValues?restauringValues.coClientIsRetired:  '',
        coClientOccupation : restauringValues?restauringValues.coClientOccupation:  '',
        coClientIsNonResidentAlien : restauringValues && restauringValues.coClientIsNonResidentAlien != 'undefined'?
          restauringValues.coClientIsNonResidentAlien:  'No'
      }
    )
  }

  initBirthRanges() {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth()
    const currentDay = new Date().getDate()
    this.minDateForBirthdate = new Date(currentYear - 120, 0, 1);
    this.maxDateForBirthdate = new Date(currentYear - 18, currentMonth, currentDay);
  }

  handleMaritalStatusChange(event: string){
    const selectedMaritalStatus = event ;
    const requiresCoClient = maritalStatusesWithCoClient.includes(selectedMaritalStatus);
    if ( this.maritalStatusRequiresCoClient != requiresCoClient) {
      if (requiresCoClient) {

        this.resetPersonalInformationCoClient(this.coClientFirstValue);
        this.personalInformationForm.get('coClientFirstName').setValidators([Validators.required, Validators.minLength(2)]);
        this.personalInformationForm.get('coClientFirstName').updateValueAndValidity();
        this.personalInformationForm.get('coClientBirthDate').setValidators([Validators.required]);
        this.personalInformationForm.get('coClientBirthDate').updateValueAndValidity();
        this.handleCoClientSameLastName('Yes')
        this.handleCoClientSameState('Yes')
        this.handleCoClientResidentAlien()
        this.handleCoClientSameNetworth('Yes')
      }
      else {
        this.coClientFirstValue = {
          ...this.coClientFirstValue,
          coClientFirstName : this.personalInformationForm.controls['coClientFirstName'].value,
          coClientSameLastname : this.personalInformationForm.controls['coClientSameLastname'].value,
          coClientLastName : this.personalInformationForm.controls['coClientLastName'].value,
          coClientGender : this.personalInformationForm.controls['coClientGender'].value,
          coClientBirthDate : this.personalInformationForm.controls['coClientBirthDate'].value,
          coClientSameNetWorth: this.personalInformationForm.controls['coClientSameNetWorth'].value,
          coClientNetWorth : this.personalInformationForm.controls['coClientNetWorth'].value,
          coClientState : this.personalInformationForm.controls['coClientState'].value,
          coClientSameState : this.personalInformationForm.controls['coClientSameState'].value,
          coClientIsRetired : this.personalInformationForm.controls['coClientIsRetired'].value,
          coClientOccupation : this.personalInformationForm.controls['coClientOccupation'].value,
          coClientIsNonResidentAlien : this.personalInformationForm.controls['coClientIsNonResidentAlien'].value == 'undefined' ?
            this.personalInformationForm.controls['coClientIsNonResidentAlien'].value : 'No',
        }
        this.resetPersonalInformationCoClient();
        this.personalInformationForm.get('coClientFirstName').setValidators([]);
        this.personalInformationForm.get('coClientFirstName').updateValueAndValidity();
        this.personalInformationForm.get('coClientLastName').setValidators([]);
        this.personalInformationForm.get('coClientLastName').updateValueAndValidity();
        this.personalInformationForm.get('coClientBirthDate').setValidators([]);
        this.personalInformationForm.get('coClientBirthDate').updateValueAndValidity();
        this.personalInformationForm.get('coClientState').setValidators([]);
        this.personalInformationForm.get('coClientState').updateValueAndValidity();
        this.handleCoClientSameLastName('Yes')
      }
    }
    this.maritalStatusRequiresCoClient = requiresCoClient;
  }

  handleCoClientSameLastName(isSameLastName){
    const isSame = isSameLastName === 'Yes'
    this.coClientSameLastname = isSame;
    if (this.coClientSameLastname) {
      this.personalInformationForm.get('coClientLastName').setValidators([]);
      this.personalInformationForm.get('coClientLastName').updateValueAndValidity();
      this.personalInformationForm.patchValue(
        {
          coClientLastName : ''
        }
      )
    }
    else{
      this.personalInformationForm.get('coClientLastName').setValidators([Validators.required, Validators.minLength(2)]);
      this.personalInformationForm.get('coClientLastName').updateValueAndValidity();
    }
  }

  handleCoClientSameNetworth(isSameNetworth){
    const isSame = isSameNetworth === 'Yes'
    this.coClientSameNetWorth = isSame;
    if (this.coClientSameNetWorth) {
      this.personalInformationForm.patchValue(
        {
          coClientNetWorth : ''
        }
      )
    }
  }

  handleCoClientResidentAlien(){
    this.personalInformationForm.patchValue(
      {
        clientIsNonResidentAlien : 'No'
      }
    )
  }

  handleCoClientSameState(isSameState){
    const isSame = isSameState === 'Yes'
    this.coClientSameState = isSame;
    if (this.coClientSameState) {
      this.personalInformationForm.get('coClientState').setValidators([]);
      this.personalInformationForm.get('coClientState').updateValueAndValidity();
      this.personalInformationForm.patchValue(
        {
          coClientState : ''
        }
      )
    }
    else{
      this.personalInformationForm.get('coClientState').setValidators([Validators.required]);
      this.personalInformationForm.get('coClientState').updateValueAndValidity();
    }
  }

}
