// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const ALL            = "PROTOCOL//DOMAINPORT";
const ALL_API        = `${ALL}/v1/advisor`;
const ALL_API_CLIENT = `${ALL}/v1/client`;
const ALL_API_V2_CLIENTS = `${ALL}/v2/client`;
const ALL_API_PUBLIC = `${ALL}/v1/public`;
const ALL_API_DATAPOINT = `${ALL}/v2/datapoint`;
const ALL_API_V2        = `${ALL}/v2`;

const PAYMENT_API    = window.location.hostname === 'localhost' && window.location.port === '4400'
  ? `http://localhost:4000/advisor`
  : `${ALL}/v2/service/payment/advisor`;

const PAYMENT_API_GENERAL    = window.location.hostname === 'localhost' && window.location.port === '4400'
  ? `http://localhost:4000`
  : `${ALL}/v2/service/payment`;

const AUTH_API = window.location.port == '4400'
  ? 'http://localhost:5000/advisor'
  : window.location.host === 'app.fpalpha.app' || window.location.host.includes('localhost')
    ? "https://auth.fpalphatesting.com/advisor"
    : "https://auth.fpalpha.com/advisor";

const AUTH = window.location.port == '4400'
  ? 'http://localhost:5000'
  : window.location.host === 'app.fpalpha.app' || window.location.host.includes('localhost')
    ? "https://auth.fpalphatesting.com"
    : "https://auth.fpalpha.com";

//---------- START OTHERS -------------

export const HTML2CANVAS_SCALE = 2;

//---------- END OTHERS ---------------

/**   NOTES
 *
 * Please follow the nex guidelines:
 * Every property starts with api
 * Followed by Advisor or Client to indicate that the endpoint exist on a specfic backend
 *
 */

const STRIPE_KEY = ['localhost', 'app.fpalpha.app'].includes(window.location.hostname)
    ? 'pk_test_sZdKLS78Eu8mMtLOfJK9kV7Q'
    : 'pk_live_M1amaFNfs2RyqMdziMeKcUcj';

export const environment = {
  production: false,

  dynamicsAppId: '41c4222e-47f2-466f-a711-1b8f858786fe',
  dynamicsRedirectUri: 'https://app.fpalpha.app/advisor/clients',

  apiAdvisor:                       `${ALL_API}`,
  apiAdvisorV2:                     `${ALL_API_V2}`,

  //Socket
  apiSocket:                        `${ALL}`,

  // Create an advisor
  apiCreateAdvisor:                 `${ALL_API}/homepage/advisors`,

  //Log in-out
  apiAdvisorLogin:                  `${ALL_API}/homepage/login`,
  apiClientLogin:                   `${ALL_API_CLIENT}/homepage/login`,

  apiAdvisorLogout:                 `${ALL_API}/homepage/logout`,
  apiClientLogout:                  `${ALL_API_CLIENT}/homepage/logout`,

  apiAdvisorForgotPass:             `${ALL_API}/homepage/forgotpassword`,
  apiClientForgotPass:              `${ALL_API_CLIENT}/homepage/forgotpassword`,

  apiAdvisorChangePass:             `${ALL_API}/homepage/changepassword`,
  apiClientChangePass:              `${ALL_API_CLIENT}/homepage/changepassword`,

  apiAdvisorData:                   `${ALL_API}/homepage/advisors`,
  apiClientData:                    `${ALL_API_CLIENT}/homepage/client`,

  apiAdvisorForceLogout:            `${ALL_API}/homepage/forcelogout`,
  apiClientForceLogout:             `${ALL_API_CLIENT}/homepage/forcelogout`,

  apiAdvisorRenewToken:             `${ALL_API}/homepage/renew`,
  apiClientRenewToken:              `${ALL_API_CLIENT}/homepage/renew`,

  apiTwoFactorAuth:                 `${ALL_API}/homepage/verify2auth/`, //Two factor Auth
  apiResendTwoFactorAuthCode:       `${ALL_API}/homepage/resendVerificationCode/`,

  apiAllAdvisorsFromFirm:           `${ALL_API}/homepage/company-advisors/`,

  // Client List
  apiCreateClient:                  `${ALL_API}/clientlist/clients`, // Create Client / POST
  apiListClients:                   `${ALL_API}/clientlist/clients`, // Read all clients / GET
  apiListClientsv2:                 `${ALL_API}/clientlist/clients-v2`, // Read all clients / GET
  apiDeleteClient:                  `${ALL_API}/clientlist/clients/`, // Delete client / DELETE
  apiBatchUpload:                   `${ALL_API}/clientlist/batchupload`, // Batch upload client / POST
  apiCreateProspectIandII:          `${ALL_API}/clientlist/prospects`, // Create Prospect pt 1 / POST
  apiCreateProspectIII:             `${ALL_API}/clientlist/prospectstemporal`, // Create Prospect pt 1 / POST
  apiShareClient:                   `${ALL_API}/clientlist/shareclient/`,
  apiAdvisorProspectToClient:       `${ALL_API}/clientlist/prospecttoclient/`, // {idcli} /POST
  apiAdvisorBetaAgreement:          `${ALL_API}/clientlist/agreement`, // {agree: true} /POST
  apiValidateEmail:                 `${ALL_API}/clientlist/validate`, // POST

  // Dashboard
  apiAdvisorDashboard:               `${ALL_API}/dashboard/clients/`,
  apiClientDashboard:                `${ALL_API_CLIENT}/dashboard/clients/`,

  // First time login
  apiClientFirstTimeLogin:          `${ALL_API_CLIENT}/welcome/`,

  // Advisor profile
  apiAdvisorAdvisorProfile:          `${ALL_API}/settings/info`,
  apiAdvisorAdvisorPreferences:      `${ALL_API}/settings/update`,
  apiAdvisorAdvisorProspecting:      `${ALL_API}/settings/lead`,
  apiAdvisorProspectImage:           `${ALL_API}/settings/lead-background`,
  apiAdvisorImage:                   `${ALL_API}/settings/photo`,

  //Client profile
  apiAdvisorClientProfile:          `${ALL_API}/profileclient/client/`,
  apiAdvisorClientResendPass:       `${ALL_API}/profileclient/resend/`,
  apiClientClientProfile:           `${ALL_API_CLIENT}/profile/client/`,

  //Ongoing planning
  apiAdvisorOngoingPlanning:        `${ALL_API}/profileclient/ongoing/`,
  apiClientOngoingPlanning:         `${ALL_API}/profile/ongoing/`,

  //Task Manager
  apiAdvisorTaskManager:            `${ALL_API}/taskmanager/`,
  apiAdvisorAuthOutlook:            `${ALL_API}/outlook/auth`,

  apiClientTaskManager:             `${ALL_API_CLIENT}/taskmanager/`,
  apiClientAuthOutlook:             `${ALL_API_CLIENT}/outlook/auth`,

  //Planning Opportunities
  apiAdvisorProjections:            `${ALL_API}/projections/`,

  //Historical opportunities
  apiAdvisorHistorical:             `${ALL_API}/historical/`,

  //Notifications
  // apiAdvisorNotifications:           `${ALL_API}/homepage/`,
  /*apiAdvisorDeleteNotifications:     `${ALL_API}/area/not/`,

  apiClientNotifications:           `${ALL_API_CLIENT}/homepage/`,
  apiClientDeleteNotifications:     `${ALL_API_CLIENT}/area/not/`,*/

  apiAdvisorNotifications: `${ALL_API_V2}/services/notifications`,
  apiAdvisorMarkAsReadAllNotifications: `${ALL_API_V2}/services/notifications/read-all`,
  apiAdvisorDeleteNotifications: `${ALL_API_V2}/services/notifications/dispatch-all`,

  apiClientNotifications: `${ALL_API_V2}/services/notifications`,
  apiClientMarkAsReadAllNotifications: `${ALL_API_V2}/services/notifications/read-all`,
  apiClientDeleteNotifications: `${ALL_API_V2}/services/notifications/dispatch-all`,

  //HUB
  apiHub:                           `${ALL_API}/hub/areas/`,
  apiAdvisorHub:                    `${ALL_API}/hub/areas/`,
  apiClientHub:                     `${ALL_API_CLIENT}/hub/areas/`,

  // Questionnaire
  apiQuestionnaire:                 `${ALL_API}/area/`,
  apiAdvisorQuestionnaire:          `${ALL_API}/area/`,
  apiClientQuestionnaire:           `${ALL_API_CLIENT}/area/`,
  apiRecs:                          `${ALL_API}/area/`,
  apiQuestionnaires:                `${ALL_API}/questionnaires`,
  apiGQPersonalInformation:         `${ALL_API}/questionnaires/general/personal-information`,
  apiGQFamily:                      `${ALL_API}/questionnaires/general/family`,
  apiGQAssets:                      `${ALL_API}/questionnaires/general/assets`,

  //Summary
  apiAdvisorSummaryExtractions:      `${ALL_API}/summary/extractions/`,
  apiAdvisorSummaryActions:          `${ALL_API}/summary/actions/`,
  apiAdvisorSummaryGraph:            `${ALL_API}/summary/graph/`,

  //Chart JS -> Areas
  apiCharts:                        `${ALL_API}/area/`,

  //Search
  apiAdvisorGlossarySearch:         `${ALL_API}/glossary/search`,
  apiClientGlossarySearch:          `${ALL_API_CLIENT}/glossary/search`,
  apiAdvisorClientSearch:          `${ALL_API}/clientlist/search`,


  //Download reports
  apiReports:                       `${ALL_API}/profileclient/client/`,
  apiProspectReports:               `${ALL_API}/prospect/pdf/`,

  //
  apiClientProfile:                 `${ALL_API}/profileclient/client/`,

  //Integrations

  /*** Availability check */
  apiIntegrationAvailabilityCheck:   `${ALL_API}/integrations/generic/all/verify-availability/`,
  /*** Outlook */
  apiOutlook:                       `${ALL_API}/outlook/`,
  /*** Redtail */
  apiRedtail:                       `${ALL_API}/integrations/redtail`,
  apiRedtailPhase1:                 `${ALL_API}/integrations/redtailphase1`,
  apiRedtailPhase1Pretty:           `${ALL_API}/integrations/redtailphase1pretty`,
  apiRedtailPhase2:                 `${ALL_API}/integrations/redtailphase2`,

  /*NEW Redtail*/
  apiRedtailV2:                     `${ALL_API}/integrations/generic/redtail/credentials/exchange`,
  apiRedtailGetClientsV2:           `${ALL_API}/integrations/generic/redtail/fetch`,
  apiRedtailImportClientsV2:        `${ALL_API}/integrations/generic/redtail/import`,
  apiRedtailImportAllClientsV2:        `${ALL_API}/integrations/generic/redtail/import-all`,
  apiRedtailDisconnectAccountV2:    `${ALL_API}/integrations/generic/redtail/disconnect`,
  apiRedtailGetClientTypes:         `${ALL_API}/integrations/generic/redtail/contact-types`,

  /*** Salesforce */
  apiSalesforceOauth:               `${ALL_API}/integrations/generic/salesforce/oauth/exchange`,
  apiSalesforceGetClients:           `${ALL_API}/integrations/generic/salesforce/fetch`,
  apiSalesforceImportClients:        `${ALL_API}/integrations/generic/salesforce/import`,
  apiSalesforceDisconnectAccount:    `${ALL_API}/integrations/generic/salesforce/disconnect`,
  apiSalesforceGetFields:    `${ALL_API}/integrations/generic/salesforce/fields`,
  apiSalesforceCustomFields:    `${ALL_API}/integrations/generic/salesforce/custom-fields`,
  apiSalesforceConfigurations:    `${ALL_API}/integrations/generic/salesforce/configurations`,

  /***Wealthbox */
  apiWealthboxOauthExchange:        `${ALL_API}/integrations/generic/wealthbox/oauth/exchange`,
  apiWealthboxGetClients:           `${ALL_API}/integrations/generic/wealthbox/fetch`,
  apiWealthboxGetClientTypes:       `${ALL_API}/integrations/generic/wealthbox/contact-types`,
  apiWealthboxImportClients:        `${ALL_API}/integrations/generic/wealthbox/import`,
  apiWealthboxDisconnectAccount:    `${ALL_API}/integrations/generic/wealthbox/disconnect`,

  /*** Riskalyze */
  apiRiskalyzeOauth:               `${ALL_API}/integrations/generic/riskalyze/oauth/exchange`,
  apiRiskalyzeGetClients:           `${ALL_API}/integrations/generic/riskalyze/fetch`,
  apiRiskalyzeImportClients:        `${ALL_API}/integrations/generic/riskalyze/import`,
  apiRiskalyzeDisconnectAccount:    `${ALL_API}/integrations/generic/riskalyze/disconnect`,

  /*** PreciseFP */
  apiPreciseFPOauth:                `${ALL_API}/integrations/generic/precisefp/oauth/exchange`,
  apiPreciseFPGetClients:           `${ALL_API}/integrations/generic/precisefp/fetch`,
  apiPreciseFPGetClientTypes:       `${ALL_API}/integrations/generic/precisefp/contact-types`,
  apiPreciseFPImportClients:        `${ALL_API}/integrations/generic/precisefp/import`,
  apiPreciseFPDisconnectAccount:    `${ALL_API}/integrations/generic/precisefp/disconnect`,

  /*** Orion */
  apiOrionOauth:                    `${ALL_API}/integrations/generic/orion/oauth/exchange`,
  apiOrionGetClients:               `${ALL_API}/integrations/generic/orion/fetch`,
  apiOrionGetClientTypes:           `${ALL_API}/integrations/generic/orion/contact-types`,
  apiOrionImportClients:            `${ALL_API}/integrations/generic/orion/import`,
  apiOrionDisconnectAccount:        `${ALL_API}/integrations/generic/orion/disconnect`,


  /** Moneyguide */
  apiMoneyguideDisconnectAccount:    `${ALL}/advisor/disconnect`,

  //Tax stats
  apiTaxStats:                      `${ALL_API}/area/triangle/`,

  //PDF Questionnaire
  apiPDFQuestionnaire:              `${ALL_API}/area/questdownload/`,

  //FlowChart
  apiFlowChart:                     `${ALL_API}/flowchart/`,

  //Prospect
  prospectAnonymous:                 `${ALL}/prospect/`,
  prospectAdvisor:                   `${ALL_API}/prospect/`,
  prospectValidate:                  `${ALL_API}/prospect/validate`,
  prospectURL:                       `${ALL_API}/prospect/validate`,
  prospectLead:                      `${ALL_API}/prospect/lead`,
  prospectProgress:                  `${ALL_API}/prospect/progress`,

  // Subscription and payment
  // stripeKey:                         `${STRIPE_KEY}`,
  // apiAdvisorSubscriptionInformation: `${ALL_API}/settings/subscription/information`,
  // apiAdvisorSubscriptionTrigger:     `${ALL_API}/settings/subscription/trigger`,
  // apiAdvisorInvoicesRecord:          `${ALL_API}/settings/subscription/invoices`,
  // apiAdvisorSubscriptionActivate:    `${ALL_API}/settings/subscription/activate`,
  // apiAdvisorPaymentMethod:           `${ALL_API}/settings/payment-method`,
  // apiAdvisorSubscriptionStart:       `${ALL_API}/settings/subscription/start`,
  // apiAdvisorSubscriptionChange:      `${ALL_API}/settings/subscription/change`,

  //First Time tutorial
  apiFirstTime:                      `${ALL_API}/homepage/firstTime`,

  //Template reports
  apiAdvisorTemplateReport:         `${ALL_API}/report`,
  apiClientAreasOnTaskManager:      `${ALL_API}/area`,
  apiClientAreasOnTaskManager_2:    `${ALL_API_CLIENT}/area`,

  //Company logo advisor
  apiAdvisorLogo:                   `${ALL_API}/homepage/company-logo`,

  // New Summary
  apiAdvisorSummary:                `${ALL_API}/summary`,
  apiGetTaxYears:                   `${ALL_API}/area/fulltax/years`,
  apiAdvisorGetTaxYears:            `${ALL_API}/area/fulltax/years`,
  apiClientSummary:                `${ALL_API_CLIENT}/summary`,
  apiClientGetTaxYears:             `${ALL_API_CLIENT}/area/fulltax/years`,

  //Calculators
  apiAdvisorCalculator:              `${ALL_API}/calculator`,

  //Modal updates
  apiAnnouncement:                   `${ALL_API}/homepage/announcement`,
  apiNewReleases:                    `${ALL_API}/homepage/newrelease`,

  // New Socket
  apiSocket2:                        `${ALL_API}/notifications`,

  //Review extraction images
  apiReviewExtractionImage:          `${ALL_API}/area`,

  //Time line report
  apiTimeLineReport:                 `${ALL_API}//report/timeline`,

  //Tax quest year toggle
  apiTaxToggleYear:                  `${ALL_API}/area`,
  //Estate Lab
  apiEstateLabDummy:                 `${ALL_API}/estate-lab-dummy`,
  apiEstateLab:                      `${ALL_API}/estate-planning`,
  apiEstateLabAssets:                `${ALL_API}/clientlist/assetsList`,
  apiEstateLabContacts:              `${ALL_API}/clientlist/contactsList`,

  // Auth API
  authConnectedApps:                 `${AUTH_API}/connections`,
  disconnectConnectedApp:            `${AUTH_API}/disconnect`,
  /* NEW GENERAL QUEST */
  apiGoals:                          `${ALL_API}/clientlist/goals`,
  apiAssets:                         `${ALL_API}/clientlist/assetsList`,
  apiFamily:                         `${ALL_API}/clientlist/contactsList`,
  apiDatapoint:                      `${ALL_API_DATAPOINT}`,
  apiDatapointGeneral:               `${ALL_API_DATAPOINT}/area/general`,
  apiZip:                            `${ALL_API_PUBLIC}/state`,

  // Client filing status
  apiClientClientGeneral:                  `${ALL_API_CLIENT}/clientgeneral`,
  apiAdvisorClientGeneral:                 `${ALL_API}/clientgeneral`,

  // Set uploader estatus to submitted

  apiAdvisorEstatusToSubmitted:      `${ALL_API}/area/isuploaderblocked`,

  //Files upload
  apiFilesUpload:                    `${ALL_API}/area/filesupload`,
  apiFileUpload:                    `${ALL_API}/area/fileupload`,
  apiRequestSummary:                 `${ALL_API}/area/estate/request-summary`,
  apiInsuranceRequestSummary:        `${ALL_API}/area/__type__/request-summary`,
  apiGetSummary:                      `${ALL_API}/area/estate-summary/document`,
  apiGetSummaryStatus:               `${ALL_API}/area/estate-summary/status`,
  updateSnapshot:                      `${ALL_API_V2}/docs/update-snapshot`,
  updateEstateLab:                      `${ALL_API_V2}/docs`,

  //Delete queue
  apiFlushNotifications:             `${ALL_API_V2}/services/notifications/flush`,

  //Save tax calculator
  apiTaxProjectionsCalculator:        `${ALL_API}/calculator/tax-projections/calculate`,

  //Status services
  apiStatusServices:                `${ALL_API_PUBLIC}/services/status`,

  //--------------
  //----API V2----
  //--------------

  //Client profile
  apiV2ClientProfile:                `${ALL_API_V2_CLIENTS}s`,

  //Company Logo
  apiCompanyLogo:                     `${ALL_API_V2}/advisors/companies/logo`,

  //Company data
  apiCompanyData:                     `${ALL_API_V2}/advisors/companies/profile`,

  //Company advisors
  apiCompanyAdvisorsData:             `${ALL_API_V2}/advisors`,

  //Roles
  apiAdvisorRol:                      `${ALL_API_V2}/advisors/companies/roles`,

  //Reports
  apiReportsCompany:                  `${ALL_API_V2}/advisors/companies/`,
  apiReportsData:                     `${ALL_API_V2}/advisors/companies/reports`,
  apiReportsBackground:               `${ALL_API_V2}/advisors/companies/reports/bg-image`,

  //Company settings
  apiCompanySettings:                 `${ALL_API_V2}/advisors/companies/settings`,

  // Payments V2
  stripeKey:                         `${STRIPE_KEY}`,
  apiAdvisorSubscriptionInformation: `${PAYMENT_API}/information`,
  apiAdvisorSubscriptionTrigger:     `${PAYMENT_API}/trigger`,
  apiAdvisorInvoicesRecord:          `${PAYMENT_API}/invoices`,
  apiAdvisorPayInvoice:              `${PAYMENT_API}/invoice`,
  apiAdvisorInvoiceFile:             `${PAYMENT_API_GENERAL}/invoice`,
  apiAdvisorSubscriptionActivate:    `${PAYMENT_API}/subscription/activate`,
  apiAdvisorPaymentMethods:           `${PAYMENT_API}/payment-methods`,
  apiAdvisorAddPaymentMethod:           `${PAYMENT_API}/payment-method`,
  apiAdvisorSubscriptionStart:       `${PAYMENT_API}/start`,
  apiAdvisorSubscriptionChange:      `${PAYMENT_API}/change`,

  //Filter Categories task manager
  apiFilterTaskManager:              `${ALL_API}/taskmanager/subtitles`,
  apiFilterTaskManager_2:              `${ALL_API_CLIENT}/taskmanager/subtitles`,

  //Set general questionnaire
  apiSetGeneralQuestionnaire:       `${ALL_API}/clientlist/toggle-no-general-quest`,

  // Estate Lab V2
  apiEstateLab_2:                    `${ALL_API}/estate-planning-v2`,

  // PENDO
  apiPendoData:                      `${ALL_API}/portfolio/advisor-profile`,

  authAcess:                         `${AUTH}`
};
