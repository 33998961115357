import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { initPendo } from 'app/shared/helpers/pendo.helper';

import { Area } from '../../../shared/helpers/areas.catalog';
import { AdvisorService } from '../../../shared/services/advisor.service';
import { AuthenticationService } from '../../../shared/services/auth/authentication.service';
import { general_questions } from '../q-general/general.model';
import { QuestionnaireController } from '../Questionnaire.controller';
import { NEW_QUEST_AREAS, NEW_QUEST_AREAS_MAIN_ROUTE } from '../insurance-hub/insurance-constants';
import { ClientService } from 'app/shared/services/client/client.service';
import { getFullName, getNumberOfChilds } from 'app/shared/services/client/utils/gettters';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-hub',
  templateUrl: './hub.component.html',
})
export class HubComponent implements OnInit, AfterViewInit, OnDestroy {

  public clientID: string;
  public areasInHub;
  public clientHasAnsweredGeneral: boolean = false;
  public objectKeys = Object.keys;
  public isLoading: boolean = false;
  public isAdvisor: boolean = false;
  public clientData: any;
  public clientHasAnswered: any;
  public deleteHomeQuestionnaires: boolean = false;
  public toggleSearchBar: boolean = false;
  public areasThatHaveUploader: string[] = ['fulltax', 'estate' , 'auto', 'home'];
  public haveDocumentStatus: boolean = false;
  public hasAccessToRecs: boolean = false;
  public hasInsuranceBetaAccess: boolean = true;
  public newQuestAreas: string[] = NEW_QUEST_AREAS;
  public newQuestAreasRoutes: any = NEW_QUEST_AREAS_MAIN_ROUTE;
  public currentClientFullName;
  private generalQuestionnaireSubscribe: Subscription;

  numberOfChilds

  constructor(
    private advisorService: AdvisorService,
    public snackBarError: MatSnackBar,
    public authService: AuthenticationService,
    public qController: QuestionnaireController,
    private router: Router,
    public currentClientService: ClientService
  ) {}



  async ngOnInit() {

    // Parse session storage data
    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));

    /*
    const auxBetaInfo = JSON.parse(localStorage.getItem('betaAccess'));
    console.log('AUX BETA INFO: ', auxBetaInfo);
    if (auxBetaInfo.includes('insuranceSnapshot') || !urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION)) {
      this.hasInsuranceBetaAccess = true;
    } else {
      this.hasInsuranceBetaAccess = false;
    }
    */

    this.clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));
    this.clientHasAnsweredGeneral = this.clientHasAnswered.ansGeneral;
    // Get client data
    this.clientID = this.authService.isAdvisor() ? this.clientData.clientId : localStorage.getItem('userId');

    //The clientHasAnsweredQuestionnaires is loaded at ngAfterViewInit


    this.isAdvisor = this.authService.isAdvisor();

    if(this.clientData.hasGeneralQuest === false){
      this.clientHasAnsweredGeneral = true;
    }

    this.hasAccessToRecs = this.authService.currentRoleHasAccessTo('planningopps');


    console.log('this.clientHasAnswered', this.clientHasAnswered);

    this.currentClientFullName = await this.currentClientService.getProperty(getFullName, 'generalQuestionnaire');

    /*this.currentClientService.data.generalQuestionnaire.subscribe(responnse=>{
      console.log('daaaata', responnse);
    })*/

      this.generalQuestionnaireSubscribe = this.currentClientService.data.generalQuestionnaire.subscribe((data)=>{
        console.log('Hub.generalQuestionnaire.subscribe');
        this.clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));
        this.clientHasAnsweredGeneral = this.clientHasAnswered.ansGeneral;
        if(this.clientData.hasGeneralQuest === false){
          this.clientHasAnsweredGeneral = true;
        }
        this.loadData();
      })

  }

  ngAfterViewInit(){

    this.loadData()

  }

  loadData(){
    console.log('hub load data');
    // Must make sure that the damn client is loaded (RT)
    if (sessionStorage['currentClient']) {
      this.parseLocalStorageSettings();
    } else {
      setTimeout(() => { this.parseLocalStorageSettings(); }, 1000);
    }

    this.pendoInit();

    this.isLoading = true;

    this.advisorService.getHubAreas(this.clientID)
      .subscribe(
        data => {
            this.areasInHub = this.mergAreaData(data);

            if(this.clientData.hasGeneralQuest === false){
              this.areasInHub.shift();
            }
            //In case of client side,

            //Remove Investment area when is client
            this.areasInHub = this.areasInHub.filter(area => area.id !== 'investment');
            console.log('AREAS: ', this.areasInHub);
            this.isLoading = false;
          },
          error => {
            console.log(error);
            this.snackBarError.open("Can't get Hub data. [" + error.error.message + ']', "", {
              duration: 6000,
              panelClass: 'error-snackbar'
            });
            this.isLoading = false;
          }
      );

    //Enable or disbale a questionnaire for Client Side
    if(!this.isAdvisor){
      this.advisorService.getClientDashboardData(this.clientID).subscribe(response => {

        /*
        let clientHasAnsweredQuestionnaires = Area.mapAreasToObject(area => {
          return { ['ans' + area.id.charAt(0).toUpperCase() + area.id.slice(1)]: response.clientHasAnsweredQuestionnaires[area.id] }
        }, AREAS);
        sessionStorage.setItem('clientHasAnsweredQuestionnaires', JSON.stringify(clientHasAnsweredQuestionnaires));
        this.clientHasAnsweredGeneral = clientHasAnsweredQuestionnaires['ansGeneral'];
        */
      })
    }
  }

  mergAreaData(areasFromBack:any[]){
    let mergedData = [];
    areasFromBack.forEach(areaFromBack => {
      let areaFromCatalog = Area.findAreaById(areaFromBack.nameOfAreaSimple);
      mergedData.push(Object.assign(areaFromBack, areaFromCatalog));
    });
    return mergedData;
  }

  //This section is left for retrocompatibility, currently, no questionnaire uses the isFirstTime flag.
  checkIfFirstTime(firstTime: boolean, area: string) {
    console.log('AREA: ', area);
    if (firstTime) sessionStorage.setItem("isFirstTime", JSON.stringify(area));
  }

  toggleSearch() {
    this.toggleSearchBar = !this.toggleSearchBar;
  }

  /**
 * @name parseSessionStorageSettings
 * @description Parses settings stored in session storage to make client data available to use.
 */

  parseLocalStorageSettings(){
      this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
      this.clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));
  }

  /**
   * @name initializeTour
   * @description Initializes and handles the tour component.
   * @fires Tour
   */



  async generalQuestAnwered(): Promise<any>{
    let hasAnswered = false;
    this.advisorService.getDataPoints(this.clientData.clientId).then(success => {



      if(success.datapoints.length > 0){
        hasAnswered = (success.datapoints.find(element => element.name == '_completed')).value
      }


    }, error => {
      console.log(error);
    });

    return hasAnswered
  }

   /**
   * @name pendoInit
   * @description Initializes the Pendo script if a token was provided. This script reads the props set on advisorInfo inside Local storage.
   * @param none
   */
  pendoInit(){
    console.log('Initializing Pendo on Questionnaires...💿');
    initPendo();
  }

  ngOnDestroy(): void {
    console.log('Hub. destroy');
    if(this.generalQuestionnaireSubscribe != undefined){
      this.generalQuestionnaireSubscribe.unsubscribe();
      console.log('Hub.generalQuestionnaire.unsubscribe');
    }
  }

}
